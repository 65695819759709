<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      {{validateData}}
      <form v-on:submit.prevent="actionForm()" class="form layout">
        <div class="container">
          <div class="row">
            <div class="col-4" v-bind:class="{'filled': nombre != ''}">
              <label for="nombre">Nombre</label>
              <input id="nombre" type="text" v-model="nombre" placeholder="Escribe algo...">
            </div>
            <div class="col-4" v-bind:class="{'filled': montoInicial > 0}">
              <label for="montoInicial">Monto Inicial</label>
              <input id="montoInicial" type="tel" v-model="montoInicialFormateado" placeholder="0.00">
            </div>
            <div class="col-4" v-bind:class="{'filled': montoActual > 0}">
              <label for="montoActual">Monto Actual</label>
              <input id="montoActual" type="tel" v-model="montoActualFormateado" placeholder="0.00">
            </div>
            <div class="col-6" v-bind:class="{'filled': descripcion != ''}">
              <label for="descripcion">Descripción</label>
              <input id="descripcion" type="text" v-model="descripcion" placeholder="Escribe algo...">
            </div>
            <div class="col-6" v-bind:class="{'filled': icon != ''}">
              <label>Icono:</label>
              <FontawesomePicker v-model="icon"></FontawesomePicker>
            </div>
            <div class="col-12">
              <label for="color">Selecciona un Color</label>
              <swatches v-model="color" :colors="colors" row-length="8" popover-to="right" swatch-size="30"></swatches>
            </div>
            <div class="col-12">
              <ul v-if="messages.length" class="messages-container">
                <p class="t-14-px">Verifica lo siguiente:</p>
                <li v-for="(message, index) in messages" v-bind:class="['message-item t-12-px', message.type]"><i class="fas fa-times" @click.prevent.stop="delete_message(message)"></i> {{message.content}}</li>
              </ul>
            </div>
            <div class="col-12">
              <button v-if="id" type="submit" class="btn b-secondary">Guardar Cambios</button>
              <button v-else type="submit" class="btn b-secondary">Crear Nuevo Elemento</button>
              <button v-if="id" type="submit" class="btn b-warn" v-on:click.prevent="deleteCategory(id)">Eliminar</button>
            </div>
          </div>
        </div>
      </form>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import router from '@/router'
import {getColors} from '@/colors.js'
import FontawesomePicker from '@/components/AwesomeIcon.vue'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    GetFooter,
    FontawesomePicker,
    Swatches
  },
  data() {
    return {
      action_state: true, 
      id: this.$route.params.id,
      nombre: '',
      montoInicial: 0.00,
      montoInicialFormateado: 0.00,
      montoActual: 0.00,
      montoActualFormateado: 0.00,
      descripcion: '',
      icon: 'far fa-smile',
      color: '#1FBC9C',
      categorias: [],
      messages: [],
      colors: getColors
    }
  },
  firestore () {
    if(this.id){
      return {
        categorias: db.collection('categorias').doc(this.id)
      }
    }
  },
  watch: {
    nombre () {
      this.varificar_modificaciones
    },
    montoInicial () {
      this.montoInicialFormateado = this.$options.filters.formatNumber(this.montoInicial)
      this.varificar_modificaciones
    },
    montoInicialFormateado () {
      this.montoInicial = this.$options.filters.cleanFormat(this.montoInicialFormateado)
      this.montoInicialFormateado = this.$options.filters.formatNumber(this.montoInicial)
    },
    montoActual () {
      this.montoActualFormateado = this.$options.filters.formatNumber(this.montoActual)
      this.varificar_modificaciones
    },
    montoActualFormateado () {
      this.montoActual = this.$options.filters.cleanFormat(this.montoActualFormateado)
      this.montoActualFormateado = this.$options.filters.formatNumber(this.montoActual)
    },
    descripcion () {
      this.varificar_modificaciones
    },
    icon () {
      this.varificar_modificaciones
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  },
  computed: {
    validateData (){
      if(this.id){
        for(const i in this.categorias){
          if(this.categorias.id == this.id){
            this.nombre = this.categorias.nombre
            this.montoInicial = Number(this.categorias.valor_inicial).toFixed(2)
            this.montoActual = Number(this.categorias.valor_actual).toFixed(2)
            this.descripcion = this.categorias.descripcion
            this.icon = this.categorias.icon
            this.color = this.categorias.color
            break
          }
        }
      }
    },
    varificar_modificaciones (){
      //Siempre y cuando no se este editando un movimiento verificar si se modifico un campo
      if(!this.id){
        if(this.nombre != '' || this.descripcion != '' || this.icon != ''){
          //Si se ha modificado cualquiera de los campos preguntar antes de salir
          this.action_state = false
        }else{
          this.action_state = true
        }
      }else{
        this.action_state = false
      }
    }
  },
  methods: { 
    delete_message (value){
     this.messages.splice(this.messages.indexOf(value), 1)
    },
    deleteCategory (id){
      if(window.confirm('¿Estás Seguro? Esto dejará sin categoría a los movimientos asignados.')){
        this.action_state = true
        db.collection('categorias').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
        router.replace({path: '/categories'})
      }
    },
    actionForm () {
      const id_categoria = this.id
      var item_nombre = this.nombre
      var item_monto_inicial = this.montoInicial
      var item_monto_actual = this.montoActual
      var item_descripcion = this.descripcion
      var item_icon = this.icon
      var item_color = this.color
      var item_creacion = new Date()

      this.messages = []

      if(!item_nombre) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar un nombre'
        }
        this.messages.push(the_message)
      }

      if(!item_monto_inicial) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar un monto inicial'
        }
        this.messages.push(the_message)
      }

      if(!item_monto_actual) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar un monto actual'
        }
        this.messages.push(the_message)
      }

      if(!item_descripcion) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar una descripción'
        }
        this.messages.push(the_message)
      }

      if(!item_icon) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario seleccionar un icono'
        }
        this.messages.push(the_message)
      }

      if(!item_color) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario seleccionar un color'
        }
        this.messages.push(the_message)
      }

      if(!this.messages.length) {
        this.action_state = true
        if(id_categoria){
          db.collection('categorias').doc(id_categoria).update({
            color: item_color,
            fecha_modificacion: item_creacion,
            nombre: item_nombre,
            valor_inicial: Number(item_monto_inicial),
            valor_actual: Number(item_monto_actual),
            descripcion: item_descripcion,
            icon: item_icon
          })
          .then(function(docRef) {
              //router.replace('/categories')
          })
          .catch(function(error) {
              //router.replace('/categories')
          })
          router.replace('/categories')
        }else{
          db.collection('categorias').add({
            color: item_color,
            fecha_creacion: item_creacion,
            fecha_modificacion: item_creacion,
            nombre: item_nombre,
            valor_inicial: Number(item_monto_inicial),
            valor_actual: Number(item_monto_actual),
            descripcion: item_descripcion,
            icon: item_icon
          })
          .then(function(docRef) {
              //router.replace('/categories')
          })
          .catch(function(error) {
              //router.replace('/categories')
          })
          router.replace('/categories')
        }
      }
      
    }
  }
}
</script>
