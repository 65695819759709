<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      <formVersion 
      v-if="folios[0]" 
      v-bind:action="'crear_cotizacion'"
      v-bind:folio="cotizacion.folio"
      v-bind:folio_secuencial="folios[0].valor"
      v-bind:version_secuencial="varsiones.length"  
      v-on:actionChange="onActionChange" />
      <GetFooter />
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase'
import formVersion from '@/components/formVersion.vue'
import GetFooter from '@/components/footer.vue'
export default {
  name: 'app',
  components: {
    formVersion,
    GetFooter
  },
  data() {
    return {
      action_state: false,
      folios: [],
      cotizacion: [],
      varsiones: []
    }
  },
  firestore () {
    return {
      folios: db.collection('valores').where('relacion', '==', 'folio').limit(1),
      cotizacion: db.collection('cotizaciones').doc(this.$route.params.id),
      varsiones: db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones')
    }
  },
  methods: {
    onActionChange(getactionChange){
      this.action_state = getactionChange
    },
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>