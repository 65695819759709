import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'
//Vistas
import Login from '@/views/loginScreen.vue'
import Dashboard from '@/views/dashboard.vue'
import Balance from '@/views/balance.vue'
import Stuffs from '@/views/stuffs.vue'
import Accounts from '@/views/accounts.vue'
import Account from '@/views/account.vue'
import AccountActions from '@/views/accountActions.vue'
import Categories from '@/views/categories.vue'
import CategoryActions from '@/views/categoryActions.vue'
import Customers from '@/views/customers.vue'
import Customer from '@/views/customer.vue'
import CustomerActions from '@/views/customerActions.vue'
import Projects from '@/views/projects.vue'
import ProjectActions from '@/views/projectActions.vue'
import Tasks from '@/views/tasks.vue'
import Moves from '@/views/moves.vue'
import MoveCreate from '@/views/moveCreate.vue'
import MoveEdit from '@/views/moveEdit.vue'
import Quotes from '@/views/quotes.vue'
import viewQuote from '@/views/viewQuote.vue'
import VersionEdit from '@/views/versionEdit.vue'
import VersionCreate from '@/views/versionCreate.vue'
import QuoteCreate from '@/views/quoteCreate.vue'

Vue.use(VueRouter)

const routes = [
	{
	  path: '/',
	  name: 'Iniciar Sesión',
	  component: Login,
	},
	{
	  path: '/logOut/:ruta_intento_ingreso',
	  name: 'Sesión Finalizada',
	  component: Login,
	},
	{
	  path: '/dashboard',
	  name: 'Resumen',
	  component: Dashboard,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/moveCreate', titulo: 'Nuevo Movimiento'},
	      {url: '/accountActions', titulo: 'Agregar Cuenta'},
	      {url: '/customerActions', titulo: 'Agregar Cliente'},
	      {url: '/categoryActions', titulo: 'Agregar Categoría'}
	    ]
	  }
	},
	{
	  path: '/balance',
	  name: 'Balance Mensual',
	  component: Balance,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/moveCreate/Ingreso/', titulo: 'Nuevo Ingreso'},
	      {url: '/moveCreate/Egreso/', titulo: 'Nuevo Gasto'},
	      {url: '/moveCreate/Transferencia/', titulo: 'Nuevo Transferencia'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/stuffs',
	  name: 'Pendientes',
	  component: Stuffs,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/accounts',
	  name: 'Cuentas',
	  component: Accounts,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/accountActions', titulo: 'Agregar Cuenta'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/accountActions',
	  name: 'Crear Cuenta',
	  component: AccountActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/accountActions/:id',
	  name: 'Editar Cuenta',
	  component: AccountActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/account/:id',
	  name: 'Detalle de Cuenta',
	  component: Account,
	  meta: {
	    requiresAuth: true,
	    header_style: 'white-style',
	    short_links: [
	      {url: '/moveCreate/Ingreso/', titulo: 'Nuevo Ingreso'},
	      {url: '/moveCreate/Egreso/', titulo: 'Nuevo Gasto'},
	      {url: '/moveCreate/Transferencia/', titulo: 'Nuevo Transferencia'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/categories',
	  name: 'Categorías',
	  component: Categories,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/categoryActions', titulo: 'Agregar Categoría'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/categoryActions',
	  name: 'Crear Categoría',
	  component: CategoryActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/categories'
	  }
	},
	{
	  path: '/categoryActions/:id',
	  name: 'Editar Categoría',
	  component: CategoryActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/categories'
	  }
	},
	{
	  path: '/customers',
	  name: 'Clientes',
	  component: Customers,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/customerActions', titulo: 'Agregar Cliente'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/customerActions',
	  name: 'Crear Cliente',
	  component: CustomerActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/customers'
	  }
	},
	{
	  path: '/customerActions/:id',
	  name: 'Editar Cliente',
	  component: CustomerActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/customers'
	  }
	},
	{
	  path: '/customer/:id',
	  name: 'Detalle de Cliente',
	  component: Customer,
	  meta: {
	    requiresAuth: true,
	    header_style: 'white-style',
	    short_links: [
	      {url: '/projectActions', titulo: 'Nuevo Proyecto'}
	    ],
	    back_link: '/customers'
	  }
	},
	{
	  path: '/projects',
	  name: 'Proyectos',
	  component: Projects,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/projectActions', titulo: 'Agregar Proyecto'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/projectActions',
	  name: 'Crear Proyecto',
	  component: ProjectActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/projects'
	  }
	},
	{
	  path: '/projectActions/:id',
	  name: 'Editar Proyecto',
	  component: ProjectActions,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/projects'
	  }
	},
	{
	  path: '/tasks',
	  name: 'Tareas',
	  component: Tasks,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/moves',
	  name: 'Movimientos',
	  component: Moves,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/moveCreate/Ingreso/', titulo: 'Nuevo Ingreso'},
	      {url: '/moveCreate/Egreso/', titulo: 'Nuevo Gasto'},
	      {url: '/moveCreate/Transferencia/', titulo: 'Nuevo Transferencia'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/moveEdit/:id',
	  name: 'Editar Movimiento',
	  component: MoveEdit,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/moveCreate',
	  name: 'Crear Movimiento',
	  component: MoveCreate,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/moveCreate/:tipo',
	  name: 'Crear Movimiento Tipo',
	  component: MoveCreate,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/moveCreate/:tipo/:cuenta',
	  name: 'Crear Movimiento Cuenta',
	  component: MoveCreate,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/quotes',
	  name: 'Cotizaciones',
	  component: Quotes,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [
	      {url: '/createQuote', titulo: 'Agregar Cotización'}
	    ],
	    back_link: '/dashboard'
	  }
	},
	{
	  path: '/viewQuote/:id',
	  name: 'Vizualizar Cotización',
	  component: viewQuote,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/quotes'
	  }
	},
	{
	  path: '/viewQuote/:id/:version',
	  name: 'Vizualizar Versión',
	  component: viewQuote,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/quotes'
	  }
	},
	{
	  path: '/editVersion/:id/:version',
	  name: 'Editar Versión',
	  component: VersionEdit,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/quotes/:id'
	  }
	},
	{
	  path: '/createVersion/:id',
	  name: 'Crear Versión',
	  component: VersionCreate,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/quotes/:id'
	  }
	},
	{
	  path: '/createQuote',
	  name: 'Crear Cotización',
	  component: QuoteCreate,
	  meta: {
	    requiresAuth: true,
	    header_style: '',
	    short_links: [],
	    back_link: '/quotes'
	  }
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior(to, from, savedPosition) {
		var elmnt = document.getElementById('wallet-application')
		elmnt.scrollTo(0, 0)
	},
	routes
})

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	const currentUser = auth.currentUser

	if (requiresAuth) {
		if (!currentUser) {
			//console.log('El ingreso a ' + to.name + ' requiere autorización')
			next('/logout/' + encodeURIComponent(to.path))
		} else {
			//console.log('Ingreso autorizado a usuario')
			next()
		}
	} else {
		//console.log('El ingreso a ' + to.name + ' no requiere autorización')
		//Verificar si es el login y si existe un usuario logeado mandarlo al resumen
		if (to.path == '/' && currentUser) {
			next('/dashboard')
		} else {
			next()
		}
	}
})

router.afterEach((to) => {
	document.title = 'Fottom Flux - ' + to.name
})

export default router
