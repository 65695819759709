<template>
  <div v-if="proyectos.length" class="row h-items-center">
    <div class="col">
      <p v-if="show_title" class="t-complementary">
        {{nombre_cliente}}
      </p>
      <p v-else class="t-complementary">
        Proyectos
      </p>
    </div>
    <div v-if="show_title" class="col-auto">
      <p>
        <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/customer/'+id_cliente)">
          Mostrar Cliente
        </a>
      </p>
    </div>
    <div class="col-12">
      <div class="box-container b-white moves-container no-select task-timers">
        <ul v-if="proyectos.length" class="list-container container">
          <li v-for="proyecto in proyectos" :key="proyecto.id" class="row v-items-top">
           <div class="col-auto" v-on:click.prevent="goTo('/projectActions/'+proyecto.id)">
             <span class="icon-list" v-bind:style="{'background-color': proyecto.color}">
               <b class="t-18-px">{{proyecto.nombre | initialText}}</b>
             </span>
           </div>
           <div class="col" v-on:click.prevent="goTo('/projectActions/'+proyecto.id)">
             <p class="t-complementary t-14-px">
               <span>{{proyecto.nombre}}</span>
             </p>
             <p class="t-complementary t-10-px">
               <span class="t-base">{{proyecto.descripcion}}</span>
             </p>
             <span v-for="(etiqueta, index) in proyecto.servicios" :key="index" class="tag-list" v-if="etiqueta" v-bind:style="{'background-color': etiqueta.color}">
               {{etiqueta.nombre}}
             </span>
           </div>
           <div class="col-auto t-right" v-on:click.prevent="goTo('/projectActions/'+proyecto.id)">
             <p class="t-14-px t-base">
               {{get_total(0, proyecto.id)}}
               <br>
               <span class="t-10-px">Tiempo Invertido</span>
             </p>
           </div>
           <div class="col-auto">
             <a href="#" class="t-complementary" v-on:click.prevent="deleteRow(proyecto.id)">
               <i class="fa fa-trash"></i>
             </a>
           </div>
           <GetTasks v-bind:id_proyecto="proyecto.id" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import GetTasks from '@/components/tasks.vue'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'GetProjects',
  components: {
    GetTasks
  },
  props: {
    show_title: {
      type: Boolean,
      default: false
    },
    id_cliente: {
      type: String
    },
    nombre_cliente: {
      type: String
    }
  },
  data() {
    return {
      proyectos: [], 
      tareas: []
    }
  },
  firestore () {
    return {
      proyectos: db.collection('proyectos').where('id_cliente', '==', this.id_cliente).orderBy('nombre'),
      tareas: db.collection('tareas').orderBy('fecha_inicio', 'desc')
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
    deleteRow(id){
      if(window.confirm('¿Estás Seguro? Esto eliminará este proyecto.')){
        //Colocar proyecto vacio a las tareas que tienen asignado este proyecto antes de borrar
        db.collection('tareas').where('id_proyecto', "==", id)
        .get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                //Actualizar tarea
                db.collection('tareas').doc(doc.id).update({
                  id_proyecto: '',
                  ref_proyecto: ''
                })
                .then(function(docRef) {

                })
                .catch(function(error) {
                    //router.replace('/projects')
                })
            })
        })
        .catch(function(error) {
            console.log("Error getting documents: ", error)
        })
        //Eliminar tarea
        db.collection('proyectos').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    get_total (valor_inicial, ref_id){
      var secs = Number(valor_inicial)
      var horas = '00 hrs'
      for (var i = 0; i <= this.tareas.length - 1; i++){
        if(this.tareas[i].id_proyecto == ref_id){
          if(this.tareas[i].timer){
            for(const time in this.tareas[i].timer){
              if(this.tareas[i].timer[time].fecha_fin && this.tareas[i].timer[time].fecha_inicio){
                secs += Number(this.tareas[i].timer[time].fecha_fin.seconds - this.tareas[i].timer[time].fecha_inicio.seconds)
              }
            }
          }
        }
      }
      if(secs > 0){
        var hours = Math.floor(secs / (60 * 60))
        var divisor_for_minutes = secs % (60 * 60)
        var minutes = Math.floor(divisor_for_minutes / 60)
        var divisor_for_seconds = divisor_for_minutes % 60
        var seconds = Math.ceil(divisor_for_seconds)
        
        var obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        }
        if(obj.h > 0){
          var horas = obj.h + ' hr '
        }else{
          var horas = ''
        }
        if(obj.m > 0){
          var minutos = obj.m + ' min'
        }else{
          var minutos = ''
        }
        horas = horas + minutos
      }
      return horas
    }
  }
}
</script>
