<template>
  <div>
    <section class="layout">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{Math.abs(the_total_ins_estudio) - Math.abs(the_total_outs_estudio) - Math.abs(the_total_saves_mensual) | formatPrice}}</h1>
                <p class="t-upper">
                  Ganancia Mensual
                  <br>
                  <span class="t-12-px t-primary">
                    {{curren_date.getTime() / 1000 | monthFormatDate}}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_ins_estudio | formatPrice}}</h1>
                <p class="t-upper">
                  Ingresos Estudio
                  <br>
                  <span class="t-12-px t-primary">
                    Facturado s/iva: {{the_total_ins_sat | formatPrice}}
                    <br>
                    Sin Facturar s/iva: {{Math.abs(the_total_ins_estudio) - Math.abs(the_total_ins_sat) | formatPrice}}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_outs_estudio | formatPrice}}</h1>
                <p class="t-upper">
                  Gastos Estudio
                  <br>
                  <span class="t-12-px t-primary">
                    Facturado s/iva: {{Math.abs(the_total_outs_sat) / 1.16 | formatPrice}}
                    <br>
                    ISR Retenido: {{Math.abs(the_total_outs_isr) | formatPrice}}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_iva_in | formatPrice}}</h1>
                <p class="t-upper">
                  IVA
                  <br>
                  <span class="t-12-px t-primary">
                    Recibido
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_iva_out | formatPrice}}</h1>
                <p class="t-upper">
                  IVA
                  <br>
                  <span class="t-12-px t-primary">
                    Gastado
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_isr | formatPrice}}</h1>
                <p class="t-upper">
                  ISR a Pagar
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_iva | formatPrice}}</h1>
                <p class="t-upper">
                  IVA a Pagar
                </p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_saves_mensual | formatPrice}}</h1>
                <p class="t-upper">
                  Ahorro Mensual
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_isr_pagado | formatPrice}}</h1>
                <p class="t-upper">
                  ISR Pagado {{set_month(curren_date, 'prev').getTime() / 1000 | monthFormatDate}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="box-container b-white t-center">
              <div class="slide-resume">
                <h1 class="t-48-px t-semibold t-complementary">{{the_total_iva_pagado | formatPrice}}</h1>
                <p class="t-upper">
                  IVA Pagado {{set_month(curren_date, 'prev').getTime() / 1000 | monthFormatDate}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="layout">
      <div class="container">
        <div class="row v-items-bottom">
          <div class="col">
            <p class="t-complementary t-center">
              Actividad Mensual
            </p>
          </div>
        </div>
      </div>
      <div class="container" id="container-moves-home">
        <div class="row h-items-center">
          <GetMoves v-bind:movimientos="movimientos" v-bind:limite="limit_pagination" />
        </div>
      </div>
    </section>
    {{set_values}}
  </div>
</template>

<script>
import { db } from '@/firebase'
import GetMoves from '@/components/moves.vue'
export default {
  name: 'BalanceContent',
  components: {
    GetMoves
  },
  props: {
    curren_date: {
      required: false,
      default: function () { return new Date() }
    }
  },
  data (){
    return{
      the_total_ins_sat: 0, //Total Ingresos Facturados
      the_total_iva_in: 0, //Total Iva Retenido en Ingresos
      the_total_ins_no_sat: 0, //Total Ingresos No Facturados
      the_total_ins: 0, //Total Ingreso Mensual Incluyendo IVA Retenido
      the_total_ins_estudio: 0, //Total Ingreso Mensual Del Estudio Incluyendo IVA Retenido
      the_total_outs_sat: 0, //Total Egresos Facturados Incluyendo IVA Transladado No incluye gasto Internacional
      the_total_outs_estudio: 0, //Total Egresos Mensual Del Estudio Incluyendo IVA Retenido
      the_total_isr_retenido: 0, //Isr retenido correspondiente al mes corriente
      the_total_iva_retenido: 0, //Iva retenido correspondiente al mes corriente
      the_total_isr_pagado: 0, //Isr pagado correspondiente al mes anterior
      the_total_iva_pagado: 0, //Iva pagado correspondiente al mes anterior
      the_total_saves_mensual: 0, //Ahorro en este mes corriente
      the_total_iva_out: 0, //Total Iva Transferido en Egresos
      the_total_outs: 0, //Total Egreso Mensual Incluyendo Pago impuestos isr e iva siempre y cuando no sea un movimiento fantasma
      the_total_outs_internacional: 0, //Total Egresos Internacionales
      the_total_outs_isr: 0, //Total ISR retenido por personas morales
      the_total_isr: 0, //Calculo de isr a pagar calculado en base a los ingresos del mes sin iva menos los gastos del mes facturados sin iva
      the_total_iva: 0, //Calculo de iva a pagar calculado del iva retenido - el iva transladado
      limit_pagination: 10,
      movimientos: [],
    }
  },
  firestore () {
    return {
      movimientos: db.collection('movimientos').where('mes_corriente', '==', (this.curren_date.getMonth() + 1)).where('ano_corriente', '==', this.curren_date.getFullYear()).orderBy('fecha_creacion', 'desc')
    }
  },
  computed: {
    set_values (){
      //Set en 0 variables antes de calcular
      this.the_total_ins_sat = 0
      this.the_total_iva_in = 0
      this.the_total_ins_no_sat = 0
      this.the_total_ins = 0
      this.the_total_ins_estudio = 0
      this.the_total_outs_sat = 0
      this.the_total_outs_estudio = 0
      this.the_total_isr_retenido = 0
      this.the_total_iva_retenido = 0
      this.the_total_isr_pagado = 0
      this.the_total_iva_pagado = 0
      this.the_total_saves_mensual = 0
      this.the_total_iva_out = 0
      this.the_total_outs = 0
      this.the_total_outs_internacional = 0
      this.the_total_outs_isr = 0
      this.the_total_isr = 0
      this.the_total_iva = 0
      //Actializamos los datos
      var vv = this

      vv.$emit('getStatusMoves', false)

      //Repasar los movimientos para calcular valores
      for (var i = 0; i <= this.movimientos.length - 1; i++){
        var move = this.movimientos[i]

        if(move.id_tipo_movimiento == 'HDPLGCGRFI4h08Oyw2q2'){//Si el movimiento es un Ingreso evitara que una transferencia sume dinero que no entro
            vv.the_total_ins += Number(move.monto)
            for(var z = 0; z <= move.etiquetas.length - 1; z++){
              if(move.etiquetas[z].id == 'qQRPtjkJrcRk8qw33Ogl'){//Si encuentra una etiqueta perteneciente al id de Iva
                vv.the_total_iva_in += Number(move.monto)
              }
              if(move.etiquetas[z].id == 'Gg9TwJxFKdOxCKOFFIXK'){//Si encuentra una etiqueta perteneciente al id de Facturado
                vv.the_total_ins_sat += Number(move.monto)
              }
              if(move.etiquetas[z].id == 'aTpiP3JF3YA2Gu6xoQ9B'){//Si encuentra una etiqueta perteneciente al id de Interno
                vv.the_total_ins_estudio += Number(move.monto)
              }
            }
        }else if(Number(move.monto) < 0){//Si el movimiento es un Egreso o transferencia negativa
            if(move.id_tipo_movimiento == 'QGCpBfjBKd6o8xCBcSAl'){//Solo si el tipo de movimiento es un Egreso
              if(move.id_cuenta != 'O2SnmMZminZ6KP8B75z3'){//Sumar a gasto del mes si no es un movimiento fantasma
                vv.the_total_outs += Number(move.monto)
              }
            }
            var isInternoFacturado = 0
            for(var z = 0; z <= move.etiquetas.length - 1; z++){
              //Calcula gastos realizados que fueron facturados
              if(move.etiquetas[z].id == 'Gg9TwJxFKdOxCKOFFIXK'){//Si encuentra una etiqueta perteneciente al id de Facturado
                vv.the_total_outs_sat += Math.abs(Number(move.monto))
                isInternoFacturado++ //Sumar concidencia con etiqueta
              }
              if(move.etiquetas[z].id == 'Ade1I8HgRhNWfGphKYNh'){//Si encuentra una etiqueta perteneciente al id de Extranjero
                vv.the_total_outs_internacional += Number(move.monto)
                //Eliminar el gasto extranjero puesto que no genera iva y se contempla el gasto entero al calcular el isr
                vv.the_total_outs_sat -= Math.abs(vv.the_total_outs_internacional)
              }
              if(move.etiquetas[z].id == 'aTpiP3JF3YA2Gu6xoQ9B'){//Si encuentra una etiqueta perteneciente al id de Interno
                if(move.id_tipo_movimiento == 'QGCpBfjBKd6o8xCBcSAl'){//Solo si el tipo de movimiento es un Egreso
                  if(move.id_cuenta != 'O2SnmMZminZ6KP8B75z3'){//Si el movimiento no pertenece a la cuenta gastos fantasmas sumar al gasto interno
                    vv.the_total_outs_estudio += Number(move.monto)
                  }
                }
                isInternoFacturado++ //Sumar concidencia con etiqueta
              }
            }
        }
        //asignar isr pagado por categoria
        if(move.id_categoria == '1LVKhrGbjQQb2Nuimt4z'){
          vv.the_total_isr_pagado += Number(move.monto)
        }
        //asignar iva pagado por categoria
        if(move.id_categoria == 'sfZC8SpBaoe69Z23hcS6'){
          vv.the_total_iva_pagado += Number(move.monto)
        }
        //asignar isr retenido por categoria
        if(move.id_categoria == 'jygRd5YUfyh8JpJdwG6V'){
          vv.the_total_isr_retenido += Number(move.monto)
        }
        //asignar iva retenido por categoria
        if(move.id_categoria == 'YARxFJQAfiMby94u449a'){
          vv.the_total_iva_retenido += Number(move.monto)
        }
        //asignar ahorro del mes
        if(move.id_cuenta == 'igJZPb5KdA6HvzQQPsTO'){
          vv.the_total_saves_mensual = Number(move.monto)
        }
        //asignar ISR retenido por persona moral
        if(move.id_categoria == 'jygRd5YUfyh8JpJdwG6V'){
          vv.the_total_outs_isr += Number(move.monto)
        }
        
      }

      /*
      //Calcular el ISR previo RESICO
      var tabuladores = [
        {limite_inferior:0.01, limite_superior:578.52, cuota_fija:0.00, tasa:1.92}, 
        {limite_inferior:578.53, limite_superior:4910.18, cuota_fija:11.11, tasa:6.40}, 
        {limite_inferior:4910.19, limite_superior:8629.20, cuota_fija:288.33, tasa:10.88}, 
        {limite_inferior:8629.21, limite_superior:10031.07, cuota_fija:692.96, tasa:16.00}, 
        {limite_inferior:10031.08, limite_superior:12009.94, cuota_fija:917.26, tasa:17.92}, 
        {limite_inferior:12009.95, limite_superior:24222.31, cuota_fija:1271.87, tasa:21.36}, 
        {limite_inferior:24222.32, limite_superior:38177.69, cuota_fija:3880.44, tasa:23.52}, 
        {limite_inferior:38177.70, limite_superior:72887.50, cuota_fija:7162.74, tasa:30.00}, 
        {limite_inferior:72887.51, limite_superior:97183.33, cuota_fija:17575.69, tasa:32.00}, 
        {limite_inferior:97183.34, limite_superior:291550.00, cuota_fija:25350.35, tasa:34.00}, 
        {limite_inferior:291550.01, limite_superior:999999999, cuota_fija:91435.02, tasa:35.00}
      ]
      //Obtenemos los gastos totales comprobables restando al ingreso facturado sin contar el iva
      //Restamos el total de los gastos sin contemplar el iva
      //Restamos el total de los gastos internacionales que no agraban iva
      const ganancias_totales = Math.abs(vv.the_total_ins_sat) - Math.abs(vv.the_total_outs_sat / 1.16) - Math.abs(vv.the_total_outs_internacional)
      var total_isr = 0
      for (var w = 0; w <= tabuladores.length - 1; w++){
        if(ganancias_totales >= tabuladores[w].limite_inferior && ganancias_totales <= tabuladores[w].limite_superior){
          var diferencia_tabulador = ganancias_totales - tabuladores[w].limite_inferior
          var impuesto_marginal = diferencia_tabulador / 100 * tabuladores[w].tasa
          total_isr = impuesto_marginal + tabuladores[w].cuota_fija
          break
        }
      }
      //Definir el total de isr a pagar contemplando el retenido por clientes
      vv.the_total_isr = total_isr - Math.abs(vv.the_total_isr_retenido)*/

      //Calcular el ISR RESICO
      if(vv.the_total_ins_sat <= 25000.00){
      	vv.the_total_isr = Math.abs( (vv.the_total_ins_sat / 100) * 1.00 )
      }else if(vv.the_total_ins_sat <= 50000.00){
      	vv.the_total_isr = Math.abs( (vv.the_total_ins_sat / 100) * 1.10 )
      }else if(vv.the_total_ins_sat <= 83333.33){
      	vv.the_total_isr = Math.abs( (vv.the_total_ins_sat / 100) * 1.50 )
      }else if(vv.the_total_ins_sat <= 208333.33){
      	vv.the_total_isr = Math.abs( (vv.the_total_ins_sat / 100) * 2.00 )
      }else if(vv.the_total_ins_sat <= 3500000.00){
      	vv.the_total_isr = Math.abs( (vv.the_total_ins_sat / 100) * 2.50 )
      }

      //Calcular el Iva recibido contemplando en cuenta el retenido por clientes
      vv.the_total_iva_in = Math.abs(vv.the_total_iva_in) - Math.abs(vv.the_total_iva_retenido)
      //Calcular total de ingreso sin facturar
      vv.the_total_ins_no_sat = Math.abs(vv.the_total_ins) - Math.abs(vv.the_total_ins_sat) - Math.abs(vv.the_total_iva_in)
      //Calcula el iva correspondiente al gasto facturado
      vv.the_total_iva_out = Math.abs(vv.the_total_outs_sat) / 1.16 * 0.16
      //Calcular el Iva a pagar contemplando el retenido por clientes
      vv.the_total_iva = Math.abs(vv.the_total_iva_in) - Math.abs(vv.the_total_iva_out)
      //Calcula el dinero real despues de pagar impuestos
      //Calcular gasto interno del estudio se resta el gasto del pago de iva
      vv.the_total_outs_estudio = Math.abs(vv.the_total_outs_estudio) - Math.abs(vv.the_total_iva_pagado)

      setTimeout(function() {
      	//Permitimos mostrar valores
      	vv.$emit('getStatusMoves', true)
      }, 1000)
      
    },
  },
  methods: {
    set_month (curren_date, type){
      var prev_month = new Date(curren_date.getFullYear(), curren_date.getMonth()-1, 1)
      var next_month = new Date(curren_date.getFullYear(), curren_date.getMonth()+1, 1)
      if(type == 'prev'){
        return prev_month
      }else{
        return next_month
      }
    },
  }
}
</script>
