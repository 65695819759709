<template>
  <div class="screen page categories b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row h-items-center">
            <GetCategories />
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetCategories from '@/components/categories.vue'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetCategories
  }
}
</script>