<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      {{validateData}}
      <form v-on:submit.prevent="actionForm()" class="form layout">
        <div class="container">
          <div class="row">
            <div class="col-12" v-bind:class="{'filled': nombre != ''}">
              <label for="nombre">Nombre</label>
              <input id="nombre" type="text" v-model="nombre" placeholder="Escribe algo...">
            </div>
            <div class="col-12" v-bind:class="{'filled': descripcion != ''}">
              <label for="descripcion">Descripción</label>
              <input id="descripcion" type="text" v-model="descripcion" placeholder="Escribe algo...">
            </div>
            <div class="col-6 phone-c-12" v-bind:class="{'filled': id_cliente}">
              <label>Cliente:</label>
              <multiselect 
              v-model="id_cliente" 
              :options="clientes" 
              :multiple="false" 
              :show-labels="false" 
              :close-on-select="true" 
              :hide-selected="false" 
              :preserve-search="true" 
              :searchable="false" 
              placeholder="Selecciona Un Cliente"  
              label="nombre" 
              track-by="id" 
              :allow-empty="true" 
              :preselect-first="false">
              <span slot="noResult">No se encontraron resultados.</span>
              </multiselect>
            </div>
            <div class="col-6 phone-c-12" v-bind:class="{'filled': servicios_seleccionados.length}">
              <label>Servicios:</label>
              <multiselect 
              v-model="servicios_seleccionados" 
              :options="servicios" 
              :multiple="true" 
              :show-labels="false" 
              :close-on-select="true" 
              :hide-selected="true" 
              :preserve-search="true" 
              :searchable="false" 
              placeholder="Selecciona Servicios"  
              label="nombre" 
              track-by="id" 
              :allow-empty="true" 
              :preselect-first="false">
              <span slot="noResult">No se encontraron resultados.</span>
              </multiselect>
            </div>
            <div class="col-12">
              <label for="color">Selecciona un Color</label>
              <swatches v-model="color" :colors="colors" row-length="8" popover-to="right" swatch-size="30"></swatches>
            </div>
            <div class="col-12">
              <ul v-if="messages.length" class="messages-container">
                <p class="t-14-px">Verifica lo siguiente:</p>
                <li v-for="(message, index) in messages" v-bind:class="['message-item t-12-px', message.type]"><i class="fas fa-times" @click.prevent.stop="delete_message(message)"></i> {{message.content}}</li>
              </ul>
            </div>
            <div class="col-12">
              <button v-if="id" type="submit" class="btn b-secondary">Guardar Cambios</button>
              <button v-else type="submit" class="btn b-secondary">Crear Nuevo Elemento</button>
            </div>
          </div>
        </div>
      </form>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import Multiselect from 'vue-multiselect'
import router from '@/router'
import {getColors} from '@/colors.js'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    GetFooter,
    Multiselect,
    Swatches
  },
  data() {
    return {
      action_state: true, 
      id: this.$route.params.id,
      nombre: '',
      descripcion: '',
      id_cliente: '',
      servicios_seleccionados: '',
      color: '#1FBC9C',
      proyectos: [],
      messages: [],
      colors: getColors,
      clientes: [],
      servicios: []
    }
  },
  firestore () {
    return {
      proyectos: db.collection('proyectos').orderBy('nombre'),
      clientes: db.collection('clientes').orderBy('nombre', 'asc'),
      servicios: db.collection('servicios').orderBy('nombre', 'asc'),
    }
  },
  watch: {
    nombre () {
      this.varificar_modificaciones
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  },
  computed: {
    validateData (){
      if(this.id){
        for(const i in this.proyectos){
          if(this.proyectos[i].id == this.id){
            this.nombre = this.proyectos[i].nombre
            this.descripcion = this.proyectos[i].descripcion
            this.id_cliente = this.proyectos[i].ref_cliente
            this.servicios_seleccionados = this.proyectos[i].servicios
            this.color = this.proyectos[i].color
            break
          }
        }
      }
    },
    varificar_modificaciones (){
      //Siempre y cuando no se este editando un movimiento verificar si se modifico un campo
      if(!this.id){
        if(this.nombre != '' || this.descripcion != '' || this.id_cliente || this.servicios_seleccionados.length){
          //Si se ha modificado cualquiera de los campos preguntar antes de salir
          this.action_state = false
        }else{
          this.action_state = true
        }
      }else{
        this.action_state = false
      }
    }
  },
  methods: { 
    delete_message (value){
     this.messages.splice(this.messages.indexOf(value), 1)
    },
    actionForm () {
      const id_proyecto = this.id
      var item_nombre = this.nombre
      var item_descripcion = this.descripcion
      var item_id_cliente = this.id_cliente
      let item_servicios = []
      if(this.servicios_seleccionados){
        let todos_servicios = this.servicios_seleccionados.sort(function(a,b) {return (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0);} ).reverse()
        for (var i = todos_servicios.length - 1; i >= 0; i--) {
          item_servicios.push(db.collection('servicios').doc(todos_servicios[i].id))
        }
      }
      var item_color = this.color
      var item_creacion = new Date()

      this.messages = []

      if(!item_nombre) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar un nombre'
        }
        this.messages.push(the_message)
      }

      if(!item_descripcion) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Descripción Requerida.'
        }
        this.messages.push(the_message)
      }

      if(!item_id_cliente) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona un cliente.'
        }
        this.messages.push(the_message)
      }

      if(!item_color) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario seleccionar un color'
        }
        this.messages.push(the_message)
      }

      if(!this.messages.length) {
        this.action_state = true
        if(id_proyecto){
          db.collection('proyectos').doc(id_proyecto).update({
            color: item_color,
            fecha_modificacion: item_creacion,
            nombre: item_nombre,
            descripcion: item_descripcion,
            id_cliente: item_id_cliente.id,
            ref_cliente: db.collection('clientes').doc(item_id_cliente.id),
            servicios: item_servicios
          })
          .then(function(docRef) {
              //router.push('/project/'+id_proyecto)
          })
          .catch(function(error) {
              //router.replace('/projects')
          })
          router.replace('/projects')
        }else{
          db.collection('proyectos').add({
            color: item_color,
            fecha_creacion: item_creacion,
            fecha_modificacion: item_creacion,
            nombre: item_nombre,
            descripcion: item_descripcion,
            id_cliente: item_id_cliente.id,
            ref_cliente: db.collection('clientes').doc(item_id_cliente.id),
            servicios: item_servicios
          })
          .then(function(docRef) {
              //router.replace('/project/'+docRef.id)
          })
          .catch(function(error) {
              //router.replace('/projects')
          })
          router.replace('/projects')
        }
      }
      
    }
  }
}
</script>
