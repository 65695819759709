<template>
  <div class="screen page balance b-soft">
    <div class="grid" :style="[the_show_values == false ? {'display': 'none'} : {}]">
      <section class="layout">
        <div class="container">
          <div class="row v-items-center month-nav-container">
            <div class="col-1">
              <a href="#" class="link t-12-px" v-on:click.prevent="curren_date = set_month(curren_date, 'prev');"><i class="fa fa-arrow-left"></i><span>Anterior</span></a>
            </div>
            <div class="col t-center t-upper">
              {{curren_date.getTime() / 1000 | monthFormatDate}}
            </div>
            <div class="col-1 t-right">
              <a v-if="parseInt(set_month(curren_date, 'next').getFullYear()) <= parseInt(new Date().getFullYear())" href="#" class="link t-12-px" v-on:click.prevent="curren_date = set_month(curren_date, 'next');"><span>Siguiente</span><i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
      </section>
      <BalanceContent v-bind:curren_date="curren_date" :key="curren_date.getMonth()" v-on:getStatusMoves="onStatusMoveChange" />
      <GetFooter />
    </div>
    <div class="grid" :style="[the_show_values == true ? {'display': 'none'} : {}]">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="box-container b-white t-center">
              <div class="load-container">
                <img src="@/assets/fottom-load.gif" alt="Fottom Flux" class="logo-load">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import BalanceContent from '@/components/balance_content.vue'
import { db } from '@/firebase'

export default {
  name: 'app',
  components: {
    GetFooter,
    BalanceContent
  },
  data() {
    return {
      the_show_values: false,
      curren_date: new Date(),
    }
  },
  methods: {
    onStatusMoveChange (new_value) {
      this.the_show_values = new_value
    },
    set_month (curren_date, type){
      var prev_month = new Date(curren_date.getFullYear(), curren_date.getMonth()-1, 1)
      var next_month = new Date(curren_date.getFullYear(), curren_date.getMonth()+1, 1)
      if(type == 'prev'){
        return prev_month
      }else{
        return next_month
      }
    },
  }
}
</script>