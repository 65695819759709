<template>
  <div class="col-12">
    <div class="row v-items-center month-nav-container">
      <div class="col-12 box-container b-white">
        <span class="t-10-px">
          <input id="filter_busqueda" type="text" v-model="filter_busqueda" placeholder="Buscar...">
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 box-container b-white moves-container no-select">
        <ul v-if="cotizaciones_filtradas.length" class="list-container container">
          <li v-for="cotizacion in cotizaciones_filtradas" :key="cotizacion.id">
            <div v-if="cotizacion.cotizar" class="row v-items-center">
              <div class="col-auto" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <span class="icon-list" v-bind:style="{'background-color': cotizacion.ref_cliente.color}">
                  <b class="t-18-px">{{cotizacion.ref_cliente.nombre | initialText}}</b>
                </span>
              </div>
              <div class="col" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <p class="t-complementary t-14-px">
                  <span>{{cotizacion.cotizar + ' - ' + cotizacion.nombre_proyecto}}</span>
                </p>
                <span v-for="producto in cotizacion.productos" class="tag-list b-secondary" v-if="producto.opciones && producto.opciones.length > 1">
                  Rango de Precios
                </span>
                <span v-for="(servicio, index) in cotizacion.servicios" :key="index" class="tag-list" v-if="servicio" v-bind:style="{'background-color': servicio.color}">
                  {{servicio.nombre}}
                </span>
              </div>
              <div class="col-auto t-right" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <p class="t-14-px t-primary">
                  <span v-if="get_precio(cotizacion.productos)">{{get_precio(cotizacion.productos) | formatPrice}}</span>
                  <span v-else>Sin Precio</span>
                  <br>
                  <span class="t-upper t-10-px t-base">{{cotizacion.fecha_modificacion.seconds | beautyFormatDate}}</span>
                </p>
              </div>
              <div class="col-auto">
                <a href="#" class="t-complementary" v-on:click.prevent="deleteQuote(cotizacion.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </div>
            </div>
            <div v-else class="row v-items-center">
              <div class="col-auto" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <span class="icon-list">
                  <b class="t-18-px">?</b>
                </span>
              </div>
              <div class="col" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <p class="t-complementary t-14-px">
                  <span>No se encontraron datos en esta versión</span>
                  <br>
                  <span class="t-upper t-10-px t-base">Ingresa para editarla</span>
                </p>
              </div>
              <div class="col-auto t-right" v-on:click.prevent="goTo('/viewQuote/'+cotizacion.id)">
                <p class="t-14-px t-primary">
                  {{0 | formatPrice}}
                  <br>
                  <span class="t-upper t-10-px t-base">{{cotizacion.fecha_creacion.seconds | beautyFormatDate}}</span>
                </p>
              </div>
              <div class="col-auto">
                <a href="#" class="t-complementary" v-on:click.prevent="deleteQuote(cotizacion.id)">
                  <i class="fa fa-trash"></i>
                </a>
              </div>
            </div>
          </li>
        </ul>
        <p v-else class="t-center">
          <i class="fas fa-sad-cry t-48-px"></i>
          <br>
          <br>
          No se encontraron cotizaciones
        </p>
      </div>
      <div class="col-12">
        <p v-if="siguiente" class="t-14-px t-center">
          <button v-on:click="mostrar_mas()" class="link">Cargar {{limite_cotizaciones}} más</button>
        </p>
        <p v-else class="t-14-px t-center">
          No existen más cotizaciones
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'GetQuotes',
  data() {
    return {
      total_filters: 0,
      filter_busqueda: '',
      status_busqueda: false,
      ultima_cotizacion: '',
      limite_cotizaciones: 10,
      pagina: 1,
      paginas: 0,
      siguiente: false,
      cotizaciones: [],
      cotizaciones_filtradas: [],
    }
  },
  firestore () {
    return {
      //cotizaciones: db.collection('cotizaciones').orderBy('fecha_modificacion', 'desc').limit(this.limite_cotizaciones)
    }
  },
  created() {

    this.comenzar()

  },
  watch: {
    filter_busqueda: function () {
      this.total_filters = this.filter_busqueda.length
      if(this.cotizaciones.length <= (this.limite_cotizaciones * this.pagina) && this.status_busqueda === false){
        //Solo se cargaran todas cuando se encuentren pocas cotizaciones
        this.status_busqueda = true
        //Cargar todas las cotizaciones
        this.mostrar_todas()
      }else{
        //Filtrar cotizaciones
        this.actualizar_cotizaciones()
      }
    }
  },
  methods: {
    comenzar (){

      var vm = this

      vm.ultima_cotizacion = false

      vm.cotizaciones = []

      let first = db
        .collection('cotizaciones')
        .orderBy('fecha_modificacion', 'desc')
        .limit(vm.limite_cotizaciones)

      first.get().then(async documentSnapshots => {

        db.collection('cotizaciones').get().then(res => {
          vm.paginas = Math.ceil((res.size / this.limite_cotizaciones))
          if(vm.paginas > 1){
            vm.siguiente = true
          }
        })

        //Obtener ultimo documento de la consulta
        vm.ultima_cotizacion = documentSnapshots.docs[documentSnapshots.docs.length - 1]

        documentSnapshots.forEach(doc => {
          const documento_inicio = doc.data()
          documento_inicio.id = doc.id
          vm.cotizaciones.push(documento_inicio)
        })

      })

      vm.actualizar_cotizaciones()

    },
    mostrar_mas (){

      var vm = this

      //Validar que la pagina actual sea menor al total de paginas menos 1
      if(vm.pagina < (vm.paginas - 1)){
        vm.siguiente = true
      }else{
        vm.siguiente = false
      }

      const next = db
        .collection('cotizaciones')
        .orderBy('fecha_modificacion', 'desc')
        .startAfter(vm.ultima_cotizacion)
        .limit(vm.limite_cotizaciones)
        //Onsnapshot revisa si se actualiza la base de datos https://firebase.google.com/docs/firestore/query-data/listen
        /*.onSnapshot(function(snapshot) {
          var add_array = true

          snapshot.docChanges().forEach(function(change) {
            console.log(change)
              //if (change.type === "added") {}
              if (change.type === "modified") {
                  add_array = false
              }
              if (change.type === "removed") {
                  add_array = false
              }
          })
        })*/
        .get()
        .then(snapshot => {

          //Obtener ultimo documento de la consulta
          vm.ultima_cotizacion = snapshot.docs[snapshot.docs.length - 1]
          //Sumar pagina
          vm.pagina += 1

          snapshot.forEach(doc => {
            const documento_siguiente = doc.data()
            documento_siguiente.id = doc.id
            vm.cotizaciones.push(documento_siguiente)
          })

          this.actualizar_cotizaciones()

        })
    },
    mostrar_todas (){

      var vm = this

      //Evitar que aparezca la opcion de cargar mas
      vm.siguiente = false

      const next = db
        .collection('cotizaciones')
        .orderBy('fecha_modificacion', 'desc')
        .startAfter(vm.ultima_cotizacion)
        .limit(9999)
        .get()
        .then(snapshot => {

          //Obtener ultimo documento de la consulta
          vm.ultima_cotizacion = snapshot.docs[snapshot.docs.length - 1]
          //Sumar pagina
          vm.pagina += 1

          snapshot.forEach(doc => {
            const documento_siguiente = doc.data()
            documento_siguiente.id = doc.id
            vm.cotizaciones.push(documento_siguiente)
          })
          
          this.status_busqueda = true

          this.actualizar_cotizaciones()

        })
    },
    actualizar_cotizaciones (){
      if(this.total_filters > 0){
        this.cotizaciones_filtradas = this.cotizaciones.filter(this.get_filters).sort(this.compare)
      }else{
        this.cotizaciones_filtradas = this.cotizaciones
      }
    },
    compare (a, b){

      //Ordenar el arreglo
      var short_a = 1
      var short_b = -1

      if (a.fecha_modificacion.seconds < b.fecha_modificacion.seconds){
        return short_a
      }else if (a.fecha_modificacion.seconds > b.fecha_modificacion.seconds){
        return short_b
      }else{
        return 0
      }

    },
    get_filters (event){

      var vm = this

      var resultado_comprobacion = false

      var resultado_cliente = 0

      var resultado_servicios = 0

      if(new RegExp(this.filter_busqueda,'i').test(event.cotizar)){
        resultado_comprobacion = true
      }else if(new RegExp(this.filter_busqueda,'i').test(event.nombre_proyecto)){
        resultado_comprobacion = true
      }else if(new RegExp(this.filter_busqueda,'i').test(event.ref_cliente.nombre)){
        resultado_comprobacion = true
      }

      return resultado_comprobacion
    },
    restore_fitlers (){
      this.total_filters = 0,
      this.filter_busqueda = '',

      this.actualizar_cotizaciones()
    },
    goTo (url){
      router.replace({path: url})
    },
    deleteQuote(id){
      var vm = this
      if(window.confirm('¿Estás Seguro? Esto eliminará el elemento permanentemente.')){
        //Elimiar versiones de la cotizacion
        db.collection('cotizaciones').doc(id).collection('versiones')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            doc.ref.delete();
          })
        })
        //Eliminar cotizacion
        db.collection('cotizaciones').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
          //Buscar dentro del arreglo y eliminar el elemento eliminado de la base de datos
          for (var i = vm.cotizaciones.length - 1; i >= 0; --i) {
              if (vm.cotizaciones[i].id == id) {
                  vm.cotizaciones.splice(i,1);
              }
          }
          //Actualizar arreglo 
          vm.actualizar_cotizaciones()
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    get_precio (productos_cotizados){
      var total_cotizacion = 0
      for(const turno in productos_cotizados){
        if(productos_cotizados[turno].opciones){
          var opcion_alta = 0
          for(const opcion in productos_cotizados[turno].opciones){
            if(productos_cotizados[turno].opciones[opcion].precio > opcion_alta){
              opcion_alta = Number(productos_cotizados[turno].opciones[opcion].precio)
            }
          }
          total_cotizacion += Number(opcion_alta)
        }else{
          //console.log(productos_cotizados[turno].precio)
          total_cotizacion += Number(productos_cotizados[turno].precio)
        }
      }
      if(total_cotizacion > 0){
        return total_cotizacion
      }else{
        return false
      }
    }
  }
}
</script>
