<template>
  <div class="screen login-screen">
    <div class="grid">
      <section class="layoout">
        <div class="container">
          <div class="row h-items-center">
            <div class="col">
              <img src="@/assets/fottom-full-logo.svg" alt="Fottom Flux" id="logo-login">
              <h1 class="t-32-px t-bold t-complementary t-upper">
                Manten el <br>control de tu <br>negocio con <span class="t-primary">Fottom</span> <sup class="t-base t-12-px">Flux</sup>
              </h1>
              <p>
                Por favor, inicia sesión con el usuario y contraseña que se te asignaron.
              </p>
              <form v-on:submit.prevent="logIn()" class="form" autocomplete="on">
                <label for="user">Usuario</label>
                <input id="user" type="text" name="user" v-model="the_user" placeholder="usuario@mail.com">
                <label for="pass">Contraseña</label>
                <div class="icon-wrap">
                  <input id="pass" v-bind:type="type_field" name="pass" v-model="the_pass" placeholder="contraseña">
                  <i class="far" v-bind:class="{'fa-eye': !state_field, 'fa-eye-slash': state_field}" v-on:click.prevent="state_field = !state_field"></i>
                </div>
                <ul v-if="messages.length" class="messages-container">
                  <p class="t-14-px">Verifica lo siguiente:</p>
                  <li v-for="(message, index) in messages" v-bind:class="['message-item t-12-px', message.type]"><i class="fas fa-times" @click.prevent.stop="delete_message(message)"></i> {{message.content}}</li>
                </ul>
                <button type="submit" class="btn b-primary">Iniciar Sesión</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import router from '@/router'
import { db, auth } from '@/firebase'
export default {
  name: 'app',
  data () {
    return {
      the_user: '',
      the_pass: '',
      type_field: 'password',
      state_field: false,
      messages: []
    }
  },
  components:{
    GetFooter
  },
  watch: {
    state_field: function() {
      if(this.state_field){
        this.type_field = 'text'
      }else{
        this.type_field = 'password'
      }
    }
  },
  methods:{
    delete_message (value){
     this.messages.splice(this.messages.indexOf(value), 1)
    },
  	logIn() {
  		this.type_field = 'password'
  		this.messages = []
  		var content_messages = []
  		var url_login = this.$route.params.ruta_intento_ingreso
  		//Validar que exista usuario y contraseña
  		if (this.the_user && this.the_pass) {
  			auth.signInWithEmailAndPassword(this.the_user, this.the_pass)
  				.then(function() {
  					//Obtener datos usuario
  					db.collection('usuarios').doc(auth.currentUser.uid).get()
  					.then(function (doc) {
  						//Inicio sesion exitoso
  						const the_message = {
  							type: 'success b-secondary',
  							content: 'logging in'
  						}
  						content_messages.push(the_message)

  						var url_ingreso = '/dashboard'

  						//Enviar a la ruta
  						if (url_login) {
  							//Se encontro una url previa al intento de ingreso
  							url_ingreso = decodeURIComponent(url_login)
  						}

  						router.push({ path: url_ingreso })
  					})
  					.catch(function (error) {
  						console.log(error)
  					})
  				})
  				.catch(function(error) {
  					//Inicio sesion fallido
  					const the_message = {
  						type: 'fatal b-fail',
  						content: error.message
  					}
  					//Plasmar errores
  					content_messages.push(the_message)
  				})
  		} else {
  			const the_message = {
  				type: 'fatal b-fail',
  				content: 'enter a username and password'
  			}
  			//Plasmar errores
  			content_messages.push(the_message)
  		}
  		//Asignar arreglo de mensajes
  		this.messages = content_messages
  	},
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>