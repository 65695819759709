import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

//Configurar datos de firebase
firebase.initializeApp({
  apiKey: 'AIzaSyB9oP8w7AqBPxby4BlVe1aybLHlt9Z3wjo',
  authDomain: 'fottom-wallet.firebaseapp.com',
  databaseURL: 'https://fottom-wallet.firebaseio.com',
  projectId: 'fottom-wallet',
  storageBucket: 'fottom-wallet.appspot.com',
  messagingSenderId: '534417440126'
})

//Configuracion de firebase
const conn_vuefire = firebase.firestore()
//Si se utiliza Firebase JS SDK < 5.8.0
/*const settings = {timestampsInSnapshots: true}
conn_vuefire.settings(settings)*/

//Variables para conexion
const db = conn_vuefire
const auth = firebase.auth()

//Limitar cache
db.settings({
  cacheSizeBytes: 10 * 1000000 //25 * 1000000 25 MB //firebase.firestore.CACHE_SIZE_UNLIMITED //https://firebase.google.com/docs/firestore/manage-data/enable-offline
})

//Habilitar soporte offline
db.enablePersistence()
.then(function() {
    //Iniciar Cloud Firestore a través de firebase
    console.log('Conexión Establecida')
})
.catch(function(err) {
    if(err.code == 'failed-precondition'){
      //Existe mas de una pestaña abierta
      alert('Existe más de una pestaña usando esta app, la persistencia solo funciona en una pestaña a la vez')
    }else if (err.code == 'unimplemented'){
      //Sin soporte para el navegador
      alert('Este navegador no soporta todas las configuraciones necesarias para habilitar la persistencia')
    }
})

export {
  db, 
  auth
}