const getColors = [
'#1FBC9C', 
'#EF5350',
'#EC407A',
'#AB47BC',
'#7E57C2',
'#5C6BC0',
'#42A5F5',
'#29B6F6',
'#26C6DA',
'#26A69A',
'#66BB6A',
'#9CCC65',
'#D4E157',
'#FFEE58',
'#FFCA28',
'#FFA726',
'#FF7043',
'#8D6E63',
'#BDBDBD',
'#78909C',
'#C62828',
'#37474F',
'#242424',
'#F57F17'
]

const getColorsSoft = [
'#FFFFFF', 
'#ef9a9a',
'#F48FB1',
'#CE93D8',
'#B39DDB',
'#9FA8DA',
'#90CAF9',
'#81D4FA',
'#80DEEA',
'#80CBC4',
'#A5D6A7',
'#C5E1A5',
'#E6EE9C',
'#FFF59D',
'#FFE082',
'#FFCC80',
'#FFAB91',
'#BCAAA4',
'#EEEEEE',
'#B0BEC5'
]

export {
  getColors,
  getColorsSoft
}