<template>
  <div class="col-12">
    <div class="box-container b-white moves-container no-select">
      <ul v-if="movimientos.length" class="list-container container">
        <li v-for="(movimiento, index) in movimientos" :key="index" class="row v-items-top" v-on:click.prevent="goTo('/moveEdit/', movimiento.id, movimiento.id_referencia)" v-if="index < actual">
          <div class="col-auto" v-if="movimiento.ref_categoria">
            <span class="icon-list" v-bind:style="{'background-color': movimiento.ref_categoria.color}">
              <i v-bind:class="movimiento.ref_categoria.icon"></i>
            </span>
            <span class="move-type" v-if="movimiento.ref_tipo_movimiento" v-bind:style="{'background-color': movimiento.ref_tipo_movimiento.color}">
              <i v-bind:class="movimiento.ref_tipo_movimiento.icon"></i>
            </span>
          </div>
          <div class="col info-row-container">
            <p class="t-complementary t-14-px">
              <span v-bind:title="movimiento.descripcion">{{movimiento.descripcion | cutText}} <!--span class="account-indicator" v-if="movimiento.ref_cuenta" v-bind:style="{'background-color': movimiento.ref_cuenta.color}"></span--></span>
            </p>
            <span class="tag-list" v-if="movimiento.ref_cuenta" v-bind:style="{'background-color': movimiento.ref_cuenta.color}">
              {{movimiento.ref_cuenta.nombre}}
            </span>
            <span v-for="(etiqueta, index) in movimiento.etiquetas" :key="index" class="tag-list" v-if="etiqueta" v-bind:style="{'background-color': etiqueta.color}">
              {{etiqueta.nombre}}
            </span>
          </div>
          <div class="col-auto t-right">
            <p v-if="movimiento.ref_tipo_movimiento" v-bind:class="['t-14-px', {'t-primary': (movimiento.ref_tipo_movimiento.nombre == 'Ingreso'), 't-fail': (movimiento.ref_tipo_movimiento.nombre == 'Egreso'), 't-warn': (movimiento.ref_tipo_movimiento.nombre == 'Transferencia')}]">
              {{movimiento.monto | formatPrice}}
            </p>
            <p class="t-upper t-10-px">
              {{movimiento.fecha_creacion.seconds | beautyFormatDate}}
            </p>
          </div>
        </li>
      </ul>
      <p v-else class="t-center">
        <i class="fas fa-sad-cry t-48-px"></i>
        <br>
        <br>
        No se encontraron movimientos registrados
      </p>
    </div>
    <div class="container" v-if="$route.path != '/dashboard'">
      <div class="row">
        <div class="col" v-if="actual > pasos">
          <p class="t-14-px t-center">
            <a href="#" class="link" v-on:click.prevent="actual = actual - pasos">Mostrar Menos</a>
          </p>
        </div>
        <div class="col" v-if="movimientos.length > actual">
          <p class="t-14-px t-center">
            <a href="#" class="link" v-on:click.prevent="actual = actual + pasos">Mostrar Más</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'GetMoves',
  props: {
    movimientos: {
      type: Array,
      required: false,
      default: function () { return [] }
    },
    limite: {
      type: Number,
      required: false,
      default: 5
    }
  },
  data (){
    return{
      pasos: this.limite,
      actual: this.limite
    }
  },
  methods: {
    goTo (url, the_move, the_reference){
      if(the_reference){
        router.push({path: url + the_reference})
      }else{
        router.push({path: url + the_move})
      }
    }
  }
}
</script>
