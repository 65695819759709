import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import { auth } from '@/firebase'
import { firestorePlugin } from 'vuefire'
import '@/registerServiceWorker'

Vue.config.productionTip = false

Vue.use(firestorePlugin)

require('moment/locale/es')
import moment from 'moment'

let app = ''

auth.onAuthStateChanged(function() {
	if (!app) {
		new Vue({
			router, //exportat variable a todo los componentes
			render: h => h(App)
		}).$mount('#app')
	}
})

//Filtros
Vue.filter('formatNumber', function(clean){
  if(clean == '-'){
    return '-$'
  }else{
    var prefijo = '$'

    var value = clean.toString().replace(/[^0-9.]/g,'')

    if(Number(clean) < 0){
      prefijo = '-$'
    }
    
    var parts = value.split(".")

    if(parts.length > 1){
      	return prefijo + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + parts[1]
    }else{
      return prefijo + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }

})

Vue.filter('formatFloat', function(clean){
  var value = clean.toString().replace(/(?!^-)[^0-9.]/g,'')
  if(isNaN(value)){
    return 0.00
  }else{
    let val = value.toString().replace(',', '').replace('-', '')
    return (val/1).toFixed(2)
  }
})

Vue.filter('cleanFormat', function(clean){
  var value = clean.toString().replace(/(?!^-)[^0-9.]/g,'')
  return value
})

Vue.filter('formatPrice', function(value){
  if(isNaN(value)){
    return '$0.00'
  }else{
    let val = (value/1).toFixed(2)
    if(value >= 0){
      var type_mount = '$'
    }else{
      var type_mount = '-$'
    }
    return type_mount + val.toString().replace('-', '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
})

Vue.filter('simpleFormatDate', function(value) {
  if(value) {
    const curdate = new Date(null)
    const date = curdate.setTime(value*1000)
    const momentdate = curdate.toISOString()
    return moment(momentdate).format('dddd DD [de] MMMM [de] YYYY')
  }
})

Vue.filter('beautyFormatDate', function(value) {
  if(value) {
    const curdate = new Date(null)
    const date = curdate.setTime(value*1000)
    const momentdate = curdate.toISOString()
    return moment(momentdate).format('HH:mm MMM DD, YYYY')
  }
})

Vue.filter('timerFormatDate', function(value) {
  if(value) {
    const curdate = new Date(null)
    const date = curdate.setTime(value*1000)
    const momentdate = curdate.toISOString()
    return moment(momentdate).format('DD MMMM h:mm a')
  }
})

Vue.filter('monthFormatDate', function(value) {
  if(value) {
    const curdate = new Date(null)
    const date = curdate.setTime(value*1000)
    const momentdate = curdate.toISOString()
    return moment(momentdate).format('MMM YYYY')
  }
})

Vue.filter('initialText', function(value) {
  if(value) {
    return value.substring(0, 1).toUpperCase()
  }
})

Vue.filter('cutText', function(value) {
  if(value.length > 100){
    return value.substring(0, 99) + '...'
  }else{
    return value
  }
})

Vue.filter('resumeText', function(value) {
  if(value.length > 101){
    return value.substring(0, 100) + '...'
  }else{
    return value
  }
})
