<template>
  <div class="col-12 box-container b-white moves-container no-select">
    <ul v-if="categorias.length" class="list-container container">
      <li v-for="categoria in categorias" :key="categoria.id" class="row v-items-center">
        <div class="col-auto" v-on:click.prevent="goTo('/CategoryActions/'+categoria.id)">
          <span class="icon-list" v-bind:style="{'background-color': categoria.color}">
            <i v-bind:class="categoria.icon"></i>
          </span>
        </div>
        <div class="col info-row-container" v-on:click.prevent="goTo('/CategoryActions/'+categoria.id)">
          <p class="t-complementary t-14-px">
            <span>{{categoria.nombre | cutText}}</span>
            <br>
            <span class="t-upper t-10-px t-base">{{categoria.descripcion}}</span>
          </p>
        </div>
        <div class="col-auto t-right" v-on:click.prevent="goTo('/CategoryActions/'+categoria.id)">
          <p v-bind:class="['t-14-px', {'t-primary': (categoria.valor_actual > 0), 't-fail': (categoria.valor_actual < 0), 't-base': (categoria.valor_actual == 0)}]">
            {{categoria.valor_actual | formatPrice}}
            <br>
            <span class="t-10-px">Monto Generado</span>
          </p>
        </div>
       </li>
    </ul>
    <p v-else class="t-center">
      <i class="fas fa-sad-cry t-48-px"></i>
      <br>
      <br>
      No se encontraron categorías
    </p>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'GetCategories',
  data() {
    return {
      categorias: [], 
    }
  },
  firestore () {
    return {
      categorias: db.collection('categorias').orderBy('nombre'),
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
  }
}
</script>
