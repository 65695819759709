<template>
  <div class="screen page tasks-page b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="t-complementary">
                Nueva Tarea
              </p>
            </div>
          </div>
          <div class="row box-container b-white moves-container no-select task-timers">
            <div class="col-12">
              <ul class="list-timer-task container">
                <li class="row v-items-top">
                 <div class="col-auto">
                   <span class="icon-list" style="background-color: rgb(0,100,230); cursor: pointer;" v-on:click.prevent="playTask(nombre_tarea, id_proyecto_tarea)">
                     <i class="fas fa-play-circle"></i>
                   </span>
                 </div>
                 <div class="col">
                   <p class="t-complementary t-14-px">
                     <input type="text" placeholder="En que estás trabajando..." v-model="nombre_tarea">
                     <select class="tag-list b-complementary no-select" v-model="id_proyecto_tarea">
                       <option value="">Sin Proyecto</option>
                       <option v-for="proyecto in proyectos" :key="proyecto.id" v-bind:value="proyecto.id">{{proyecto.nombre}}</option>
                     </select>
                   </p>
                 </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="t-complementary">
                Tareas Realizadas
              </p>
            </div>
          </div>
          <div class="row box-container b-white moves-container no-select task-timers">
            <GetTasks v-bind:todas_tareas="tareas" v-on:change_timer="onTimerChange" />
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import GetFooter from '@/components/footer.vue'
import GetTasks from '@/components/tasks.vue'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetTasks
  },
  data() {
    return {
      id_tarea_considencia: '',
      tareas: [],
      tareas_usuario: [],
      proyectos: [],
      nombre_tarea: '',
      id_proyecto_tarea: ''
    }
  },
  firestore () {
    return {
      tareas: db.collection('tareas').orderBy('fecha_inicio', 'desc'),
      tareas_usuario: db.collection('tareas').where('id_usuario', '==', firebase.auth().currentUser.uid).where('play', '==', true).orderBy('fecha_inicio'),
      proyectos: db.collection('proyectos').orderBy('nombre'),
    }
  },
  methods:{
    onTimerChange: function (get_param_value) {
      if(get_param_value){
        this.$emit('change_timer', true)
      }
    },
    validarTarea: function(nombre, id_proyecto){
      this.id_tarea_considencia = ''
      for(const i in this.tareas){
        if(this.tareas[i].nombre == nombre && this.tareas[i].id_proyecto == id_proyecto){
          this.id_tarea_considencia = this.tareas[i].id
          break
        }
      }
    },
    playTask(nombre, id_proyecto){
      var self = this
      if(nombre.trim()){
        var ref_proyecto = ''
        if(id_proyecto != ''){
          ref_proyecto = db.collection('proyectos').doc(id_proyecto)
        }
        if(self.tareas_usuario.length > 0){
          alert('Adoro tu entusiasmo, pero actualmente tienes una tarea corriendo. Asegurate finalizarla antes de iniciar una nueva.')
        }else{
          //Verificar si es una cuenta existente o una cuenta nueva
          this.validarTarea(nombre.trim(), id_proyecto)
          //Si se encuentra un id activar la tarea
          if(this.id_tarea_considencia != ''){
            db.collection('tareas').doc(this.id_tarea_considencia).update({
              play: true,
              fecha_inicio: new Date(),
              id_usuario: firebase.auth().currentUser.uid
            })
            .then(function(docRef) {
                self.$emit('change_timer', true)
                self.nombre_tarea = ''
                self.id_proyecto_tarea = ''
            })
            .catch(function(error) {
                //router.replace('/projects')
            })
          }else{
            //Si no se encuentra el id crear nueva tarea
            db.collection('tareas').add({
              play: true,
              fecha_inicio: new Date(),
              nombre: nombre.trim(),
              id_proyecto: id_proyecto,
              ref_proyecto: ref_proyecto,
              id_usuario: firebase.auth().currentUser.uid
            })
            .then(function(docRef) {
                self.$emit('change_timer', true)
                self.nombre_tarea = ''
                self.id_proyecto_tarea = ''
            })
            .catch(function(error) {
                //router.replace('/projects')
            })
          }
        }
      }else{
        alert('Es necesario añadir lo que estás haciendo')
      }
    }
  }
}
</script>