<template>
  <div>
    <div class="screen page moves b-soft">
      <div class="grid">
        <section class="layout">
          <div class="container">
            <div v-if="filter_status" :class="['filter-container row t-12-px no-select',{ 'opened': open_filters }]">
              <div class="col filter-resume" :title="filter_resume">
                <a href="#" class="link t-12-px" style="text-transform: none;" v-on:click.prevent="open_filters = !open_filters"><i class="fas fa-filter"></i> {{filter_resume}}</a>
              </div>
              <div class="col-auto">
                <a href="#" class="link t-12-px" v-on:click.prevent="restar_fitlers()"><i class="fas fa-times"></i></a>
              </div>
            </div>
            <div class="row v-items-center month-nav-container">
              <div class="col-1">
                <a href="#" class="link t-12-px" v-on:click.prevent="curren_date = set_month(curren_date, 'prev')"><i class="fa fa-arrow-left"></i><span>Anterior</span></a>
              </div>
              <div class="col t-center t-upper">
                {{curren_date.getTime() / 1000 | monthFormatDate}}
                <br>
                <span class="t-10-px">
                  <a href="#" class="link" v-on:click.prevent="open_filters = !open_filters; filter_status = !filter_status"><i class="fas fa-filter"></i> Filtrar</a>
                </span>
              </div>
              <div class="col-1 t-right">
                <a v-if="parseInt(set_month(curren_date, 'next').getFullYear()) <= parseInt(new Date().getFullYear())" href="#" class="link t-12-px" v-on:click.prevent="curren_date = set_month(curren_date, 'next')"><span>Siguiente</span><i class="fa fa-arrow-right"></i></a>
              </div>
            </div>
            <GetFilterMoves 
            v-bind:curren_date="curren_date" 
            v-bind:form_descripcion="form_descripcion"
            v-bind:form_monto="form_monto"
            v-bind:montoFormateado="montoFormateado"
            v-bind:form_tipo_movimiento="form_tipo_movimiento"
            v-bind:form_cuenta="form_cuenta"
            v-bind:form_cliente="form_cliente"
            v-bind:form_etiqueta="form_etiqueta"
            v-bind:form_categoria="form_categoria"
            v-bind:form_tipo_pago="form_tipo_pago"
            v-bind:form_tipo_proceso="form_tipo_proceso"
            :key="curren_date.getMonth()" />
          </div>
        </section>
        <GetFooter />
      </div>
    </div>
    <div :class="['short-menu-container filter-actions layout',{ 'opened': open_filters }]">
      <div class="grid">
        <transition name="menu-animation" enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
          <form v-if="open_filters" class="form short-menu b-white no-select layout">
            <div class="grid">
              <div class="container">
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_descripcion != ''}">
                    <label for="descripcion">Descripción</label>
                    <input id="descripcion" type="text" v-model="form_descripcion" placeholder="Escribe algo...">
                  </div>
                  <div class="col" v-bind:class="{'filled': form_monto > 0}">
                    <label for="monto">Monto</label>
                    <input id="monto" type="tel" v-model="montoFormateado" placeholder="0.00">
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_tipo_movimiento != ''}">
                    <label>Tipo Movimiento</label>
                    <multiselect 
                    v-model="form_tipo_movimiento" 
                    :options="tipos_movimientos" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todos" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_cuenta != ''}">
                    <label>Cuenta(s)</label>
                    <multiselect 
                    v-model="form_cuenta" 
                    :options="cuentas" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todas" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                  <div class="col" v-bind:class="{'filled': form_cliente != ''}">
                    <label>Cliente(s)</label>
                    <multiselect 
                    v-model="form_cliente" 
                    :options="clientes" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todos" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_etiqueta != ''}">
                    <label>Etiqueta(s)</label>
                    <multiselect 
                    v-model="form_etiqueta" 
                    :options="etiquetas" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todas" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_categoria != ''}">
                    <label>Categoría(s)</label>
                    <multiselect 
                    v-model="form_categoria" 
                    :options="categorias" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    placeholder="Todas" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_tipo_pago != ''}">
                    <label>Tipo(s) de Pago</label>
                    <multiselect 
                    v-model="form_tipo_pago" 
                    :options="tipos_pagos" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todos" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-bind:class="{'filled': form_tipo_proceso != ''}">
                    <label>Tipo(s) de Proceso</label>
                    <multiselect 
                    v-model="form_tipo_proceso" 
                    :options="tipos_procesos" 
                    :multiple="true" 
                    :show-labels="false" 
                    :close-on-select="true" 
                    :hide-selected="false" 
                    :preserve-search="true" 
                    :searchable="false" 
                    placeholder="Todos" 
                    label="nombre" 
                    track-by="id" 
                    :allow-empty="true" 
                    :preselect-first="false">
                    <span slot="noResult">No se encontraron resultados.</span>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid">
              <div class="container">
                <div class="row v-item-center">
                  <div class="col-auto">
                    <button class="btn b-secondary" v-on:click.prevent="apply_fitlers()">Aplicar Filtros</button>
                  </div>
                  <div class="col-auto">
                    <button class="btn" v-on:click.prevent="restar_fitlers()">Restaurar</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </transition>
        <div :class="['short-actions no-select',{ 'opened': open_filters }]">
          <div class="hover-screen" v-on:click="open_filters = !open_filters"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetFilterMoves from '@/components/moves_content.vue'
import router from '@/router'
import { db } from '@/firebase'
import Multiselect from 'vue-multiselect'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetFilterMoves,
    Multiselect
  },
  props: {
    item_component_state: {
      type: Boolean,
      default : false
    }
  },
  data() {
    return {
      curren_date: new Date(),
      open_filters: false,
      filter_status: false,
      form_descripcion: '',
      form_monto: '',
      montoFormateado: 0.00,
      form_tipo_movimiento: '',
      form_cuenta: '',
      form_cliente: '',
      form_etiqueta: '',
      form_categoria: '',
      form_tipo_pago: '',
      form_tipo_proceso: '',
    }
  },
  firestore () {
    return {
      tipos_movimientos: db.collection('tipos_movimientos').orderBy('nombre', 'asc'),
      cuentas: db.collection('cuentas').orderBy('orden', 'asc'),
      clientes: db.collection('clientes').orderBy('nombre', 'asc'),
      etiquetas: db.collection('etiquetas').orderBy('nombre', 'asc'),
      categorias: db.collection('categorias').orderBy('nombre', 'asc'),
      tipos_pagos: db.collection('tipos_pago').orderBy('nombre', 'asc'),
      tipos_procesos: db.collection('tipos_estatus').orderBy('nombre', 'asc')
    }
  },
  watch: {
    form_monto () {
      this.montoFormateado = this.$options.filters.formatNumber(this.form_monto)
    },
    montoFormateado () {
      this.form_monto = this.$options.filters.cleanFormat(this.montoFormateado)
      this.montoFormateado = this.$options.filters.formatNumber(this.monto)
    },
    item_component_state () {
      //Cambiar el estado en este componente al cambiar en app.vue
      this.open_filters = this.item_component_state
    },
    open_filters () {
      //Cambiar el estado de app.vue
      this.$emit('item_component_state', this.open_filters)
    }
  },
  computed: {
    filter_resume() {
      var resume_content = []
      var the_resume = ''
      if(this.form_descripcion){
        resume_content.push('Descripción: ' + this.form_descripcion)
      }
      if(this.form_monto){
        resume_content.push('Monto: ' + this.form_monto)
      }
      if(this.form_tipo_movimiento != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_tipo_movimiento.length - 1; i++){
          if(i < this.form_tipo_movimiento.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_tipo_movimiento[i].nombre + separador
        }
        resume_content.push('Tipo(s): ' + contenido_arreglo)
      }
      if(this.form_cuenta != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_cuenta.length - 1; i++){
          if(i < this.form_cuenta.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_cuenta[i].nombre + separador
        }
        resume_content.push('Cuenta(s): ' + contenido_arreglo)
      }
      if(this.form_cliente != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_cliente.length - 1; i++){
          if(i < this.form_cliente.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_cliente[i].nombre + separador
        }
        resume_content.push('Cliente(s): ' + contenido_arreglo)
      }
      if(this.form_etiqueta != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_etiqueta.length - 1; i++){
          if(i < this.form_etiqueta.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_etiqueta[i].nombre + separador
        }
        resume_content.push('Etiqueta(s): ' + contenido_arreglo)
      }
      if(this.form_categoria != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_categoria.length - 1; i++){
          if(i < this.form_categoria.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_categoria[i].nombre + separador
        }
        resume_content.push('Categoría(s): ' + contenido_arreglo)
      }
      if(this.form_tipo_pago != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_tipo_pago.length - 1; i++){
          if(i < this.form_tipo_pago.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_tipo_pago[i].nombre + separador
        }
        resume_content.push('Tipo(s) de Pago: ' + contenido_arreglo)
      }
      if(this.form_tipo_proceso != ''){
        var contenido_arreglo = ''
        for (var i = 0; i <= this.form_tipo_proceso.length - 1; i++){
          if(i < this.form_tipo_proceso.length - 1){
            var separador = ' + '
          }else{
            var separador = ''
          }
          contenido_arreglo += this.form_tipo_proceso[i].nombre + separador
        }
        resume_content.push('Tipo(s) de Proceso: ' + contenido_arreglo)
      }
      var i = 0
      for (i in resume_content){
        if(i < resume_content.length - 1){
          the_resume += resume_content[i] + ' / '
        }else{
          the_resume += resume_content[i]
        }
      }
      return the_resume
    },
  },
  methods: {
    apply_fitlers (){
      this.open_filters = false
    },
    restar_fitlers (){
      this.form_descripcion = ''
      this.form_monto = ''
      this.form_tipo_movimiento = []
      this.form_cuenta = []
      this.form_cliente = []
      this.form_etiqueta = []
      this.form_categoria = []
      this.form_tipo_pago = []
      this.form_tipo_proceso = []
      this.open_filters = false
      this.filter_status = false
    },
    set_month (curren_date, type){
      var prev_month = new Date(curren_date.getFullYear(), curren_date.getMonth()-1, 1)
      var next_month = new Date(curren_date.getFullYear(), curren_date.getMonth()+1, 1)
      if(type == 'prev'){
        return prev_month
      }else{
        return next_month
      }
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>