<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      <formMove v-on:actionChange="onActionChange" />
      <GetFooter />
    </div>
  </div>
</template>

<script>
import formMove from '@/components/formMove.vue'
import GetFooter from '@/components/footer.vue'
export default {
  name: 'app',
  components: {
    formMove,
    GetFooter
  },
  data() {
    return {
      action_state: true
    }
  },
  methods: {
    onActionChange(getactionChange){
      this.action_state = getactionChange
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>