<template>
  <div class="content-form-moves">
    {{validateData}}
    <form v-on:submit.prevent="actionForm()" class="form layout">
      <div class="container">
        <div class="row">
          <div class="col-12" v-bind:class="{'filled': id_tipo_movimiento}">
            <input v-model="id_referencia" type="text" readonly="true" style="display: none;">
            <label>Tipo de Movimiento:</label>
            <multiselect 
            v-model="id_tipo_movimiento" 
            :options="tipos_movimientos" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Tipo de Movimiento" 
            label="nombre" 
            track-by="nombre" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': descripcion != ''}">
            <label for="descripcion">Descripción</label>
            <input id="descripcion" type="text" v-model="descripcion" placeholder="Escribe algo...">
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': monto > 0}">
            <label for="monto">Monto</label>
            <input id="monto" type="tel" ref="monto_field" v-model="montoFormateado" placeholder="0.00" v-on:focus="focusPrice">
          </div>
          <div class="col-12" v-bind:class="{'filled': facturado}" v-if="id_tipo_movimiento && id_tipo_movimiento.nombre == 'Ingreso' && monto > 0 && !descripcion.startsWith('IVA ')" >
            <label>Facturar:</label>
            <multiselect 
            v-model="facturado" 
            :options="get_total_facturado" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="false" 
            :searchable="false" 
            placeholder="Selecciona en caso de realizar factura" 
            label="nombre" 
            track-by="nombre" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-12" v-bind:class="{'filled': id_cuenta}">
            <label>Cuenta:</label>
            <multiselect 
            v-model="id_cuenta" 
            :options="cuentas" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Una Cuenta" 
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-12" v-bind:class="{'filled': id_cuenta_destino_transferencia}" v-if="cuentas.length && id_tipo_movimiento.nombre == 'Transferencia'">
            <label>Transferir a:</label>
            <multiselect 
            v-model="id_cuenta_destino_transferencia" 
            :options="cuentas" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Una Cuenta a Transferir" 
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': id_categoria}">
            <label>Categoría:</label>
            <multiselect 
            v-model="id_categoria" 
            :options="categorias" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="true" 
            placeholder="Selecciona o Busca Una Categoria"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': etiquetas_seleccionadas.length}">
            <label>Etiquetas:</label>
            <multiselect 
            v-model="etiquetas_seleccionadas" 
            :options="etiquetas" 
            :multiple="true" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="true" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Etiquetas"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': id_tipo_pago}">
            <label>Forma de Pago:</label>
            <multiselect 
            v-model="id_tipo_pago" 
            :options="tipos_pagos" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Tipo de Pago"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': id_tipo_proceso}">
            <label>Estatus:</label>
            <multiselect 
            v-model="id_tipo_proceso" 
            :options="tipos_procesos" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Estatus"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': id_cliente}">
            <label>Cliente:</label>
            <multiselect 
            v-model="id_cliente" 
            :options="clientes" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Un Cliente"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': fecha != ''}">
            <label>Fecha Creación:</label>
            <datetime v-model="fecha" type="datetime" use12-hour id="date_input"></datetime>
          </div>
          <div class="col-12">
            <ul v-if="messages.length" class="messages-container">
              <p class="t-14-px">Verifica lo siguiente:</p>
              <li v-for="(message, index) in messages" v-bind:class="['message-item t-12-px', message.type]"><i class="fas fa-times" @click.prevent.stop="delete_message(message)"></i> {{message.content}}</li>
            </ul>
          </div>
          <div class="col-12">
            <button v-if="id && id_tipo_movimiento.nombre !== 'Transferencia'" type="submit" class="btn b-secondary">Guardar Cambios</button>
            <button v-if="!id" type="submit" class="btn b-secondary">Crear Nuevo Elemento</button>
            <button v-if="id" type="submit" class="btn b-warn" v-on:click.prevent="delete_element(id, id_referencia)">Eliminar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from '@/router'
import { Datetime } from 'vue-datetime'
import Multiselect from 'vue-multiselect'
import { db } from '@/firebase'
export default {
  name: 'formMove',
  components: {
    Multiselect,
    datetime: Datetime
  },
  props: {
    id: {
      required: false,
      default: ''
    },
    id_referencia: {
      required: false,
      default: ''
    },
    arranque_monto: {
      required: false,
      default: 0.00
    },
    arranque_id_cuenta: {
      required: false,
      default: ''
    },
    arranque_id_cuenta_destino_transferencia: {
      required: false,
      default: ''
    },
    arranque_id_categoria: {
      required: false,
      default: ''
    },
    arranque_id_cliente: {
      required: false,
      default: ''
    },
    prop_descripcion: {
      required: false,
      default: ''
    },
    prop_monto: {
      required: false,
      default: 0.00
    },
    prop_id_tipo_movimiento: {
      required: false,
      default: false
    },
    prop_id_cuenta: {
      required: false,
      default: false
    },
    prop_id_cuenta_destino_transferencia: {
      required: false,
      default: false
    },
    prop_id_categoria: {
      required: false,
      default: false
    },
    prop_etiquetas_seleccionadas: {
      required: false,
      default: false
    },
    prop_id_cliente: {
      required: false,
      default: false
    },
    prop_id_tipo_pago: {
      required: false,
      default: false
    },
    prop_id_tipo_proceso: {
      required: false,
      default: false
    },
    prop_fecha_creacion: {
      required: false,
      default: 0
    }
  },
  data() {
    return {
      descripcion: '',
      monto: 0.00,
      id_tipo_movimiento: false,
      id_cuenta: false,
      id_cuenta_destino_transferencia: false,
      id_categoria: false,
      etiquetas_seleccionadas: false,
      id_cliente: false,
      id_tipo_pago: false,
      id_tipo_proceso: false,
      fecha_creacion: 0,
      montoFormateado: 0.00,
      tipo: this.$route.params.tipo,
      cuenta: this.$route.params.cuenta,
      fecha: false,
      facturado: false,
      cuentas: [],
      categorias: [],
      clientes: [],
      etiquetas: [],
      tipos_pagos: [],
      tipos_procesos: [],
      tipos_movimientos: [],
      messages: []
    }
  },
  firestore () {
    return {
      cuentas: db.collection('cuentas').orderBy('nombre', 'asc'),
      categorias: db.collection('categorias').orderBy('nombre', 'asc'),
      clientes: db.collection('clientes').orderBy('nombre', 'asc'),
      etiquetas: db.collection('etiquetas').orderBy('nombre', 'asc'),
      tipos_pagos: db.collection('tipos_pago').orderBy('nombre', 'asc'),
      tipos_procesos: db.collection('tipos_estatus').orderBy('nombre', 'asc'),
      tipos_movimientos: db.collection('tipos_movimientos').orderBy('nombre', 'asc')
    }
  },
  watch: {
    descripcion (){
      this.varificar_modificaciones
    },
    monto (){
      if(!this.montoFormateado){
      	this.montoFormateado = this.$options.filters.formatNumber(this.monto)
      }
      this.varificar_modificaciones
    },
    montoFormateado () {
      this.monto = this.$options.filters.cleanFormat(this.montoFormateado)
      this.montoFormateado = this.$options.filters.formatNumber(this.monto)
    },
    id_tipo_movimiento (){
      this.varificar_modificaciones
    },
    id_cuenta (){
      this.varificar_modificaciones
    },
    id_categoria (){
      this.varificar_modificaciones
    },
    etiquetas_seleccionadas (){
      this.varificar_modificaciones
    },
    id_cliente (){
      this.varificar_modificaciones
    },
    id_tipo_pago (){
      this.varificar_modificaciones
    },
    id_tipo_proceso (){
      this.varificar_modificaciones
    },
    facturado (){
      if(!this.id){
        if(this.facturado){
          this.etiquetas_seleccionadas = []
          for (var i = 0; i <= this.etiquetas.length - 1; i++) {
            if(this.etiquetas[i].id == 'Gg9TwJxFKdOxCKOFFIXK' || this.etiquetas[i].id == 'XIitbCE4Arh7yEZKGfpM' || this.etiquetas[i].id == 'aTpiP3JF3YA2Gu6xoQ9B'){
              this.etiquetas_seleccionadas.push(this.etiquetas[i])
            }
          }
          this.id_categoria = {}
          for (var i = 0; i <= this.categorias.length - 1; i++) {
            if(this.categorias[i].id == 'e9rVi6s1VNkxQ9lMdRlZ'){
              this.id_categoria = this.categorias[i]
            }
          }
          this.id_cuenta = {}
          for (var i = 0; i <= this.cuentas.length - 1; i++) {
            if(this.cuentas[i].id == '6IHQqGlEUc4GzZRK8N6M'){
              this.id_cuenta = this.cuentas[i]
            }
          }
          this.id_tipo_pago = {}
          for (var i = 0; i <= this.tipos_pagos.length - 1; i++) {
            if(this.tipos_pagos[i].id == 'HZI4svn89w3i0dD6xQJB'){
              this.id_tipo_pago = this.tipos_pagos[i]
            }
          }
          this.id_tipo_proceso = {}
          for (var i = 0; i <= this.tipos_procesos.length - 1; i++) {
            if(this.tipos_procesos[i].id == 'xDdpIk0oSnzDnyfTQMFt'){
              this.id_tipo_proceso = this.tipos_procesos[i]
            }
          }
        }else{
          this.etiquetas_seleccionadas = []
          this.id_categoria = {}
          this.id_cuenta = {}
          this.id_tipo_pago = {}
          this.id_tipo_proceso = {}
        }
      }
    }
  },
  created(){
    
  },
  computed: {
    validateData (){
      if(this.prop_monto){
        this.descripcion = this.prop_descripcion
        this.monto = this.prop_monto
        this.id_tipo_movimiento = this.prop_id_tipo_movimiento
        this.id_cuenta = this.prop_id_cuenta
        this.id_cuenta_destino_transferencia = this.prop_id_cuenta_destino_transferencia
        this.id_categoria = this.prop_id_categoria
        this.etiquetas_seleccionadas = this.prop_etiquetas_seleccionadas
        this.id_cliente = this.prop_id_cliente
        this.id_tipo_pago = this.prop_id_tipo_pago
        this.id_tipo_proceso = this.prop_id_tipo_proceso
        this.fecha_creacion = this.prop_fecha_creacion
        //this.montoFormateado = this.monto
      }
      //this.montoFormateado = this.$options.filters.formatNumber(this.monto)
      //Verificar tipo de movimiento a insertar
      if(this.tipo && this.monto <= 0){
        for(const row in this.tipos_movimientos){
          //Busca dentro de tipos_movimientos uno que coincida su id_tipo_movimiento con el del item
          if(this.tipo == this.tipos_movimientos[row].nombre){
            this.id_tipo_movimiento = this.tipos_movimientos[row]
            break
          }
        }
      }
      //Verificar cuenta para insertar nuevo movimiento
      if(this.cuenta && this.monto <= 0){
        for(const row in this.cuentas){
          //Busca dentro de tipos_movimientos uno que coincida su id_tipo_movimiento con el del item
          if(this.cuenta == this.cuentas[row].id){
            this.id_cuenta = this.cuentas[row]
            break
          }
        }
      }
      //Verificar fecha
      if(this.fecha_creacion.seconds > 0){
        const value = this.fecha_creacion.seconds
        const curdate = new Date(null)
        const date = curdate.setTime(value*1000)
        const momentdate = curdate.toISOString()
        this.fecha = momentdate
      }else{
        this.fecha = new Date().toISOString()
      }
    },
    get_total_facturado (){
      const monto_movimiento_facturado = parseFloat(this.monto).toFixed(2)
      const iva_movimiento_facturado = parseFloat(this.monto * 0.16).toFixed(2)
      const iva_movimiento_retenido = parseFloat(this.monto * 0.106667).toFixed(6)
      const isr_movimiento_retenido = parseFloat(this.monto * 0.012500).toFixed(6)
      const total_movimiento_facturado_fisica = Number(iva_movimiento_facturado) + Number(monto_movimiento_facturado)
      const total_movimiento_facturado_moral = Number(iva_movimiento_facturado) + Number(monto_movimiento_facturado) - Number(iva_movimiento_retenido) - Number(isr_movimiento_retenido)
      return [
      {
        'tipo': 'fisica', 
        'nombre':'Persona Física: IVA Cobrado - ' + this.$options.filters.formatPrice(iva_movimiento_facturado) + ' Total: ' + this.$options.filters.formatPrice(total_movimiento_facturado_fisica)
      },
      {
        'tipo': 'moral', 
        'nombre':'Persona Moral: IVA Cobrado - ' + this.$options.filters.formatPrice(iva_movimiento_facturado) + ' ISR Retenido - ' + this.$options.filters.formatPrice(isr_movimiento_retenido) + ' IVA Retenido - ' + this.$options.filters.formatPrice(iva_movimiento_retenido) + ' Total: ' + this.$options.filters.formatPrice(total_movimiento_facturado_moral)
      }
      ]
    },
    varificar_modificaciones (){
      //Siempre y cuando no se este editando un movimiento verificar si se modifico un campo
      if(!this.id){
        if(this.descripcion != '' || this.monto > 0 || this.id_tipo_movimiento || this.id_cuenta || this.id_categoria || this.etiquetas_seleccionadas.length || this.id_cliente || this.id_tipo_pago || this.id_tipo_proceso){
          //Si se ha modificado cualquiera de los campos preguntar antes de salir
          this.$emit('actionChange', false)
        }else{
          this.$emit('actionChange', true)
        }
      }else{
        this.$emit('actionChange', false)
      }
    }
  },
  methods: { 
    focusPrice (event){
      if(event.target.value == 0 || event.target.value == '$' || event.target.value == '0' || event.target.value == '$0' || event.target.value == '$0.0' || event.target.value == '$0.00'){
        event.target.select()
      }
    },
    delete_message (value){
     this.messages.splice(this.messages.indexOf(value), 1)
    },
    delete_element (the_move, the_transfer){
      var vv = this
      if(window.confirm('¿Estás Seguro? Se perderá la información para siempre')){
        // Eliminar documento clic
        db.collection('movimientos').doc(the_move).get().then(function(doc) {

            //Eliminar movimiento con el id obtenido
            db.collection('movimientos').doc(doc.id).delete()
            .then(function(transRef){
              console.log('eliminado el movimiento ' + the_move)
              //Modificar cantidades
              vv.modificar_valor('cuentas', doc.data().id_cuenta, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
              if(!the_transfer){
                //Modificar monto de categoria y cliente si no se trata de una transferencia
                vv.modificar_valor('categorias', doc.data().id_categoria, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                vv.modificar_valor('clientes', doc.data().id_cliente, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
              }
              // Guardado
            }).catch(function(error) {
              console.error('Error removing document: ', error)
              alert('Error al ejecutar operación 1')
            })

        }).catch(function(error) {
            console.log("Error - no se encontro el documento a eliminar de la transferencia:", error)
        });
        // Si tiene un id de trasnferencia
        if(the_transfer){
          // Eliminar documento referido
          db.collection('movimientos').doc(the_transfer).get().then(function(doc) {

              //Eliminar movimiento con el id obtenido
              db.collection('movimientos').doc(doc.id).delete()
              .then(function(transRef){
                console.log('eliminada la transferencia ' + the_transfer)
                //Modificar cantidades solo de cuenta ya que las trasnferencias no modifican otras cantidades
                vv.modificar_valor('cuentas', doc.data().id_cuenta, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                // Guardado
              }).catch(function(error) {
                console.error('Error removing document: ', error)
                alert('Error al ejecutar operación 2')
              })

          }).catch(function(error) {
              console.log("Error - no se encontro el documento a eliminar de la transferencia:", error)
          });
        }
        this.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
        router.replace('/dashboard')
      }
    },
    actionForm () {
      const id_movimiento = this.id
      const id_referencia_movimiento = this.id_referencia
      let editar = false
      if(id_movimiento){
        editar = true
      }
      let tipo_movimiento = this.id_tipo_movimiento.nombre
      let id_tipo_movimiento_movimiento = this.id_tipo_movimiento.id
      let monto_movimiento = this.monto
      let descripcion_movimiento = this.descripcion
      let id_cuenta_movimiento = this.id_cuenta.id
      if(this.id_cuenta_destino_transferencia){
        var id_cuenta_destino_transferencia_movimiento = this.id_cuenta_destino_transferencia.id
      }else{
        var id_cuenta_destino_transferencia_movimiento = ''
      }
      let id_categoria_movimiento = this.id_categoria.id
      let id_cliente_movimiento = ''
      if(this.id_cliente){
        id_cliente_movimiento = this.id_cliente.id
      }
      let etiquetas_movimiento = []
      if(this.etiquetas_seleccionadas.length){
        let todas_etiquetas = this.etiquetas_seleccionadas.sort(function(a,b) {return (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0);} ).reverse()
        for (var i = todas_etiquetas.length - 1; i >= 0; i--) {
          etiquetas_movimiento.push(db.collection('etiquetas').doc(todas_etiquetas[i].id))
        }
      }
      let id_tipo_pago_movimiento = this.id_tipo_pago.id
      let id_tipo_proceso_movimiento = this.id_tipo_proceso.id
      let factura_requerida_movimiento = this.facturado
      let fecha_seleccionada = this.fecha
      let fecha_creacion_movimiento = new Date(fecha_seleccionada)

      // Setear errores
      this.messages = []

      // Comprobar tipo
      if(!id_tipo_movimiento_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona un tipo de movimiento.'
        }
        this.messages.push(the_message)
      }

      // Comprobar monto
      if(monto_movimiento <= 0) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Monto Requerido. No puede ser menor o igual a 0'
        }
        this.messages.push(the_message)
      }

      // Comprobar descripcion
      if(!descripcion_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Descripción Requerida.'
        }
        this.messages.push(the_message)
      }

      // Comprobar cuenta
      if(!id_cuenta_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona una cuenta.'
        }
        this.messages.push(the_message)
      }else{
        // Comprobar transferencia
        if(tipo_movimiento == 'Transferencia'){
          if(!id_cuenta_destino_transferencia_movimiento) {
            const the_message = {
              type: 'fatal b-fail',
              content: 'Selecciona una cuenta de transferencia.'
            }
            this.messages.push(the_message)
          }else if(id_cuenta_movimiento == id_cuenta_destino_transferencia_movimiento) {
            const the_message = {
              type: 'fatal b-fail',
              content: 'Selecciona una cuenta de transferencia diferente a la cuenta de origen.'
            }
            this.messages.push(the_message)
          }
        }
      }

      // Comprobar categoria
      if(!id_categoria_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona una categoría.'
        }
        this.messages.push(the_message)
      }

      // Comprobar cliente
      if(!id_cliente_movimiento) {
        id_cliente_movimiento = ''
      }

      // Comprobar pago
      if(!id_tipo_pago_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona una forma de pago.'
        }
        this.messages.push(the_message)
      }

      // Comprobar estatus
      if(!id_tipo_proceso_movimiento) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Selecciona un estatus.'
        }
        this.messages.push(the_message)
      }

      // Comprobar fecha
      if(!fecha_creacion_movimiento || !fecha_seleccionada) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Fecha Requerida.'
        }
        this.messages.push(the_message)
      }

      // Si no existen errores proceder
      if(!this.messages.length){

        // Verificar tipo de movimiento
        if(tipo_movimiento == 'Ingreso' || tipo_movimiento == 'Egreso'){
          //Si es ingreso
          if(tipo_movimiento == 'Ingreso'){
            // Comprobar si es un monto facturado
            if(factura_requerida_movimiento){
              // Crear movimiento IVA
              this.manipular_movimiento(tipo_movimiento, id_movimiento, 'IVA ' + descripcion_movimiento, monto_movimiento * 0.16, id_cuenta_movimiento, false, false, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, [db.collection('etiquetas').doc('qQRPtjkJrcRk8qw33Ogl')], fecha_creacion_movimiento)
              //Si es una factura a persona moral crear movimientos de isr e iva retenidos
              if(factura_requerida_movimiento.tipo == 'moral'){
                this.manipular_movimiento(tipo_movimiento, id_movimiento, 'ISR Retenido ' + descripcion_movimiento, (monto_movimiento * 0.012500) * -1, id_cuenta_movimiento, false, false, 'jygRd5YUfyh8JpJdwG6V', id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, 'QGCpBfjBKd6o8xCBcSAl', [db.collection('etiquetas').doc('0LS2Io6BKDrSJYiI56cD'), db.collection('etiquetas').doc('aTpiP3JF3YA2Gu6xoQ9B')], fecha_creacion_movimiento)
                this.manipular_movimiento(tipo_movimiento, id_movimiento, 'IVA Retenido ' + descripcion_movimiento, (monto_movimiento * 0.106667) * -1, id_cuenta_movimiento, false, false, 'YARxFJQAfiMby94u449a', id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, 'QGCpBfjBKd6o8xCBcSAl', [], fecha_creacion_movimiento)
              }
            }
          }else if(tipo_movimiento == 'Egreso'){
            //Si es egreso convertir en negativo el monto
            monto_movimiento = Number('-' + monto_movimiento)
          }
          //Validar si se esta editando o creando
          if(editar){
            //Verificar si antes se trataba de una transferencia al tener un id de referencia
            if(id_referencia_movimiento){
              var vv = this
              //Obtener movimiento a eliminar
              db.collection('movimientos').doc(id_referencia_movimiento).get().then(function(doc) {

                  //Eliminar movimiento con el id_referenciado para evitar doble transferencia al editar
                  db.collection('movimientos').doc(doc.id).delete()
                  .then(function(transRef){
                    console.log('eliminada la trasnferencia ' + id_referencia_movimiento)
                    //Modificar cantidades permitiendo que se actualicen los valores al pasar de una transferencia a ingreso o egreso
                    vv.modificar_valor('cuentas', doc.data().id_cuenta, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                    vv.modificar_valor('categorias', doc.data().id_categoria, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                    vv.modificar_valor('clientes', doc.data().id_cliente, 0, doc.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                    // Guardado
                  }).catch(function(error) {
                    console.error('Error removing document: ', error)
                    alert('Error al ejecutar operación 3')
                  })

              }).catch(function(error) {
                  console.log("Error - no se encontro el documento a eliminar de la transferencia:", error)
              });
            }
            //Editando movimiento
            this.manipular_movimiento(tipo_movimiento, id_movimiento, descripcion_movimiento, monto_movimiento, id_cuenta_movimiento, false, false, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, etiquetas_movimiento, fecha_creacion_movimiento)
          }else{
            //Creando movimiento
            this.manipular_movimiento(tipo_movimiento, id_movimiento, descripcion_movimiento, monto_movimiento, id_cuenta_movimiento, false, false, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, etiquetas_movimiento, fecha_creacion_movimiento)
          }
        }else if(tipo_movimiento == 'Transferencia'){
          if(editar){
            //NO PERMITIR EDITAR TRANSFERENCIA
            // Edita el movimiento principal
            //this.manipular_movimiento(tipo_movimiento, id_movimiento, descripcion_movimiento, Number('-' + monto_movimiento), id_cuenta_movimiento, false, false, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, etiquetas_movimiento, fecha_creacion_movimiento)
            //Edita el movimiento secundario en caso de que no exista se crea al pasar id_referencia_movimiento vacio puede pasar al pasar de Ingreso Egreo a Trasnferencia o que se eliminara por error
            //this.manipular_movimiento(tipo_movimiento, id_referencia_movimiento, descripcion_movimiento, monto_movimiento, id_cuenta_destino_transferencia_movimiento, false, id_movimiento, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, etiquetas_movimiento, fecha_creacion_movimiento)
          }else{
            // Movimiento que quita el monto a cuanta principal seleccionada y devuelve el id del documento creado creando un nuwvo movimiento que añade a cuenta
            this.manipular_movimiento(tipo_movimiento, id_movimiento, descripcion_movimiento, Number('-' + monto_movimiento), id_cuenta_movimiento, id_cuenta_destino_transferencia_movimiento, false, id_categoria_movimiento, id_cliente_movimiento, id_tipo_pago_movimiento, id_tipo_proceso_movimiento, id_tipo_movimiento_movimiento, etiquetas_movimiento, fecha_creacion_movimiento)
          }
        }
        // Enviar a la cuenta
        this.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
        router.replace('/account/'+id_cuenta_movimiento)
      }
    },
    manipular_movimiento(tipo_value, id_value, descripcion_value, monto_value, id_cuenta_value, id_cuenta_transferencia_value, id_referencia_value, id_categoria_value, id_cliente_value, id_tipo_pago_value, id_tipo_proceso_value, id_tipo_movimiento_value, etiquetas_value, fecha_value){
      
      // Prevenir una consulta erronea de referencia al no seleccionar cliente
      if(id_referencia_value){
        var valor_id_referencia = id_referencia_value
      }else{
        var valor_id_referencia = false
      }
      // Prevenir una consulta erronea de referencia al no seleccionar cliente
      if(id_cliente_value){
        var valor_id_cliente = id_cliente_value
        var valor_referencia_cliente = db.collection('clientes').doc(id_cliente_value)
      }else{
        var valor_id_cliente = false
        var valor_referencia_cliente = false
      }

      //Variable que permite acceder al this raiz
      var vv = this

      //Setear valores corrientes en 0
      var dia_corriente_value = 0
      var mes_corriente_value = 0
      var ano_corriente_value = 0

      //Si hay fecha seleccionada obtener dias corrientes
      if(fecha_value){
        dia_corriente_value = fecha_value.getDate()
        mes_corriente_value = fecha_value.getMonth() + 1
        ano_corriente_value = fecha_value.getFullYear()
      }

      let datos_seleccionados = {
        descripcion: descripcion_value,
        monto: Number(monto_value).toFixed(2),
        id_cuenta: id_cuenta_value,
        ref_cuenta: db.collection('cuentas').doc(id_cuenta_value),
        id_referencia: valor_id_referencia,
        id_categoria: id_categoria_value,
        ref_categoria: db.collection('categorias').doc(id_categoria_value),
        id_cliente: valor_id_cliente,
        ref_cliente: valor_referencia_cliente,
        id_tipo_pago: id_tipo_pago_value,
        ref_tipo_pago: db.collection('tipos_pago').doc(id_tipo_pago_value),
        id_tipo_proceso: id_tipo_proceso_value,
        ref_tipo_proceso: db.collection('tipos_estatus').doc(id_tipo_proceso_value),
        id_tipo_movimiento: id_tipo_movimiento_value,
        ref_tipo_movimiento: db.collection('tipos_movimientos').doc(id_tipo_movimiento_value),
        etiquetas: etiquetas_value,
        dia_corriente: dia_corriente_value,
        mes_corriente: mes_corriente_value,
        ano_corriente: ano_corriente_value,
        fecha_creacion: fecha_value,
        fecha_modificacion: new Date()
      }
      //Verificar si se esta editando o creando
      if(id_value){
        db.collection('movimientos').doc(id_value).update(datos_seleccionados)
        .then(function(){
          console.log('actualizado el id ' + id_value)
          if(tipo_value != 'Transferencia'){
            //Modificar cantidades
            vv.modificar_valor('cuentas', vv.arranque_id_cuenta, datos_seleccionados.id_cuenta, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
            //Modificar monto de categoria y cliente si no se trata de una transferencia
            vv.modificar_valor('categorias', vv.arranque_id_categoria, datos_seleccionados.id_categoria, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
            vv.modificar_valor('clientes', vv.arranque_id_cliente, datos_seleccionados.id_cliente, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
          }else{
            //Modificar cantidades en caso de ser una transferencia
          }
          // Guardado
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación 4')
        })
      }else{
        db.collection('movimientos').add(datos_seleccionados)
        .then(function(docRef){
          console.log('creado el id ' + docRef.id)
          if(tipo_value != 'Transferencia'){
            //Modificar cantidades
            vv.modificar_valor('cuentas', vv.arranque_id_cuenta, datos_seleccionados.id_cuenta, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
            vv.modificar_valor('categorias', vv.arranque_id_categoria, datos_seleccionados.id_categoria, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
            vv.modificar_valor('clientes', vv.arranque_id_cliente, datos_seleccionados.id_cliente, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
          }else{
            //Si se trata de una transferencia (ya sea crear el movimiento por primera vez o crear el segundo movimiento de la transferencia al pasar de un egreso o ingreso) ignorar la cuenta anterior
            vv.modificar_valor('cuentas', '', datos_seleccionados.id_cuenta, vv.arranque_monto, datos_seleccionados.monto) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
          }
          // Devuelve el id del documento creado y crear transferencia en caso de existir la opcion
          if(id_cuenta_transferencia_value){
            db.collection('movimientos').add({
              descripcion: descripcion_value,
              monto: Math.abs(Number(monto_value).toFixed(2)),
              id_cuenta: id_cuenta_transferencia_value,
              ref_cuenta: db.collection('cuentas').doc(id_cuenta_transferencia_value),
              id_referencia: docRef.id,
              id_categoria: id_categoria_value,
              ref_categoria: db.collection('categorias').doc(id_categoria_value),
              id_cliente: valor_id_cliente,
              ref_cliente: valor_referencia_cliente,
              id_tipo_pago: id_tipo_pago_value,
              ref_tipo_pago: db.collection('tipos_pago').doc(id_tipo_pago_value),
              id_tipo_proceso: id_tipo_proceso_value,
              ref_tipo_proceso: db.collection('tipos_estatus').doc(id_tipo_proceso_value),
              id_tipo_movimiento: id_tipo_movimiento_value,
              ref_tipo_movimiento: db.collection('tipos_movimientos').doc(id_tipo_movimiento_value),
              etiquetas: etiquetas_value,
              dia_corriente: dia_corriente_value,
              mes_corriente: mes_corriente_value,
              ano_corriente: ano_corriente_value,
              fecha_creacion: fecha_value
            })
            .then(function(transRef){
              console.log('creado la transferencia id ' + transRef.id)
              //Modificar cantidades solo de cuenta ya que las trasnferencias no modifican otras cantidades
              vv.modificar_valor('cuentas', vv.arranque_id_cuenta, id_cuenta_transferencia_value, vv.arranque_monto, Math.abs(Number(monto_value).toFixed(2))) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
              // Guardado
            }).catch(function(error) {
              console.error('Error removing document: ', error)
              alert('Error al ejecutar operación 5')
            })
          }
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación 6')
        })
      }
    },
    modificar_valor(coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo){

      //Existe la coleccion
      if(coleccion != ''){
        
        //Comprobar que tipo de modificacion se realizara
        if((id_documento_anterior != '' && id_documento_nuevo != '') && (id_documento_anterior == id_documento_nuevo)){

          //Revisar si el documento viejo y nuevo no estan vacios y son identicos, esto quiere decir que no se cambio el documento y se puede utilizar cualquiera de los dos id
          if(monto_anterior != monto_nuevo){

            //Si el monto anterior y el nuevo no coinciden significa que si se modifico la cantidad por lo que se debera de proceder a restar el monto anterior y sumar el nuevo con cualquiera de los id

            db.collection(coleccion).doc(id_documento_nuevo).get().then(function(doc) {
                //Obtener valor actual
                var valor_en_cuenta = doc.data().valor_actual

                //Restar valor anterior si existe un monto anterior
                if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                  valor_en_cuenta = Number(valor_en_cuenta) - Number(monto_anterior)
                }

                //Agregar valor con el monto nuevo
                if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                  db.collection(coleccion).doc(id_documento_nuevo).update({valor_actual: Number(valor_en_cuenta) + Number(monto_nuevo)})
                  .then(function(){
                    console.log('Se resto el monto viejo y se sumo el monto nuevo al documento ' + coleccion)
                  }).catch(function(error) {
                    console.log('Error - Se resto el monto viejo y se sumo el monto nuevo al documento ' + coleccion + ' - ' + error)
                  })
                }

            }).catch(function(error) {
                console.log("Error - no se encontro el documento:", error)
            });

          }else{
            console.log('No se modifico ningun documento ya que los montos eran identicos ' + coleccion)
          }

        }else if((id_documento_anterior != '' && id_documento_nuevo != '') && (id_documento_anterior != id_documento_nuevo)){

          //Revisar si el documento viejo y nuevo no estan vacios y son diferentes, esto quiere decir que se cambio el documento y debera de restarse al documento viejo y sumarse al documento nuevo
          console.log('Se cambio el documento viejo por uno nuevo ' + coleccion)

          //Restar a documento viejo el monto viejo
          db.collection(coleccion).doc(id_documento_anterior).get().then(function(doc) {
              //Obtener valor actual
              var valor_en_cuenta = doc.data().valor_actual

              //Restar valor anterior si existe un monto anterior
              if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                db.collection(coleccion).doc(id_documento_anterior).update({valor_actual: Number(valor_en_cuenta) - Number(monto_anterior)})
                .then(function(){
                  console.log('Se resto el monto viejo a la cuenta vieja ' + coleccion)

                  //Sumar a documento nuevo el monto nuevo
                  db.collection(coleccion).doc(id_documento_nuevo).get().then(function(doc) {
                      //Obtener valor actual
                      var valor_en_cuenta = doc.data().valor_actual

                      //Restar valor anterior si existe un monto anterior
                      if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                        db.collection(coleccion).doc(id_documento_nuevo).update({valor_actual: Number(valor_en_cuenta) + Number(monto_nuevo)})
                        .then(function(){
                          console.log('Se sumo el monto nuevo al documento nuevo ' + coleccion)
                        }).catch(function(error) {
                          console.log('Error - No se sumo el monto nuevo al documento nuevo ' + coleccion + ' - ' + error)
                        })
                      }

                  }).catch(function(error) {
                      console.log("Error - no se encontro el documento:", error)
                  });

                }).catch(function(error) {
                  console.log('Error - No se resto el monto viejo a la cuenta vieja ' + coleccion + ' - ' + error)
                })
              }

          }).catch(function(error) {
              console.log("Error - no se encontro el documento:", error)
          });

        }else if(id_documento_anterior != '' || id_documento_nuevo != ''){

          //Revisar si solo existe uno de los dos documentos (viejo o nuevo) esto quiere decir que en caso de existir el viejo se deselecciono el documento actual y en caso de existir el nuevo solamente esto quiere decir que nunca se habia seleccionado un documento
          if(id_documento_anterior != ''){

            console.log('Se deselecciono el documento nuevo ' + coleccion)
            //Restar a documento viejo el monto viejo
            db.collection(coleccion).doc(id_documento_anterior).get().then(function(doc) {
                //Obtener valor actual
                var valor_en_cuenta = doc.data().valor_actual

                //Restar valor anterior si existe un monto anterior
                if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                  db.collection(coleccion).doc(id_documento_anterior).update({valor_actual: Number(valor_en_cuenta) - Number(monto_anterior)})
                  .then(function(){
                    console.log('Se resto el monto viejo a la cuenta vieja ' + coleccion)
                  }).catch(function(error) {
                    console.log('Error - No se resto el monto viejo a la cuenta vieja ' + coleccion + ' - ' + error)
                  })
                }

            }).catch(function(error) {
                console.log("Error - no se encontro el documento:", error)
            });

          }else if(id_documento_nuevo != ''){

            console.log('Se agrego sin antes existir el documento viejo ' + coleccion)

            var documento_nuevo = db.collection(coleccion).doc(id_documento_nuevo)

            //Sumar a documento nuevo el monto nuevo multiples veces para evitar error al crear multiples veces el documento
            //https://firebase.google.com/docs/firestore/manage-data/transactions
            return db.runTransaction(function(transaction) {
                ////este codigo se ejecuta multiples veces si no hay conflicto
                return transaction.get(documento_nuevo).then(function(doc) {
                    if (!doc.exists) {
                        throw "Error - no se encontro el documento";
                    }
                    //Restar valor anterior si existe un monto anterior
                    if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                      var nuevo_valor_en_cuenta = Number(doc.data().valor_actual) + Number(monto_nuevo)
                      transaction.update(documento_nuevo, { valor_actual: nuevo_valor_en_cuenta })
                    }
                })
            }).then(function() {
                console.log('Se sumo el monto nuevo al documento nuevo ' + coleccion)
            }).catch(function(error) {
                console.log('Error - No se sumo el monto nuevo al documento nuevo ' + coleccion + ' - ' + error)
            })

          }

        }else{

          console.log('No se encontro coincidencias a ejecutar ' + coleccion)

        }

      }
    },
  }
}
</script>
<style>
  @import url('../../node_modules/vue-datetime/dist/vue-datetime.css')
</style>