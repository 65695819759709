<template>
  <div id="wallet-application">
    <detect-network v-on:detected-condition="handleConnectivityChange">
      <!-- Only renders when the device is online -->
      <section slot="online" class="layout is-online">
        <div class="grid">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <transition name="menu-animation" enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
                  <ul class="messages-container" v-if="connection_back == true">
                    <li class="message-item t-center t-12-px fatal b-primary">Conexión Restablecida</li>
                  </ul>
                </transition>
                <transition name="menu-animation" enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
                  <ul class="messages-container" v-if="connection_lose == true">
                    <li class="message-item t-center t-12-px fatal b-fail">Conexión Perdida</li>
                  </ul>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Only renders when the device is offline -->
      <section slot="offline" class="layout is-offline b-fail">
        <div class="grid" v-if="connection_back == false">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <p class="t-center t-12-px t-white">Estás trabajando sin conexión a internet, una vez regrese se actualizará lo que hagas. Si no se presentan cambios recarga la pantalla o vuelve a abrir la app</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </detect-network>
    <header v-if="$route.meta.short_links" class="header no-select">
      <transition name="menu-animation" enter-active-class="animated slideInLeft" leave-active-class="animated slideOutLeft">
        <nav v-if="show_menu" id="menu" class="b-white">
          <img src="@/assets/fottom-full-logo.svg" alt="Fottom Flux" id="logo-menu">
          <ul>
            <li>
              <router-link v-on:click.native="close_menu" to="/dashboard">Inicio</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/balance">Balance Mensual</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/stuffs">Pendientes</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/accounts">Cuentas</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/moves">Movimientos</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/customers">Clientes</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/projects">Proyectos</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/tasks">Tareas</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/categories">Categorías</router-link>
            </li>
            <li>
              <router-link v-on:click.native="close_menu" to="/quotes">Cotizaciones</router-link>
            </li>
            <li>
              <a href="#" v-on:click.prevent="logOut">Cerrar Sesión</a>
            </li>
          </ul>
        </nav>
      </transition>
      <transition name="layer-animation" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="show_menu" class="hover-screen" v-on:click.prevent="close_menu"></div>
      </transition>
      <div v-bind:class="['grid', $route.meta.header_style]">
        <section class="layout">
          <div class="container">
            <div class="row v-items-center">
              <div class="col-auto">
                <button id="action-menu" :class="{ back: $route.path != '/dashboard' && getHitory() == true }" v-on:click="btn_action($route.path)">
                  <span class="b-complementary"></span>
                  <span class="b-complementary"></span>
                  <span class="b-complementary"></span>
                </button>
              </div>
              <div class="col t-center t-complementary t-upper t-12-px">{{name_screen}}</div>
              <div class="col-auto">
                <div v-on:click="logOut" class="log-out-icon">
                  <span class="b-complementary"></span>
                  <span class="b-complementary"></span>
                  <span class="b-complementary"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </header>
    <div id="main">
      <transition name="screen-animation" :enter-active-class="enter_animation" :leave-active-class="leave_animation">
        <router-view v-on:the_id="onIdChange" v-on:name_screen="onNameScreenChange" v-on:item_component_state="onitem_componentChange" v-bind:item_component_state="item_component" v-on:change_timer="onTimerChange"/>
      </transition>
      <section v-if="tareas_activas.length" class="layout the-timer">
        <div class="grid">
          <div class="btn-short-actions b-secondary" v-bind:class="{'pulse':!open_timer}" v-on:click="open_timer = !open_timer"><i v-if="!open_timer" class="fas fa-clock"></i> <i v-else class="fas fa-times"></i></div>
          <transition name="view-timer-animation" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutLeft">
            <ul v-if="open_timer" class="container box-container b-white">
              <transition v-for="tarea in tareas" :key="tarea.id" name="pop-timer-animation" enter-active-class="animated bounceInLeft" leave-active-class="animated bounceOutLeft">
                <GetTimer v-if="tarea.play" v-bind:tareaActiva="tarea" />
              </transition>
            </ul>
          </transition>
        </div>
      </section>
      <div v-if="$route.path != '/' && $route.meta.short_links && $route.meta.short_links.length" :class="['short-menu-container layout',{ 'opened': open_short }]">
        <div class="grid">
          <transition v-if="$route.meta.short_links && $route.meta.short_links.length > 1" name="menu-animation" enter-active-class="animated slideInUp" leave-active-class="animated slideOutDown">
            <nav v-if="open_short" class="short-menu b-white no-select t-center">
              <div class="grid">
                <ul>
                  <li v-for="short_link in shorts_links">
                    <router-link v-on:click.native="close_menu" v-bind:to="short_link.url + the_id">{{short_link.titulo}}</router-link>
                  </li>
                </ul>
              </div>
            </nav>
          </transition>
          <div :class="['short-actions no-select',{ 'opened': open_short }]">
            <div class="hover-screen" v-on:click="open_short = !open_short"></div>
            <div v-if="$route.meta.short_links && $route.meta.short_links.length > 1" class="btn-short-actions b-secondary" v-on:click="open_short = !open_short"><i class="fas fa-plus"></i></div>
            <div v-else class="btn-short-actions b-secondary" v-on:click="goTo(shorts_links[0].url)"><i class="fas fa-plus"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import firebase from 'firebase/app'
import detectNetwork from 'v-offline'
import { db, auth } from '@/firebase'
import GetTimer from '@/components/timer'
import 'firebase/auth'
export default {
  components: {
    detectNetwork,
    GetTimer
  },
  data () {
    return {
      enter_animation: 'animated slideInRight',
      leave_animation: 'animated slideOutLeft',
      name_screen: this.$route.name,
      connection_back: false,
      connection_lose: false,
      total_push: 1,
      show_menu: false,
      open_short: false,
      open_timer: false,
      item_component: false,
      shorts_links: this.$route.meta.short_links,
      the_id: '',
      tareas: [],
      tareas_activas: []
    }
  },
  firestore () {
    return {
      tareas: db.collection('tareas').orderBy('fecha_inicio', 'desc'),
      tareas_activas: db.collection('tareas').where('play', '==', true).orderBy('fecha_inicio', 'desc')
    }
  },
  mounted() {
    // Close on prevent item
    var item = this
    var userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/android/i.test(userAgent)) {
      var limit_push = 2
      var start_push = 0
    }else{
      var limit_push = 2
      var start_push = 1
    }
    history.pushState(null, null, location.href)
    window.onpopstate = function(event) {
      if(item.show_menu || item.open_short || item.item_component || item.open_timer){
        router.go(1)
        item.total_push = start_push
      }else{
        if(item.$route.path != '/dashboard'){
          item.total_push = item.total_push + 1
          if(item.total_push == limit_push){
            if(item.$route.meta.back_link){
              if(item.$route.meta.back_link == '/quotes/:id'){
                router.push('/viewQuote/'+this.$route.params.id)
              }else{
                router.push(item.$route.meta.back_link)
              }
            }else{
              if(history.length > 3){
                router.back()
              }
            }
          }
        }else{
          router.go(1)
        }
      }
      if(item.show_menu){
        item.show_menu = false
      }
      if(item.open_short){
        item.open_short = false
      }
      if(item.item_component){
        item.item_component = false
      }
      if(item.open_timer){
        item.open_timer = false
      }
    }
    window.onbeforeunload = function() {
      if(item.tareas_activas.length){
        return 'Tienes tareas corriendo en este momento. Asegurate de cerrarlas antes de salir.'
      }
    }
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    handleConnectivityChange: function(status){
      this.connection_back = status
      if(!status){
        this.connection_lose = true
      }
      setTimeout(this.HideConnection, 2400)
    },
    HideConnection: function(){
      this.connection_back = false
      this.connection_lose = false
    },
    getHitory: function (){
      if(window.history.length >= 3){
        return true
      }
    },
    onIdChange: function (get_param_value) {
      this.the_id = get_param_value
    },
    onNameScreenChange: function (get_param_value) {
      this.name_screen = get_param_value
    },
    onitem_componentChange: function (get_param_value) {
      this.item_component = get_param_value
    },
    onTimerChange: function (get_param_value) {
      if(get_param_value){
        if(this.tareas_activas && this.tareas_activas.length > 0){
          this.open_timer = true
        }
      }
    },
    fetchData () {
      this.name_screen = this.$route.name
      this.shorts_links = this.$route.meta.short_links
      this.the_id = ''
      this.item_component = false
      this.close_menu()
      history.pushState(null, null, location.href)
    },
    goTo (url){
      router.push({path: url})
    },
    btn_action (screen){
      if(screen != '/dashboard' && this.getHitory() == true){
        this.enter_animation = 'animated slideInLeft'
        this.leave_animation = 'animated slideOutRight'
        this.show_menu = false
        if(this.$route.meta.back_link){
          if(this.$route.meta.back_link == '/quotes/:id'){
            router.push('/viewQuote/'+this.$route.params.id)
          }else{
            router.push(this.$route.meta.back_link)
          }
        }else{
          router.go(-1)
        }
      }else{
        this.show_menu = true
      }
      this.open_short = false
    },
    close_menu (){
      this.show_menu = false
      this.open_short = false
      //this.open_timer = false
      this.item_component = false
      this.total_push = 1
    },
    logOut (){
      if(window.confirm('¿Estás Seguro? Tendrás que inicar sesión nuevamente')){
        this.show_menu = false
        this.open_short = false
        var url_logout = '/logout/' + encodeURIComponent(this.$router.currentRoute.path);
        auth.signOut().then(function() {
        	//Cerro sesion
        	console.log('Cerro sesion');
        	router.push({ path: url_logout });
        });
      }
    }
  },
  updated: function () {
    this.enter_animation = 'animated slideInRight'
    this.leave_animation = 'animated slideOutLeft'
  }
}
</script>