<template>
  <div class="row h-items-center">
    <GetMoves v-bind:movimientos="movimientos" v-bind:limite="limit_pagination" />
  </div>
</template>

<script>
import { db } from '@/firebase'
import GetMoves from '@/components/moves.vue'
export default {
  name: 'GetSliderMoves',
  components: {
    GetMoves
  },
  props: {
    title_search: {
      required: true,
      default: 'id_cuenta'
    },
    id_search: {
      required: true,
      default: ''
    },
  },
  data (){
    return{
      limit_pagination: 25,
      movimientos: [],
    }
  },
  firestore () {
    return {
      movimientos: db.collection('movimientos').where(this.title_search, '==', this.id_search).orderBy('fecha_creacion', 'desc')
    }
  },
}
</script>
