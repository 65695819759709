<template>
  <div class="screen page quote b-soft">
    <div class="grid">
      <ul class="tabs">
        <li v-for="(the_version, index) in versiones" v-bind:class="{'active': (!$route.params.version && index == versiones.length - 1) || $route.params.version == the_version.id}" v-on:click.prevent="goTo('/viewQuote/'+$route.params.id+'/'+the_version.id, the_version)">
          {{the_version.folio}}
        </li>
        <li v-on:click.prevent="goTo('/createVersion/'+$route.params.id, false)">
          Añadir
        </li>
      </ul>
      <GetContentQuote v-if="this.$route.params.version" v-bind:version="version" />
      <GetContentQuote v-else v-for="(contenido_version, index) in version" v-bind:version="contenido_version" />
      <GetFooter />
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
import GetFooter from '@/components/footer.vue'
import GetContentQuote from '@/components/contentQuote.vue'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetContentQuote,
  },
  data(){
    return{
      versiones: [],
      version: []
    }
  },
  firestore () {
    return {
      versiones: db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').orderBy('fecha_modificacion', 'asc'),
      //version: db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').doc(this.$route.params.version)
    }
  },
  created() {

    if(this.$route.params.version){
      //obtener version por id
      this.$bind('version', db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').doc(this.$route.params.version)).then(version => {
        this.version === 'version'
        // version are ready to be used
        // if it contained any reference to other document or collection, the
        // promise will wait for those references to be fetched as well
      })
    }else{
      //obtener version ultima creada
      this.$bind('version', db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').orderBy('fecha_modificacion', 'desc').limit(1)).then(version => {
        this.version === 'version'
        // version are ready to be used
        // if it contained any reference to other document or collection, the
        // promise will wait for those references to be fetched as well
      })
    }
  },
  methods: {
    goTo (url, item){
      router.replace({path: url})
      if(item){
        this.version = item
      }
    }
  }
}
</script>