<template>
  <footer class="layout footer">
    <div class="container">
      <div class="row">
        <div class="col">
          <p class="t-10-px">
            Fottom Beta.1.0. By Soluble. 2021. <br>Todos los derechos reservados. 
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'GetFooter'
}
</script>
