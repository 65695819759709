<template>
  <div class="col-12 box-container b-white moves-container no-select">
    <draggable v-if="cuentas.length" class="list-container container" v-model="cuentas" @end="change_order" v-bind="{handle:'.handle-container'}">
      <li v-for="cuenta in cuentas" :key="cuenta.id" class="row v-items-center" v-on:click.prevent="goTo('/account/' + cuenta.id)">
        <div class="col-auto handle-container">
          <div class="handle">
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="col-auto">
          <span class="icon-list" v-bind:style="{'background-color': cuenta.color}">
            <b class="t-18-px">{{cuenta.nombre | initialText}}</b>
          </span>
        </div>
        <div class="col info-row-container">
          <p class="t-complementary t-14-px">
            <span>{{cuenta.nombre | cutText}}</span>
            <br>
            <span class="t-upper t-10-px t-base">{{cuenta.fecha_creacion.seconds | beautyFormatDate}}</span>
          </p>
        </div>
        <div class="col-auto t-right">
          <p v-bind:class="['t-14-px', {'t-primary': (cuenta.valor_actual > 0), 't-fail': (cuenta.valor_actual < 0), 't-base': (cuenta.valor_actual == 0)}]">
            {{cuenta.valor_actual | formatPrice}}
            <br>
            <span class="t-10-px">{{get_diff_account(cuenta.valor_inicial, cuenta.valor_actual)}}% al valor inicial</span>
          </p>
        </div>
       </li>
    </draggable>
    <p v-else class="t-center">
      <i class="fas fa-sad-cry t-48-px"></i>
      <br>
      <br>
      No se encontraron cuentas
    </p>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  name: 'GetAccounts',
  data() {
    return {
      cuentas: [], 
    }
  },
  firestore () {
    return {
      cuentas: db.collection('cuentas').orderBy('orden'),
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
    change_order() {
      for (var a = 0; a <= this.cuentas.length - 1; a++){
        db.collection('cuentas').doc(this.cuentas[a].id).update({
          orden: a + 1
        })
      }
    },
    get_diff_account (valor_inicial, valor_actual){
      if(valor_inicial > 0){
        var start = Number(valor_inicial)
      }else{
        var start = 1
      }
      if(valor_actual > 0){
        var diff = start - Number(valor_actual)
        var signal = '+'
      }else{
        var diff = start + Number(valor_actual)
        var signal = '-'
      }
      if(valor_inicial == valor_actual){
        var porcent = 0
      }else{
        var porcent = (Math.abs(diff) / start) * 100
      }
      if(porcent >= 1000){
        var the_porcent = (porcent / 1000).toFixed(1) + 'k'
      }else{
        var the_porcent = porcent.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return signal + the_porcent
    }
  }
}
</script>
