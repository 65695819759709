<style scoped>
.sugerencias{
  display: none;
  list-style: none;
  margin: -20px 0 20px;
  padding: 20px;
}
.sugerencias.mostrar{
  display: block;
}
.sugerencias li{
  display: block;
  padding: 5px;
}
.sugerencias li i{
  margin-right: 5px;
  padding: 5px 0;
}
.sugerencias input{
  background-color: rgb(245,245,245);
  border-radius: 4px;
  border: none;
  margin: 0;
  opacity: 1;
  padding: 10px;
}
</style>
<template>
  <ul class="layout box-container b-white sugerencias" v-bind:class="{'mostrar': state}">
    <textarea v-if="tipo == 'descripcion_producto' || tipo == 'descripcion_seccion' || tipo == 'nota'" v-model="busqueda" placeholder="Ingresar sugerencias..."></textarea>
    <input v-else type="text" v-model="busqueda" placeholder="Ingresar sugerencias...">
    <li v-for="sugerencia in listaSugerencias">
      <a href="#" v-on:click.prevent="deleteSugerencia(sugerencia.id);"><i class="fa fa-trash t-10-px"></i></a> 
      <a href="#" v-on:click.prevent="$emit('actionChange', sugerencia.valor);">{{sugerencia.valor | resumeText}}</a>
    </li>
    <li v-if="busqueda.trim() != ''" class="t-secondary">
      <a href="#" v-on:click.prevent="addSugerencia(busqueda, tipo); $emit('actionChange', busqueda.trim());">Añadir "{{busqueda.trim() | resumeText}}"</a>
    </li>
  </ul>
</template>

<script>
import { db } from '@/firebase'
export default {
  name: 'GetSugerencias',
  props: {
    busqueda: {
      required: false,
      default: ''
    },
    tipo: {
      required: false,
      default: 'cotizacion'
    },
    state: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      sugerencias: []
    }
  },
  firestore () {
    return {
      sugerencias: db.collection('sugerencias').where('relacion', '==', this.tipo)
    }
  },
  computed: {
    listaSugerencias() {
      return this.sugerencias.filter(sugerencia => {
        return sugerencia.valor.toLowerCase().includes(this.busqueda.trim().toLowerCase())
      })
    },
  },
  methods: {
    deleteSugerencia (id){
      if(window.confirm('¿Estás Seguro? Esto eliminará esta sugerencia')){
        db.collection('sugerencias').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    addSugerencia (busqueda, tipo){
      db.collection('sugerencias').add({valor: busqueda.trim(),relacion: tipo})
    }
  }
}
</script>
