<template>
  <div class="screen page projects b-soft">
    <div class="grid">
      <section class="layout">
        <div v-if="proyectos.length" class="container">
          <GetProjects v-for="cliente in clientes" :key="cliente.id" v-bind:id_cliente="cliente.id" v-bind:nombre_cliente="cliente.nombre" v-bind:show_title="true" />
        </div>
        <div v-else class="container">
          <div class="row">
            <div class="col">
              <p class="t-complementary">
                Sin Proyectos
              </p>
            </div>
            <div class="col-auto">
              <p>
                <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/projectActions')">
                  Crear Proyecto
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetProjects from '@/components/projects.vue'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetProjects
  },
  data() {
    return {
      clientes: [],
      proyectos: []
    }
  },
  firestore () {
    return {
      clientes: db.collection('clientes').orderBy('nombre'),
      proyectos: db.collection('proyectos')
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    }
  }
}
</script>