<template>
  <div class="screen page fulled-slider account b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row fulled-slide-container">
            <div class="col-12">
              <slick v-if="cuentas.length" ref="slick" :options="slickOptions" @afterChange="change_moves_account" @init="onInitCarousel">
                <div v-for="cuenta in cuentas" class="slide-fulled">
                  <div class="t-center t-white fulled-slide-resume" v-bind:style="{ 'background-color': cuenta.color}">
                    <h1 class="t-48-px t-semibold">{{cuenta.valor_actual | formatPrice}}</h1>
                    <p class="t-upper">
                      Saldo Total en Cuenta
                      <br>
                      <span class="t-10-px">
                        {{cuenta.fecha_creacion.seconds | beautyFormatDate}} - {{date_to_seconds() | beautyFormatDate}}
                      </span>
                    </p>
                    <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/accountActions/'+cuenta.id)">
                      Editar Cuenta
                    </a>
                    <a href="#" class="t-10-px link" v-on:click.prevent="deleteAccount(cuenta.id)">
                      Eliminar Cuenta
                    </a>
                  </div>
                </div>
              </slick>
            </div>
          </div>
        </div>
      </section>
      <section v-if="cuentas.length" class="layout">
        <div class="container" id="container-moves-full-slide">
          <GetSliderMoves v-bind:title_search="'id_cuenta'" v-bind:id_search="id_item_activo" :key="id_item_activo"/>
        </div>
      </section>
      <section v-else class="layout">
        <div class="container">
          <div class="row fulled-slide-container">
            <div class="col-12">
              <div class="slide-fulled">
                <div class="t-center t-white fulled-slide-resume b-primary">
                  <h1 class="t-48-px t-semibold">Sin Cuentas</h1>
                  <p class="t-upper">
                    No se encontraron cuentas
                    <br>
                    <span class="t-10-px">
                      {{date_to_seconds() | beautyFormatDate}}
                    </span>
                  </p>
                  <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/accountActions')">
                    Crear Cuenta
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetSliderMoves from '@/components/slider_moves.vue'
import Slick from 'vue-slick'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    Slick,
    GetFooter,
    GetSliderMoves
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        prevArrow: false,
        nextArrow: false,
        dots: true
      },
      cuentas: [], 
      id_item_activo: this.$route.params.id,
      slide: true
    }
  },
  firestore () {
    return {
      cuentas: db.collection('cuentas').orderBy('orden'),
    }
  },
  watch: {
    id_item_activo: function() {
      this.slide = false
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
    deleteAccount (id_borrar_cuenta){
      var vv = this
      if(window.confirm('¿Estás Seguro? Esto eliminará todos los movimientos')){
        //Borrar cuenta de base de datos
        db.collection('cuentas').doc(id_borrar_cuenta).delete().then(function() {
          console.log('Document successfully deleted!')
          //Buscar movimientos en esta cuenta
          db.collection('movimientos').where('id_cuenta', '==', id_borrar_cuenta).get().then(moves => {

            moves.forEach(move => {

              var the_move = move.id

              var the_transfer = move.data().id_referencia

              //Eliminar movimiento con el id obtenido
              db.collection('movimientos').doc(the_move).delete()
              .then(function(transRef){
                console.log('eliminado el movimiento ' + the_move)
                //Ignorar el de la cuenta ya que se elimino
                if(!the_transfer){
                  //Modificar monto de categoria y cliente si no se trata de una transferencia
                  vv.modificar_valor('categorias', move.data().id_categoria, 0, move.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                  vv.modificar_valor('clientes', move.data().id_cliente, 0, move.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                }
                // Guardado
              }).catch(function(error) {
                console.error('Error removing document: ', error)
                alert('Error al ejecutar operación')
              })

              // Si tiene un id de trasnferencia
              if(the_transfer){
                // Eliminar documento referido
                db.collection('movimientos').doc(the_transfer).get().then(function(transf) {

                    //Eliminar movimiento con el id obtenido
                    db.collection('movimientos').doc(the_transfer).delete()
                    .then(function(doc){
                      console.log('eliminada la transferencia ' + the_transfer)
                      //Modificar cantidades solo de cuenta ya que las trasnferencias no modifican otras cantidades
                      vv.modificar_valor('cuentas', transf.data().id_cuenta, 0, transf.data().monto, 0) //coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo
                      // Guardado
                    }).catch(function(error) {
                      console.error('Error removing document: ', error)
                      alert('Error al ejecutar operación')
                    })

                }).catch(function(error) {
                    console.log("Error - no se encontro el documento a eliminar de la transferencia:", error)
                });
              }

            })

          })
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
        router.replace({path: '/dashboard'})
      }
    },
    date_to_seconds (get_date){
      if(get_date){
        //Format validate 2018-01-25T18:30
        var seconds = new Date(get_date).getTime() / 1000
      }else{
        var seconds = new Date().getTime() / 1000
      }
      return seconds
    },
    change_moves_account(event, slick, currentSlide) {
      this.id_item_activo = this.cuentas[currentSlide].id
      this.$emit('the_id', this.id_item_activo)
      history.replaceState('', '', this.id_item_activo)
      this.$emit('name_screen', this.cuentas[currentSlide].nombre)
      document.title = 'Fottom Flux - ' + this.cuentas[currentSlide].nombre
    },
    onInitCarousel() {
      this.$nextTick(function () {
        if (this.$refs.slick && this.slide == true) {
          for (var i = 0; i < this.cuentas.length; i++) {
            if(this.id_item_activo == this.cuentas[i].id){
              this.$refs.slick.goTo(i)
            }
          }
        }
      })
    },
    modificar_valor(coleccion, id_documento_anterior, id_documento_nuevo, monto_anterior, monto_nuevo){

      //Existe la coleccion
      if(coleccion != ''){
        //Comprobar que tipo de modificacion se realizara
        if((id_documento_anterior != '' && id_documento_nuevo != '') && (id_documento_anterior == id_documento_nuevo)){

          //Revisar si el documento viejo y nuevo no estan vacios y son identicos, esto quiere decir que no se cambio el documento y se puede utilizar cualquiera de los dos id
          if(monto_anterior != monto_nuevo){

            //Si el monto anterior y el nuevo no coinciden significa que si se modifico la cantidad por lo que se debera de proceder a restar el monto anterior y sumar el nuevo con cualquiera de los id
            
            db.collection(coleccion).doc(id_documento_nuevo).get().then(function(doc) {
                //Obtener valor actual
                var valor_en_cuenta = doc.data().valor_actual

                //Restar valor anterior si existe un monto anterior
                if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                  valor_en_cuenta = Number(valor_en_cuenta) - Number(monto_anterior)
                }

                //Agregar valor con el monto nuevo
                if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                  db.collection(coleccion).doc(id_documento_nuevo).update({valor_actual: Number(valor_en_cuenta) + Number(monto_nuevo)})
                  .then(function(){
                    console.log('Se resto el monto viejo y se sumo el monto nuevo al documento ' + coleccion)
                  }).catch(function(error) {
                    console.log('Error - Se resto el monto viejo y se sumo el monto nuevo al documento ' + coleccion + ' - ' + error)
                  })
                }

            }).catch(function(error) {
                console.log("Error - no se encontro el documento:", error)
            });

          }else{
            console.log('No se modifico ningun documento ya que los montos eran identicos ' + coleccion)
          }

        }else if((id_documento_anterior != '' && id_documento_nuevo != '') && (id_documento_anterior != id_documento_nuevo)){

          //Revisar si el documento viejo y nuevo no estan vacios y son diferentes, esto quiere decir que se cambio el documento y debera de restarse al documento viejo y sumarse al documento nuevo
          console.log('Se cambio el documento viejo por uno nuevo ' + coleccion)

          //Restar a documento viejo el monto viejo
          db.collection(coleccion).doc(id_documento_anterior).get().then(function(doc) {
              //Obtener valor actual
              var valor_en_cuenta = doc.data().valor_actual

              //Restar valor anterior si existe un monto anterior
              if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                db.collection(coleccion).doc(id_documento_anterior).update({valor_actual: Number(valor_en_cuenta) - Number(monto_anterior)})
                .then(function(){
                  console.log('Se resto el monto viejo a la cuenta vieja ' + coleccion)

                  //Sumar a documento nuevo el monto nuevo
                  db.collection(coleccion).doc(id_documento_nuevo).get().then(function(doc) {
                      //Obtener valor actual
                      var valor_en_cuenta = doc.data().valor_actual

                      //Restar valor anterior si existe un monto anterior
                      if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                        db.collection(coleccion).doc(id_documento_nuevo).update({valor_actual: Number(valor_en_cuenta) + Number(monto_nuevo)})
                        .then(function(){
                          console.log('Se sumo el monto nuevo al documento nuevo ' + coleccion)
                        }).catch(function(error) {
                          console.log('Error - No se sumo el monto nuevo al documento nuevo ' + coleccion + ' - ' + error)
                        })
                      }

                  }).catch(function(error) {
                      console.log("Error - no se encontro el documento:", error)
                  });

                }).catch(function(error) {
                  console.log('Error - No se resto el monto viejo a la cuenta vieja ' + coleccion + ' - ' + error)
                })
              }

          }).catch(function(error) {
              console.log("Error - no se encontro el documento:", error)
          });

        }else if(id_documento_anterior != '' || id_documento_nuevo != ''){

          //Revisar si solo existe uno de los dos documentos (viejo o nuevo) esto quiere decir que en caso de existir el viejo se deselecciono el documento actual y en caso de existir el nuevo solamente esto quiere decir que nunca se habia seleccionado un documento
          if(id_documento_anterior != ''){

            console.log('Se deselecciono el documento nuevo ' + coleccion)
            //Restar a documento viejo el monto viejo
            db.collection(coleccion).doc(id_documento_anterior).get().then(function(doc) {
                //Obtener valor actual
                var valor_en_cuenta = doc.data().valor_actual

                //Restar valor anterior si existe un monto anterior
                if(Number(monto_anterior) > 0 || Number(monto_anterior) < 0){
                  db.collection(coleccion).doc(id_documento_anterior).update({valor_actual: Number(valor_en_cuenta) - Number(monto_anterior)})
                  .then(function(){
                    console.log('Se resto el monto viejo a la cuenta vieja ' + coleccion)
                  }).catch(function(error) {
                    console.log('Error - No se resto el monto viejo a la cuenta vieja ' + coleccion + ' - ' + error)
                  })
                }

            }).catch(function(error) {
                console.log("Error - no se encontro el documento:", error)
            });

          }else if(id_documento_nuevo != ''){

            console.log('Se agrego sin antes existir el documento viejo ' + coleccion)
            //Sumar a documento nuevo el monto nuevo
            db.collection(coleccion).doc(id_documento_nuevo).get().then(function(doc) {
                //Obtener valor actual
                var valor_en_cuenta = doc.data().valor_actual

                //Restar valor anterior si existe un monto anterior
                if(Number(monto_nuevo) > 0 || Number(monto_nuevo) < 0){
                  db.collection(coleccion).doc(id_documento_nuevo).update({valor_actual: Number(valor_en_cuenta) + Number(monto_nuevo)})
                  .then(function(){
                    console.log('Se sumo el monto nuevo al documento nuevo ' + coleccion)
                  }).catch(function(error) {
                    console.log('Error - No se sumo el monto nuevo al documento nuevo ' + coleccion + ' - ' + error)
                  })
                }

            }).catch(function(error) {
                console.log("Error - no se encontro el documento:", error)
            });

          }

        }else{

          console.log('No se encontro coincidencias a ejecutar ' + coleccion)

        }

      }
    },
  }
}
</script>
<style>
  @import url('../../node_modules/slick-carousel/slick/slick.css')
</style>