<template>
  <div class="icon-picker container">
    <div class="row">
      <div class="col-auto">
        <div @click.prevent.stop="change()" class="icon-selected"><i v-bind:class="value"></i></div>
      </div>
      <div class="col">
        <div class="preview-container">
          <input @focus.prevent.stop="change()" ref="picker" v-model="search" type="text" placeholder="Selecciona un icono">
        </div>
      </div>
      <div class="col-12">
        <transition name="icon-preview-fade">
          <div class="preview-container">
            <div v-if="show_icon_picker" @click="select(undefined)" class="previewer">
              <div v-for="(i, index) in iconsFiltered" :key="index" class="icon-preview">
                <div @click.prevent.stop="select(i)" :class="['icon-wrapper','rounded','shadow-sm', {selected: i.title == value}]" >
                  <i :class="i.title" />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import {icons} from '@/icons.js'
export default {
    name: 'FontawesomePicker',
    props: {
      value: {
        type: String,
        required: false,
        default: ''
      }
    },
    data: function(){
      return {
        show_icon_picker: false,
        icons: icons,
        hoverPanel: false,
        search: '',
        beforeSelect: '',
        selected: '',
      }
    },
    methods: {
      change(){
        this.show_icon_picker = true
        this.search = ''
      },
      select(icon){
        if(icon){
          this.$emit('input', icon.title)
          this.selected = icon.title
          this.search = icon.title
          this.show_icon_picker = false
        }
      }
    },
    computed: {
      iconsFiltered: function(){
        const search = (this.search == this.selected) ? this.beforeSelect : this.search
        return this.icons.filter(i => 
          i.title.indexOf(search) !== -1 || i.searchTerms.some(t => t.indexOf(search) !== -1)
        )
      }
    }
  }
</script>
<style type="text/css">
.icon-selected i{
  display: inline-block;
  line-height: 44px;
  width: 20px;
}

.icon-picker > .row{
  margin-left: -5px;
  margin-right: -5px;
}

.icon-preview-fade-enter-active, .icon-preview-fade-leave-active {
  transition: opacity .25s;
}

.icon-preview-fade-enter, .icon-preview-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.preview-container{
  width: 100%;
  position: relative;
}

.previewer{
  position: relative;
  width: 100%;
  margin-top: 30px;
  min-height: 50px;
  max-height: 240px;
  overflow: auto;
  background: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.icon-preview{
  width: calc(100% / 9);
  padding-top: calc(100% / 9);
  position: relative;
}

.icon-wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items:center;
  cursor: pointer;
  transition: ease-in-out all .25s;
}

.icon-preview:nth-child(2n+1) .icon-wrapper{
  background-color: #f4f4f4;
}

.icon-wrapper.selected{
  background-color: #0064E6 !important;
  color: #fbfbfb;
}

.icon-wrapper i {
  font-size: 85%;
}

</style>