<template>
  <li class="row v-items-center v-items-top-phone item-task-timer">
   <div class="col-auto">
     <span class="icon-list" v-bind:class="{'pulse':tareaActiva.play}" style="background-color: rgb(0,100,230); cursor: pointer;" v-on:click.prevent="playTask(tareaActiva.id, tareaActiva.nombre, tareaActiva.id_proyecto)">
       <i v-if="tareaActiva.play" class="fas fa-pause-circle"></i>
       <i v-else class="fas fa-play-circle"></i>
     </span>
   </div>
   <div class="col">
     <p class="t-complementary t-14-px">
      <input type="text" placeholder="Escribe algo..." v-model="tareaActiva.nombre">
     </p>
     <select class="tag-list no-select" v-model="tareaActiva.id_proyecto" v-bind:style="{'background-color': tareaActiva.ref_proyecto.color}">
       <option value="">Sin Proyecto</option>
       <option v-for="proyecto in proyectos" :key="proyecto.id" v-bind:value="proyecto.id">{{proyecto.nombre}}</option>
     </select>
   </div>
   <div class="col-auto t-right">
     <p class="t-18-px t-base">
      {{timer}}
     </p>
     <p class="t-12-px">
       <span class="hiddenDate t-complementary">
        <datetime v-model="fecha_comienzo" type="datetime" :max-datetime="get_date_start()" use12-hour></datetime>
        {{seconds | timerFormatDate}}
       </span>
       <span class="separator-timer">-</span>
       <span>
         {{time | timerFormatDate}}
       </span>
     </p>
   </div>
  </li>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { db } from '@/firebase'
import { Datetime } from 'vue-datetime'
export default {
  name: 'GetTimer',
  props: {
    tareaActiva: {
      default: []
    }
  },
  components: {
    Datetime
  },
  data(){
    return{
      seconds: 0,
      id_tarea_considencia: '',
      previus_timer: [],
      fecha_comienzo: '',
      fecha_termino: '',
      timer: '',
      time: '',
      proyectos: [],
      tareas: []
    }
  },
  firestore () {
    return {
      proyectos: db.collection('proyectos').orderBy('nombre'),
      tareas: db.collection('tareas').orderBy('fecha_inicio', 'desc')
    }
  },
  mounted(){
    this.fecha_comienzo = this.get_date_start(this.tareaActiva.fecha_inicio.seconds)
    this.fecha_termino = this.get_date_start()
    //this.seconds = this.setSeconds(this.tareaActiva.fecha_inicio.seconds)
    //this.starChron()
  },
  watch:{
    fecha_comienzo (){
      if(this.fecha_comienzo){
        var newSeconds = new Date(this.fecha_comienzo).getTime() / 1000
        this.seconds = newSeconds
        this.starChron()
      }
    }
  },
  methods:{
    validarTarea: function(nombre, id_proyecto){
      this.id_tarea_considencia = ''
      for(const i in this.tareas){
        if(this.tareas[i].nombre == nombre && this.tareas[i].id_proyecto == id_proyecto){
          this.id_tarea_considencia = this.tareas[i].id
          if(this.tareas[i].timer){
            this.previus_timer = []
            for(const e in this.tareas[i].timer){
              this.previus_timer.push({'fecha_fin': this.tareas[i].timer[e].fecha_fin, 'fecha_inicio': this.tareas[i].timer[e].fecha_inicio, 'ref_usuario': db.collection('usuarios').doc(this.tareas[i].timer[e].ref_usuario.id)})
            }
          }
          break
        }
      }
    },
    starChron: function(){
      setInterval(() => {
        this.timer = this.setTimeRun(this.setSeconds(this.seconds))
        this.time = new Date().getTime() / 1000
      }, 1000)
    },
    setSeconds: function(seconds){
      if(seconds > 0){
        const startSeconds = seconds
        const currentDate = new Date()
        const currentSeconds = currentDate.getTime() / 1000
        var totalSeconds = currentSeconds - startSeconds
      }else{
        var totalSeconds = 0
      }
      return totalSeconds
    },
    setTimeRun: function(seconds){
      if(seconds > 0){
        var hours = Math.floor(seconds / (60 * 60))
        var divisor_for_minutes = seconds % (60 * 60)
        var minutes = Math.floor(divisor_for_minutes / 60)
        var divisor_for_seconds = (divisor_for_minutes % 60) - 1
        var secs = Math.ceil(divisor_for_seconds)
        
        var obj = {
            "h": hours,
            "m": minutes,
            "s": secs
        }

        if(obj.h < 10){
          var horas = '0'+obj.h
        }else{
          var horas = obj.h
        }
        if(obj.m < 10){
          var minutos = '0'+obj.m
        }else{
          var minutos = obj.m
        }
        if(obj.s < 10){
          var segundos = '0'+obj.s
        }else if(obj.s == 60){
          var segundos = '00'
        }else{
          var segundos = obj.s
        }

        var timer = horas + ':' + minutos + ':' + segundos

        return timer

      }else{
        return '00:00:00'
      }
    },
    get_date_start: function(seconds){
      if(seconds > 0){
        const value = seconds
        const curdate = new Date(null)
        var date = curdate.setTime(value*1000)
        var format_date = curdate.toISOString()
      }else{
        var date = new Date()
        var format_date = date.toISOString()
      }
      return format_date
    },
    playTask(id_tarea_activa, nombre, id_proyecto){
      var self = this
      var fecha_comienzo = self.fecha_comienzo
      var ref_proyecto = ''
      if(id_proyecto != ''){
        ref_proyecto = db.collection('proyectos').doc(id_proyecto)
      }
      if(nombre.trim()){
        //Verificar si existe un documento con el mismo nombre y proyecto solo ayadir el tiempo y modificar dicho documento
        self.validarTarea(nombre.trim(), id_proyecto)
        self.previus_timer.push({'fecha_fin': new Date(), 'fecha_inicio': new Date(self.fecha_comienzo), 'ref_usuario': db.collection('usuarios').doc(firebase.auth().currentUser.uid)})
        if(self.id_tarea_considencia == ''){
          //Si el id esta vacio se deberá de pausar la tarea en la que se baso y crear una nueva tarea
          db.collection('tareas').doc(id_tarea_activa).update({
            play: false
          })
          .then(function(docRef) {
              //router.push('/project/'+id_proyecto)
          })
          .catch(function(error) {
              //router.replace('/projects')
          })
          //Crear nueva doc
          db.collection('tareas').add({
            play: false,
            fecha_inicio: new Date(),
            nombre: nombre.trim(),
            id_proyecto: id_proyecto,
            ref_proyecto: ref_proyecto,
            timer: self.previus_timer,
            id_usuario: firebase.auth().currentUser.uid
          })
          .then(function(docRef) {

          })
          .catch(function(error) {
              //router.replace('/projects')
          })
        }else{
          if(id_tarea_activa == self.id_tarea_considencia){
            //Si los id coiciden se trata de que no cambio el nombre de la actividad y de la tarea pausada
            db.collection('tareas').doc(id_tarea_activa).update({
              play: false,
              fecha_inicio: new Date(),
              timer: self.previus_timer,
              id_usuario: firebase.auth().currentUser.uid
            })
            .then(function(docRef) {
                //router.push('/project/'+id_proyecto)
            })
            .catch(function(error) {
                //router.replace('/projects')
            })
          }else{
            //Si los id no coicides significa que el nombre que se ingreso y el proyecto coiciden con otra tarea diferente a la que se baso
            //Por lo que se debe pausar el id del doc que se baso
            db.collection('tareas').doc(id_tarea_activa).update({
              play: false
            })
            .then(function(docRef) {
                //router.push('/project/'+id_proyecto)
            })
            .catch(function(error) {
                //router.replace('/projects')
            })
            //La tarea que coincide solo añadimos el nuevo tiempo
            db.collection('tareas').doc(this.id_tarea_considencia).update({
              play: false,
              fecha_inicio: new Date(),
              timer: self.previus_timer,
              id_usuario: firebase.auth().currentUser.uid
            })
            .then(function(docRef) {
                //router.push('/project/'+id_proyecto)
            })
            .catch(function(error) {
                //router.replace('/projects')
            })
          }
        }
      }else{
        alert('Es necesario añadir lo que estás haciendo')
      }
    }
  }
}
</script>
