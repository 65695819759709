<template>
  <div v-if="pendiente.id != ''" class="row h-items-center">
    <div class="col-12">
      <ul class="container list-task">
        <draggable v-model="listas" @end="change_order" v-bind="{handle:'.handle-task'}">
          <li v-for="lista in listas" :key="lista.id" class="row v-items-top">
            <div class="col-auto handle-container handle-task">
              <div class="handle">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="col-auto">
              <span class="check-box no-select" v-bind:class="{'checked': lista.check}" v-on:click.prevent="changeState(lista.check, pendiente.id, lista.id)">
                <i v-if="lista.check" class="fa fa-check"></i>
              </span>
            </div>
            <div class="col">
              <div class="t-complementary t-14-px container">
                <div class="row">
                  <div class="col">
                    <input type="text" placeholder="Escribe algo..." v-model="lista.nombre" v-on:blur="changeName(lista.nombre, pendiente.id, lista.id)"  class="task-nombre">
                  </div>
                  <div class="col-auto">
                    $<input type="tel" placeholder="0.00" v-model="lista.monto" v-on:blur="changePrice(lista.monto, pendiente.id, lista.id)" class="task-monto">
                  </div>
                </div>
              </div>
              <select class="tag-list b-complementary no-select" v-model="lista.id_proyecto" v-on:change="changeProject(lista.id_proyecto, pendiente.id, lista.id)" v-bind:style="{'color': pendiente.color}">
                <option value="">Proyecto</option>
                <option v-for="proyecto in proyectos" :key="proyecto.id" v-bind:value="proyecto.id">{{proyecto.nombre}}</option>
              </select>
            </div>
            <div class="col-auto">
              <a href="#" class="t-complementary" v-on:click.prevent="deleteRow(pendiente.id, lista.id)">
                <i class="fa fa-times-circle"></i>
              </a>
            </div>
          </li>
          <li class="row v-items-top create" v-on:click.prevent="createNote(note.nombre, pendiente.id, note.id)">
            <div class="col-auto">
              <i class="fa fa-plus t-complementary icon-add-task"></i>
            </div>
            <div class="col">
              <div class="t-complementary t-14-px container">
                <div class="row">
                  <div class="col">
                    <p>
                      Añadir Pendiente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </draggable>
        <p v-if="marcados.length" class="t-complementary">
          {{marcados.length}} Pendientes Marcados
        </p>
        <li v-for="lista in marcados" :key="lista.id" class="row v-items-top ready">
          <div class="col-auto handle-container handle-task" style="visibility: hidden;">
            <div class="handle">
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="col-auto">
            <span class="check-box no-select" v-bind:class="{'checked': lista.check}" v-on:click.prevent="changeState(lista.check, pendiente.id, lista.id)">
              <i v-if="lista.check" class="fa fa-check"></i>
            </span>
          </div>
          <div class="col">
            <div class="t-complementary t-14-px container">
              <div class="row">
                <div class="col">
                  <input type="text" placeholder="Escribe algo..." v-model="lista.nombre" v-on:blur="changeName(lista.nombre, pendiente.id, lista.id)"  class="task-nombre">
                </div>
                <div class="col-auto">
                  $<input type="tel" placeholder="0.00" v-model="lista.monto" v-on:blur="changePrice(lista.monto, pendiente.id, lista.id)" class="task-monto">
                </div>
              </div>
            </div>
            <select class="tag-list b-complementary no-select" v-model="lista.id_proyecto" v-on:change="changeProject(lista.id_proyecto, pendiente.id, lista.id)" v-bind:style="{'color': pendiente.color}">
              <option value="">Proyecto</option>
              <option v-for="proyecto in proyectos" :key="proyecto.id" v-bind:value="proyecto.id">{{proyecto.nombre}}</option>
            </select>
          </div>
          <div class="col-auto">
            <a href="#" class="t-complementary" v-on:click.prevent="deleteRow(pendiente.id, lista.id)">
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
import draggable from 'vuedraggable'
export default {
  name: 'GetStuffs',
  components: {
    draggable
  },
  props: {
    pendiente: {
      type: Object
    }
  },
  data() {
    return {
      listas: [],
      marcados: [],
      proyectos: [],
      note: {
        check: false,
        nombre: 'Escribe algo...',
        monto: '',
        id_proyecto: ''
      },
    }
  },
  firestore () {
    return {
      listas: db.collection('pendientes').doc(this.pendiente.id).collection('lista').where('check', '==', false).orderBy('orden'),
      marcados: db.collection('pendientes').doc(this.pendiente.id).collection('lista').where('check', '==', true).orderBy('orden'),
      proyectos: db.collection('proyectos').orderBy('nombre')
    }
  },
  methods: {
    change_order() {
      for (var a = 0; a <= this.listas.length - 1; a++){
        db.collection('pendientes').doc(this.pendiente.id).collection('lista').doc(this.listas[a].id).update({
          orden: a + 1
        })
      }
    },
    deleteRow(id_pendiente, id_lista){
      if(window.confirm('¿Estás Seguro? Esto eliminará este elemento.')){
        db.collection('pendientes').doc(id_pendiente).collection('lista').doc(id_lista).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    changeState(state, id_pendiente, id_lista) {
      if(id_pendiente && id_lista){
        if(state){
          var the_state = false
        }else{
          var the_state = true
        }
        db.collection('pendientes').doc(id_pendiente).collection('lista').doc(id_lista).update({check: the_state})
        .then(function(){
          // Guardado
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }else{
        alert('Error al ejecutar operación')
      }
    },
    changeName(name, id_pendiente, id_lista) {
      if(name.trim() != ''){
        if(id_pendiente && id_lista){
          db.collection('pendientes').doc(id_pendiente).collection('lista').doc(id_lista).update({nombre: name.trim()})
          .then(function(){
            // Guardado
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else{
          alert('Error al ejecutar operación')
        }
      }else{
        alert('Es necesario ingresar un nombre')
      }
    },
    changePrice(price, id_pendiente, id_lista) {
      if(Number(price) > 0){
        var the_price = Number(price)
      }else{
        var the_price = ''
      }
      if(id_pendiente && id_lista){
        db.collection('pendientes').doc(id_pendiente).collection('lista').doc(id_lista).update({monto: the_price})
        .then(function(){
          // Guardado
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }else{
        alert('Error al ejecutar operación')
      }
    },
    changeProject(project, id_pendiente, id_lista) {
      if(project != ''){
        var the_project = project
        var ref_the_project = db.collection('proyectos').doc(the_project)
      }else{
        var the_project = ''
        var ref_the_project = ''
      }
      if(id_pendiente && id_lista){
        db.collection('pendientes').doc(id_pendiente).collection('lista').doc(id_lista).update({
          id_proyecto: the_project,
          ref_proyecto: ref_the_project
        })
        .then(function(){
          // Guardado
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }else{
        alert('Error al ejecutar operación')
      }
    },
    createNote(project, id_pendiente, id_lista) {
      var selfed = this
      var check = selfed.note.check
      var nombre = selfed.note.nombre.trim()
      var monto = selfed.note.monto
      var id_proyecto = selfed.note.id_proyecto
      if(Number(monto) > 0){
        var the_monto = Number(monto)
      }else{
        var the_monto = ''
      }
      if(id_proyecto != ''){
        var the_project = id_proyecto
        var ref_the_project = db.collection('proyectos').doc(the_project)
      }else{
        var the_project = ''
        var ref_the_project = ''
      }
      if(nombre != ''){
        db.collection('pendientes').doc(id_pendiente).collection('lista').add({
          check: check,
          nombre: nombre,
          monto: the_monto,
          id_proyecto: the_project,
          ref_proyecto: ref_the_project,
          orden: 9999999
        })
        .then(function(){
          // Guardado
          selfed.note.check = false
          //selfed.note.nombre = ''
          selfed.note.monto = ''
          selfed.note.id_proyecto = ''
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }
    }
  }
}
</script>
