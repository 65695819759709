<template>
  <div class="col-12 timer-task">
    {{validarTareas()}}
    <p v-if="tareas.length && id_proyecto != ''" class="t-complementary">
      Tareas en Proyecto
    </p>
    <ul v-if="tareas.length" class="list-timer-task container">
      <li v-for="tarea in tareas" :key="tarea.id" class="row v-items-top">
       <div class="col-auto">
         <span class="icon-list" style="background-color: rgb(0,100,230); cursor: pointer;" v-on:click.prevent="playTask(tarea.id)">
           <i class="fas fa-play-circle"></i>
         </span>
       </div>
       <div class="col">
         <p class="t-complementary t-14-px">
           <input type="text" placeholder="Escribe algo..." v-model="tarea.nombre" v-on:blur="changeName(tarea.nombre, tarea.id)">
           <select class="tag-list no-select" v-model="tarea.id_proyecto" v-on:change="changeProject(tarea.id_proyecto, tarea.id)" v-bind:style="{'background-color': tarea.ref_proyecto.color}">
             <option value="">Sin Proyecto</option>
             <option v-for="proyecto in proyectos" :key="proyecto.id" v-bind:value="proyecto.id">{{proyecto.nombre}}</option>
           </select>
           <span style="margin-left: 0.5em;" class="tag-list b-secondary no-select" v-if="tarea.timer && !tarea.play" v-on:click="tarea.play = !tarea.play">{{tarea.timer.length}} Tiempo(s)</span>
           <span style="margin-left: 0.5em;" class="tag-list b-secondary no-select" v-if="tarea.play" v-on:click="tarea.play = !tarea.play">Ocultar Teimpos</span>
         </p>
         <p v-if="tarea.timer && tarea.play" v-for="(timer, key) in tarea.timer" class="t-upper t-10-px">
           {{timer.fecha_inicio.seconds | timerFormatDate}} -> <span v-if="timer.fecha_fin">{{timer.fecha_fin.seconds | timerFormatDate}}</span> <span v-else>En Proceso</span> 
           <a href="#" class="t-complementary" style="margin-left: 0.5em;" v-on:click.prevent="deleteTime(tarea.id, tarea.timer, key)">
             <i class="fa fa-times-circle"></i>
           </a>
           <br>
           <span class="t-bold">{{timer.ref_usuario.nombre}}</span>
         </p>
       </div>
       <div class="col-auto t-right">
         <p class="t-14-px t-base">
           {{get_total_tiempo(tarea.timer)}}
           <br>
           <span class="t-10-px">Tiempo Invertido</span>
         </p>
       </div>
       <div class="col-auto">
         <a href="#" class="t-complementary delete-task" v-on:click.prevent="deleteRow(tarea.id)">
           <i class="fa fa-times-circle"></i>
         </a>
       </div>
      </li>
    </ul>
    <p v-else>
      Sin tareas por el momento
    </p>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'GetTasks',
  props: {
    id_proyecto: {
      type: String,
      default: ''
    },
    todas_tareas:{
      type: Array
    }
  },
  data() {
    return {
      mostrar_tiempos: false,
      tareas: [],
      tareas_usuario: [],
      proyectos: []
    }
  },
  firestore () {
    return {
      tareas: db.collection('tareas').where('id_proyecto', '==', this.id_proyecto).orderBy('fecha_inicio', 'desc'),
      tareas_usuario: db.collection('tareas').where('id_usuario', '==', firebase.auth().currentUser.uid).where('play', '==', true).orderBy('fecha_inicio'),
      proyectos: db.collection('proyectos').orderBy('nombre')
    }
  },
  methods: {
    deleteTime(id, array, fila){
      if(window.confirm('¿Estás Seguro? Esto eliminará este timepo.')){
        var old_timer = array
        if(old_timer){
          var new_timer = []
          for(const i in old_timer){
            if(i != fila){
              new_timer.push({'fecha_fin': old_timer[i].fecha_fin, 'fecha_inicio': old_timer[i].fecha_inicio, 'ref_usuario': db.collection('usuarios').doc(old_timer[i].ref_usuario.id)})
            }
          }
        }
        //Borrar tarea
        db.collection('tareas').doc(id).update({
          timer: new_timer
        })
        .then(function(){
          // Guardado
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }
    },
    deleteRow(id){
      if(window.confirm('¿Estás Seguro? Esto eliminará esta tarea.')){
        //Borrar tarea
        db.collection('tareas').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    validarTareas(){
      if(this.id_proyecto == ''){
        this.tareas = this.todas_tareas
      }
      return ''
    },
    playTask (id_task){
      if(id_task){
        if(this.tareas_usuario.length > 0){
          alert('Adoro tu entusiasmo, pero actualmente tienes una tarea corriendo. Asegurate finalizarla antes de iniciar una nueva.')
        }else{
          this.$emit('change_timer', true)
          db.collection('tareas').doc(id_task).update({
            play: true,
            fecha_inicio: new Date(),
            id_usuario: firebase.auth().currentUser.uid
          })
          .then(function(){
            // Guardado
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }
      }else{
        alert('Error al ejecutar operación')
      }
    },
    changeName(name, id) {
      if(name.trim() != ''){
        if(id){
          db.collection('tareas').doc(id).update({nombre: name.trim()})
          .then(function(){
            // Guardado
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else{
          alert('Error al ejecutar operación')
        }
      }else{
        alert('Es necesario ingresar un nombre')
      }
    },
    changeProject(id_proyecto, id) {
      if(id_proyecto != ''){
        var the_project = id_proyecto
        var ref_the_project = db.collection('proyectos').doc(the_project)
      }else{
        var the_project = ''
        var ref_the_project = ''
      }
      db.collection('tareas').doc(id).update({
        id_proyecto: the_project,
        ref_proyecto: ref_the_project
      })
      .then(function(){
        // Guardado
        if(the_project == ''){
          //alert('La tarea solo aparecerá en la seccion tareas')
        }
      }).catch(function(error) {
        console.error('Error removing document: ', error)
        alert('Error al ejecutar operación')
      })
    },
    date_to_seconds (get_date){
      if(get_date){
        //Format validate 2018-01-25T18:30
        var seconds = new Date(get_date).getTime() / 1000
      }else{
        var seconds = new Date().getTime() / 1000
      }
      return seconds
    },
    get_total (valor_inicial, ref_id){
      var secs = Number(valor_inicial)
      var horas = '00 hrs'
      for (var i = 0; i <= this.tareas.length - 1; i++){
        if(this.tareas[i].id_proyecto == ref_id){
          secs += Number(this.tareas[i].fecha_fin.seconds - this.tareas[i].fecha_inicio.seconds)
        }
      }
      if(secs > 0){
        var hours = Math.floor(secs / (60 * 60))
        var divisor_for_minutes = secs % (60 * 60)
        var minutes = Math.floor(divisor_for_minutes / 60)
        var divisor_for_seconds = divisor_for_minutes % 60
        var seconds = Math.ceil(divisor_for_seconds)
        
        var obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        }
        if(obj.h > 0){
          var horas = obj.h + ' hr '
        }else{
          var horas = ''
        }
        if(obj.m > 0){
          var minutos = obj.m + ' min'
        }else{
          var minutos = ''
        }
        horas = horas + minutos
      }
      return horas
    },
    get_total_tiempo (timer){
      var secs = 0
      if(timer){
        for(const i in timer){
          if(timer[i].fecha_fin && timer[i].fecha_inicio){
            secs += Number(timer[i].fecha_fin.seconds - timer[i].fecha_inicio.seconds)
          }
        }
      }
      var horas = '00 hrs'
      if(secs > 0){
        var hours = Math.floor(secs / (60 * 60))
        var divisor_for_minutes = secs % (60 * 60)
        var minutes = Math.floor(divisor_for_minutes / 60)
        var divisor_for_seconds = divisor_for_minutes % 60
        var seconds = Math.ceil(divisor_for_seconds)
        
        var obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        }
        if(obj.h > 0){
          var horas = obj.h + ' hr '
        }else{
          var horas = ''
        }
        if(obj.m > 0){
          var minutos = obj.m + ' min'
        }else{
          var minutos = ''
        }
        if(obj.s > 0){
          var segs = ' ' + obj.s + ' seg'
        }else{
          var segs = ''
        }
        horas = horas + minutos + segs
      }
      return horas
    }
  }
}
</script>
<style>
  @import url('../../node_modules/slick-carousel/slick/slick.css')
</style>