<template>
  <div class="row h-items-center">
    <GetMoves v-bind:movimientos="update_moves" v-bind:limite="limit_pagination" />
  </div>
</template>

<script>
import { db } from '@/firebase'
import GetMoves from '@/components/moves.vue'
export default {
  name: 'GetFilterMoves',
  components: {
    GetMoves
  },
  props: {
    curren_date: {
      required: false,
      default: function () { return new Date() }
    },
    form_descripcion: {
      required: false,
      default: ''
    },
    form_monto: {
      required: false,
      default: ''
    },
    montoFormateado: {
      required: false,
      default: 0.00
    },
    form_tipo_movimiento: {
      required: false,
      default: ''
    },
    form_cuenta: {
      required: false,
      default: ''
    },
    form_cliente: {
      required: false,
      default: ''
    },
    form_etiqueta: {
      required: false,
      default: ''
    },
    form_categoria: {
      required: false,
      default: ''
    },
    form_tipo_pago: {
      required: false,
      default: ''
    },
    form_tipo_proceso: {
      required: false,
      default: ''
    },
  },
  data (){
    return{
      limit_pagination: 999,
      movimientos: [],
    }
  },
  firestore () {
    return {
      movimientos: db.collection('movimientos').where('mes_corriente', '==', (this.curren_date.getMonth() + 1)).where('ano_corriente', '==', this.curren_date.getFullYear()).orderBy('fecha_creacion', 'desc')
    }
  },
  computed: {
    update_moves (){
      var movimientos_filtrados = []
      for (var i = 0; i <= this.movimientos.length - 1; i++){
        //Validar filtros
        if(this.form_descripcion != ''){
          if(this.like_string(this.movimientos[i].descripcion, this.form_descripcion) != true){
            continue
          }
        }
        if(this.form_monto != ''){
          if(this.movimientos[i].monto != Number(this.form_monto)){
            continue
          }
        }
        if(this.form_tipo_movimiento != ''){
          var counter
          var finded = 0
          for (counter in this.form_tipo_movimiento){
            if(this.movimientos[i].id_tipo_movimiento == this.form_tipo_movimiento[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_cuenta != ''){
          var counter
          var finded = 0
          for (counter in this.form_cuenta){
            if(this.movimientos[i].id_cuenta == this.form_cuenta[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_cliente != ''){
          var counter
          var finded = 0
          for (counter in this.form_cliente){
            if(this.movimientos[i].id_cliente == this.form_cliente[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_etiqueta != ''){
          var counter
          var finded = 0
          for (counter in this.form_etiqueta){
            var count_etiqueta
            for (count_etiqueta in this.movimientos[i].etiquetas){
              if(this.movimientos[i].etiquetas[count_etiqueta].id == this.form_etiqueta[counter].id){
                finded++
              }
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_categoria != ''){
          var counter
          var finded = 0
          for (counter in this.form_categoria){
            if(this.movimientos[i].id_categoria == this.form_categoria[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_tipo_pago != ''){
          var counter
          var finded = 0
          for (counter in this.form_tipo_pago){
            if(this.movimientos[i].id_tipo_pago == this.form_tipo_pago[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }
        if(this.form_tipo_proceso != ''){
          var counter
          var finded = 0
          for (counter in this.form_tipo_proceso){
            if(this.movimientos[i].id_tipo_proceso == this.form_tipo_proceso[counter].id){
              finded++
            }
          }
          if(finded == 0){
            continue
          }
        }

        //Colocar movimiento filtrado
        movimientos_filtrados.push(this.movimientos[i])
        
      }
      return movimientos_filtrados
    }
  },
  methods: {
    like_string (string, compare){
      var the_string = String(string).toLowerCase().trim()
      var the_compare = String(compare).toLowerCase().trim()
      var the_find = new RegExp(the_compare, 'g')
      if(the_string.match(the_find)){
        return true
      }
    }
  }
}
</script>
