<template>
  <div class="screen page fulled-slider customer b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row fulled-slide-container">
            <div class="col-12">
              <slick v-if="clientes.length" ref="slick" :options="slickOptions" @afterChange="change_moves_customer">
                <div v-for="cliente in active_customers" class="slide-fulled">
                  <div class="t-center t-white fulled-slide-resume" v-bind:style="{ 'background-color': cliente.color}">
                    <h1 class="t-48-px t-semibold">{{cliente.valor_actual | formatPrice}}</h1>
                    <p class="t-upper">
                      Saldo Total en Cliente
                      <br>
                      <span class="t-10-px">
                        {{cliente.fecha_creacion.seconds | beautyFormatDate}} - {{date_to_seconds() | beautyFormatDate}}
                      </span>
                    </p>
                    <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/customerActions/'+cliente.id)">
                      Editar Cliente
                    </a>
                    <a href="#" class="t-10-px link" v-on:click.prevent="deleteCustomer(cliente.id)">
                      Eliminar Cliente
                    </a>
                  </div>
                </div>
              </slick>
            </div>
          </div>
        </div>
      </section>
      <section v-if="clientes.length" class="layout">
        <div class="container" id="container-moves-full-slide">
          <GetSliderMoves v-bind:title_search="'id_cliente'" v-bind:id_search="id_item_activo" :key="id_item_activo"/>
        </div>
      </section>
      <section v-else class="layout">
        <div class="container">
          <div class="row fulled-slide-container">
            <div class="col-12">
              <div class="slide-fulled">
                <div class="t-center t-white fulled-slide-resume b-primary">
                  <h1 class="t-48-px t-semibold">Sin Clientes</h1>
                  <p class="t-upper">
                    No se encontraron clientes
                    <br>
                    <span class="t-10-px">
                      {{date_to_seconds() | beautyFormatDate}}
                    </span>
                  </p>
                  <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/customerActions')">
                    Crear Cliente
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetSliderMoves from '@/components/slider_moves.vue'
import Slick from 'vue-slick'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    Slick,
    GetFooter,
    GetSliderMoves
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        prevArrow: false,
        nextArrow: false,
        dots: true
      },
      clientes: [], 
      id_item_activo: this.$route.params.id,
      slide: true
    }
  },
  firestore () {
    return {
      clientes: db.collection('clientes').orderBy('nombre'),
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick && this.slide == true) {
        this.$emit('name_screen', this.clientes[0].nombre)
        document.title = 'Fottom Flux - ' + this.clientes[0].nombre
      }
    })
  },
  watch: {
    id_item_activo: function() {
      this.slide = false
    }
  },
  computed: {
    active_customers: function(){
      this.$emit('the_id', this.id_item_activo)
      history.replaceState('', '', this.id_item_activo)
      if(this.slide == true){
        for (var i = 0; i <= this.clientes.length - 1; i++){
          if(this.id_item_activo == this.clientes[i].id){
            //change order array basaded on function(from,to){this.splice(to,0,this.splice(from,1)[0]);return this;}
            this.clientes.splice(0,0,this.clientes.splice(this.clientes.indexOf(this.clientes[i]),1)[0])
          }
        }
      }
      return this.clientes
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
    deleteCustomer (id_borrar_cliente){
      if(window.confirm('¿Estás Seguro? Esto eliminará todos los movimientos')){
        //Borrar cliente de base de datos
        db.collection('clientes').doc(id_borrar_cliente).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
        router.replace({path: '/dashboard'})
      }
    },
    date_to_seconds (get_date){
      if(get_date){
        //Format validate 2018-01-25T18:30
        var seconds = new Date(get_date).getTime() / 1000
      }else{
        var seconds = new Date().getTime() / 1000
      }
      return seconds
    },
    change_moves_customer(event, slick, currentSlide) {
      this.id_item_activo = this.clientes[currentSlide].id
      this.$emit('name_screen', this.clientes[currentSlide].nombre)
      document.title = 'Fottom Flux - ' + this.clientes[currentSlide].nombre
    }
  }
}
</script>
<style>
  @import url('../../node_modules/slick-carousel/slick/slick.css')
</style>