<template>
  <div class="screen page quotes b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row h-items-center">
            <GetQuotes />
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetQuotes from '@/components/quotes.vue'
export default {
  name: 'app',
  components: {
    GetFooter,
    GetQuotes
  }
}
</script>