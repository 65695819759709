<template>
  <div class="col-12 box-container b-white moves-container no-select">
    <ul v-if="clientes.length" class="list-container container">
      <li v-for="cliente in clientes" :key="cliente.id" class="row v-items-center" v-on:click.prevent="goTo('/customer/'+cliente.id)">
        <div class="col-auto">
          <span class="icon-list" v-bind:style="{'background-color': cliente.color}">
            <b class="t-18-px">{{cliente.nombre | initialText}}</b>
          </span>
        </div>
        <div class="col info-row-container">
          <p class="t-complementary t-14-px">
            <span>{{cliente.nombre | cutText}}</span>
            <br>
            <span class="t-upper t-10-px t-base">{{cliente.fecha_creacion.seconds | beautyFormatDate}}</span>
          </p>
        </div>
        <div class="col-auto t-right">
          <p v-bind:class="['t-14-px', {'t-primary': (cliente.valor_actual > 0), 't-fail': (cliente.valor_actual < 0), 't-base': (cliente.valor_actual == 0)}]">
            {{cliente.valor_actual | formatPrice}}
            <br>
            <span class="t-10-px">Total Ingresado</span>
          </p>
        </div>
       </li>
    </ul>
    <p v-else class="t-center">
      <i class="fas fa-sad-cry t-48-px"></i>
      <br>
      <br>
      No se encontraron clientes
    </p>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'GetCustomers',
  data() {
    return {
      clientes: [], 
    }
  },
  firestore () {
    return {
      clientes: db.collection('clientes').orderBy('nombre'),
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
  }
}
</script>
