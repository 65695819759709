<template>
  <div class="screen page dashboard b-soft">
    <div class="grid">
      <section class="layout">
        <div class="container">
          <div class="row">
            <getDashboard 
            :key="JSON.stringify(cuentas)" />
            <div v-for="cuenta in cuentas" class="col-6 cuenta-dashboard">
              <div class="box-container b-white t-center">
                <div class="account-resume">
                  <div v-on:click.prevent="goTo('/account/'+cuenta.id)">
                    <h1 class="t-24-px t-semibold t-complementary">{{cuenta.valor_actual | formatPrice}}</h1>
                    <p class="t-upper t-primary t-10-px">
                      {{cuenta.nombre}}
                    </p>
                    <span class="color-account" v-bind:style="{ 'background-color': cuenta.color}">
                      {{cuenta.nombre}}
                    </span>
                  </div>
                  <span class="t-10-px edit-account" v-on:click.prevent="goTo('/accountActions/'+cuenta.id)">
                    <i class="fas fa-pen"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col cuenta-dashboard">
              <div class="box-container b-secondary t-center">
                <a href="#" class="account-resume" v-on:click.prevent="goTo('/accountActions')">
                  <h1 class="t-24-px t-semibold t-white"><i class="fas fa-plus"></i></h1>
                  <p class="t-upper t-white t-10-px">
                    Añadir Cuenta
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="layout">
        <div class="container">
          <div class="row v-items-bottom">
            <div class="col">
              <p class="t-complementary">
                Actividad Reciente
              </p>
            </div>
            <div class="col-auto">
              <p>
                <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/moves')">
                  Ver Toda la Actividad
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="container" id="container-moves-home">
          <div class="row h-items-center">
            <GetMoves v-bind:movimientos="movimientos" v-bind:limite="limit_pagination" />
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import GetMoves from '@/components/moves.vue'
import getDashboard from '@/components/get_dashboard.vue'
import Slick from 'vue-slick'
import router from '@/router'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    getDashboard,
    Slick,
    GetFooter,
    GetMoves
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        prevArrow: false,
        nextArrow: false,
        dots: true
      },
      the_show_values: false, //Determina si se muestra los valores despues de cargarse
      cuentas: [], 
      limit_pagination: 5,
      movimientos: []
    }
  },
  firestore () {
    return {
      cuentas: db.collection('cuentas').orderBy('orden'),
      movimientos: db.collection('movimientos').orderBy('fecha_creacion', 'desc').limit(this.limit_pagination)
    }
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
  }
}
</script>
<style>
  @import url('../../node_modules/slick-carousel/slick/slick.css')
</style>