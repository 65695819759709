<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      <formMove 
      v-bind:id="movimiento.id" 
      v-bind:id_referencia="set_id_referencia" 
      v-bind:arranque_monto="Number(movimiento.monto)"
      v-bind:arranque_id_cuenta="movimiento.id_cuenta"
      v-bind:arranque_id_cuenta_destino_transferencia="set_id_cuenta_referencia"
      v-bind:arranque_id_categoria="movimiento.id_categoria"
      v-bind:arranque_id_cliente="movimiento.id_cliente"
      v-bind:prop_descripcion="movimiento.descripcion" 
      v-bind:prop_monto="Math.abs(Number(movimiento.monto))"
      v-bind:prop_id_tipo_movimiento="movimiento.ref_tipo_movimiento" 
      v-bind:prop_id_cuenta="movimiento.ref_cuenta" 
      v-bind:prop_id_cuenta_destino_transferencia="set_cuenta_referencia" 
      v-bind:prop_id_categoria="movimiento.ref_categoria" 
      v-bind:prop_etiquetas_seleccionadas="movimiento.etiquetas" 
      v-bind:prop_id_cliente="movimiento.ref_cliente" 
      v-bind:prop_id_tipo_pago="movimiento.ref_tipo_pago" 
      v-bind:prop_id_tipo_proceso="movimiento.ref_tipo_proceso" 
      v-bind:prop_fecha_creacion="movimiento.fecha_creacion"
      v-on:actionChange="onActionChange" 
      :key="JSON.stringify(movimiento)" />
      <GetFooter />
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase'
import formMove from '@/components/formMove.vue'
import GetFooter from '@/components/footer.vue'
export default {
  name: 'app',
  components: {
    formMove,
    GetFooter
  },
  data() {
    return {
      action_state: false,
      movimiento: [],
      transferencias: []
    }
  },
  firestore () {
    return {
      movimiento: db.collection('movimientos').doc(this.$route.params.id),
      transferencias: db.collection('movimientos').where('id_referencia', '==', this.$route.params.id)
    }
  },
  computed: {
    set_id_cuenta_referencia: function(){
      if(this.transferencias != ''){
        return this.transferencias[0].ref_cuenta.id
      }else{
        return ''
      }
    },
    set_cuenta_referencia: function(){
      if(this.transferencias != ''){
        return this.transferencias[0].ref_cuenta
      }else{
        return false
      }
    },
    set_id_referencia: function(){
      if(this.transferencias != ''){
        return this.transferencias[0].id
      }else{
        return false
      }
    }
  },
  methods: {
    onActionChange(getactionChange){
      this.action_state = getactionChange
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>