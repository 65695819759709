<template>
  <div class="screen page formActions b-soft">
    <div class="grid">
      <formVersion 
      v-if="version && folios[0]" 
      v-bind:action="'editar_version'" 
      v-bind:folio_secuencial="folios[0].valor"
      v-bind:version_secuencial="varsiones.length"  
      v-bind:id="version.id" 
      v-bind:cotizar="version.cotizar" 
      v-bind:nombre_proyecto="version.nombre_proyecto" 
      v-bind:tiempo_entrega="version.tiempo_entrega" 
      v-bind:id_cliente="version.ref_cliente" 
      v-bind:folio="version.folio" 
      v-bind:referencias="version.referencias"
      v-bind:servicios_seleccionados="version.servicios"
      v-bind:productos="version.productos"
      v-bind:notas="version.notas"
      v-bind:fecha_solicitud="version.fecha_solicitud" 
      v-bind:fecha_creacion="version.fecha_creacion" 
      v-on:actionChange="onActionChange" />
      <GetFooter />
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase'
import formVersion from '@/components/formVersion.vue'
import GetFooter from '@/components/footer.vue'
export default {
  name: 'app',
  components: {
    formVersion,
    GetFooter
  },
  data() {
    return {
      action_state: false,
      version: [],
      folios: [],
      varsiones: []
    }
  },
  firestore () {
    return {
      //version: db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').doc(this.$route.params.version),
      folios: db.collection('valores').where('relacion', '==', 'folio').limit(1),
      varsiones: db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones')
    }
  },
  created() {
    //Evitar cambiar informacion automatica de la version
    if(this.$route.params.version){
      //obtener version
      this.$bind('version', db.collection('cotizaciones').doc(this.$route.params.id).collection('versiones').doc(this.$route.params.version)).then(version => {
        this.version === 'version'
        // version are ready to be used
        // if it contained any reference to other document or collection, the
        // promise will wait for those references to be fetched as well

        // you can unbind a property anytime you want
        // this will be done automatically when the component is destroyed
        this.$unbind('version', false)
      })
    }
  },
  methods: {
    onActionChange(getactionChange){
      this.action_state = getactionChange
    }
  },
  beforeRouteLeave (to, from, next) {
    if(this.action_state){
      next()
    }else{
      const answer = window.confirm('¿Estás Seguro? Se perderá la información que se haya modificado')
      if(answer){
        next()
      } else {
        next(false)
      }
    }
  }
}
</script>