<template>
  <div class="content-form-versions">
    {{validateData}}
    <form v-on:submit.prevent="actionForm()" class="form layout" autocomplete="off" id="contenedor-formulario-versiones">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="t-complementary t-21-px">Información General</p>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': cotizar != ''}">
            <label for="cotizar">Cotizar</label>
            <div class="icon-wrap">
              <input id="cotizar" type="text" v-model="cotizar" placeholder="Escribe algo...">
              <i class="fa" v-bind:class="{'fa-search': !status_cotizar, 'fa-times': status_cotizar}" v-on:click.prevent="status_cotizar = !status_cotizar; buscar = ''"></i>
            </div>
            <GetSugerencias 
            v-bind:state="status_cotizar"  
            v-bind:tipo="'cotizar'" 
            v-on:actionChange="function(new_value){cotizar = new_value; status_cotizar = false}" 
            v-bind:busqueda="buscar" />
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': nombre_proyecto != ''}">
            <label for="nombre_proyecto">Nombre del Proyecto</label>
            <div class="icon-wrap">
              <input id="nombre_proyecto" type="text" v-model="nombre_proyecto" placeholder="Escribe algo...">
              <i class="fa" v-bind:class="{'fa-search': !status_proyecto, 'fa-times': status_proyecto}" v-on:click.prevent="status_proyecto = !status_proyecto; buscar = ''"></i>
            </div>
            <GetSugerencias 
            v-bind:state="status_proyecto"  
            v-bind:tipo="'proyecto'" 
            v-on:actionChange="function(new_value){nombre_proyecto = new_value; status_proyecto = false}" 
            v-bind:busqueda="buscar" />
          </div>
          <div class="col phone-c-12" v-bind:class="{'filled': tiempo_entrega > 0}">
            <label for="tiempo_entrega">Días Hábiles de Entrega</label>
            <input id="tiempo_entrega" type="tel" ref="tiempo_entrega_field" v-model="tiempo_entrega" placeholder="Escribe algo...">
          </div>
          <div class="col phone-c-12" v-bind:class="{'filled': id_cliente && id_cliente.id != ''}">
            <label>Cliente:</label>
            <multiselect 
            v-model="id_cliente" 
            :options="clientes" 
            :multiple="false" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="false" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Un Cliente"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div v-if="id" class="col-3 phone-c-12" v-bind:class="{'filled': folio != ''}">
            <label for="folio">Folio</label>
            <input id="folio" type="text" v-model="folio" placeholder="Escribe algo...">
          </div>
          <div class="break-line"></div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': fecha_solicitar != ''}">
            <label>Fecha Solicitud:</label>
            <datetime v-model="fecha_solicitar" type="datetime" use12-hour id="date_input"></datetime>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': fecha_crear != ''}">
            <label>Fecha Creación:</label>
            <datetime v-model="fecha_crear" type="datetime" use12-hour id="date_input"></datetime>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': referencias.length}">
            <label>Referencias:</label>
            <multiselect 
            v-model="referencias" 
            tag-placeholder="Añadir Referencia" 
            placeholder="Ingresa un Referencia" 
            :options="referencias" 
            :multiple="true" 
            :taggable="true" 
            @tag="addTag">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-6 phone-c-12" v-bind:class="{'filled': servicios_seleccionados.length}">
            <label>Servicios (No se plasma en la cotización):</label>
            <multiselect 
            v-model="servicios_seleccionados" 
            :options="servicios" 
            :multiple="true" 
            :show-labels="false" 
            :close-on-select="true" 
            :hide-selected="true" 
            :preserve-search="true" 
            :searchable="false" 
            placeholder="Selecciona Servicios"  
            label="nombre" 
            track-by="id" 
            :allow-empty="true" 
            :preselect-first="false">
            <span slot="noResult">No se encontraron resultados.</span>
            </multiselect>
          </div>
          <div class="col-12">
            <div class="row v-items-center">
              <div class="col-auto">
                <p class="t-complementary t-21-px">Productos</p>
              </div>
              <div class="col">
                <p class="t-right">
                  <a href="#" class="t-14-px link t-secondary" v-on:click.prevent="productos.push({descripcion: '', incluye: [{descripcion: '', titulo: '', titulo_incluye_state: false, descripcion_incluye_state: false}], precio: '', titulo: '', titulo_state: false, descripcion_state: false})">+ Producto</a>
                </p>
              </div>
            </div>
          </div>
          <draggable v-model="productos" v-bind="{handle:'.handle-products'}" class="col-12">
            <div v-for="(producto, index) in productos" class="col-12 box-container padding-block b-white">
              <div class="row">
                <div class="col-auto handle-container handle-products">
                  <div class="handle">
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div class="col">
                  <p>
                    <a href="#" class="t-14-px" v-on:click.prevent="delete_producto(productos[index])">
                      <i class="fa fa-trash"></i> Eliminar este producto
                    </a>
                  </p>
                  <div class="row">
                    <div class="col phone-c-12" v-bind:class="{'filled': productos[index].titulo != ''}">
                      <label>Título</label>
                      <div class="icon-wrap">
                        <input v-model="productos[index].titulo" type="text" placeholder="Escribe algo...">
                        <i class="fa" v-bind:class="{'fa-search': !productos[index].titulo_state, 'fa-times': productos[index].titulo_state}" v-on:click.prevent="productos[index].titulo_state = !productos[index].titulo_state; buscar = ''"></i>
                      </div>
                      <GetSugerencias 
                      v-bind:state="productos[index].titulo_state"  
                      v-bind:tipo="'titulo_producto'" 
                      v-on:actionChange="function(new_value){productos[index].titulo = new_value; productos[index].titulo_state = false}" 
                      v-bind:busqueda="buscar" />
                    </div>
                    <div class="col-2 phone-c-12" v-bind:class="{'filled': productos[index].precio > 0}">
                      <label>Precio</label>
                      <input v-model="productos[index].precioformateado" type="text" placeholder="Escribe algo...">
                    </div>
                    <div class="col-12" v-bind:class="{'filled': productos[index].descripcion != ''}">
                      <label>Descripción</label>
                      <div class="icon-wrap">
                        <textarea @input="mixin_autoResize_resize" rows="3" v-model="productos[index].descripcion" placeholder="Escribe algo..."></textarea>
                        <i class="fa" v-bind:class="{'fa-search': !productos[index].descripcion_state, 'fa-times': productos[index].descripcion_state}" v-on:click.prevent="productos[index].descripcion_state = !productos[index].descripcion_state; buscar = ''"></i>
                      </div>
                      <GetSugerencias 
                      v-bind:state="productos[index].descripcion_state"  
                      v-bind:tipo="'descripcion_producto'" 
                      v-on:actionChange="function(new_value){productos[index].descripcion = new_value; productos[index].descripcion_state = false}" 
                      v-bind:busqueda="buscar" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row v-items-center">
                      <div class="col-auto">
                        <p class="t-complementary t-16-px">Incluye</p>
                      </div>
                      <div class="col">
                        <p class="t-right">
                          <a href="#" class="t-14-px link t-secondary" v-on:click.prevent="productos[index].incluye.push({descripcion: '', titulo: '', titulo_incluye_state: false, descripcion_incluye_state: false})">+ Sección/Bloque</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <draggable v-model="productos[index].incluye" v-bind="{handle:'.handle-include'}" class="col-12">
                    <div class="row v-items-center" v-for="(incluir, key) in productos[index].incluye" >
                      <div class="col-auto handle-container handle-include">
                        <div class="handle">
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col-12">
                            <p>
                              <a href="#" class="t-14-px" v-on:click.prevent="delete_incluye(index, productos[index].incluye[key])">
                                <i class="fa fa-trash"></i> Eliminar Sección/Bloque
                              </a>
                            </p>
                          </div>
                          <div class="col phone-c-12" v-bind:class="{'filled': productos[index].incluye[key].titulo != ''}">
                            <label>Título</label>
                            <div class="icon-wrap">
                              <input v-model="productos[index].incluye[key].titulo" type="text" placeholder="Escribe algo...">
                              <i class="fa" v-bind:class="{'fa-search': !productos[index].incluye[key].titulo_incluye_state, 'fa-times': productos[index].incluye[key].titulo_incluye_state}" v-on:click.prevent="productos[index].incluye[key].titulo_incluye_state = !productos[index].incluye[key].titulo_incluye_state; buscar = ''"></i>
                            </div>
                            <GetSugerencias 
                            v-bind:state="productos[index].incluye[key].titulo_incluye_state"  
                            v-bind:tipo="'titulo_seccion'" 
                            v-on:actionChange="function(new_value){productos[index].incluye[key].titulo = new_value; productos[index].incluye[key].titulo_incluye_state = false}" 
                            v-bind:busqueda="buscar" />
                          </div>
                          <div class="col-9 phone-c-12" v-bind:class="{'filled': productos[index].incluye[key].descripcion != ''}">
                            <label>Descripción</label>
                            <div class="icon-wrap">
                              <textarea @input="mixin_autoResize_resize" rows="1" v-model="productos[index].incluye[key].descripcion" placeholder="Escribe algo..."></textarea>
                              <i class="fa" v-bind:class="{'fa-search': !productos[index].incluye[key].descripcion_incluye_state, 'fa-times': productos[index].incluye[key].descripcion_incluye_state}" v-on:click.prevent="productos[index].incluye[key].descripcion_incluye_state = !productos[index].incluye[key].descripcion_incluye_state; buscar = ''"></i>
                            </div>
                            <GetSugerencias 
                            v-bind:state="productos[index].incluye[key].descripcion_incluye_state"  
                            v-bind:tipo="'descripcion_seccion'" 
                            v-on:actionChange="function(new_value){productos[index].incluye[key].descripcion = new_value; productos[index].incluye[key].descripcion_incluye_state = false}" 
                            v-bind:busqueda="buscar" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
              <div class="row v-items-center">
                <div class="col">
                  <p class="t-left">
                    <a href="#" class="t-14-px link t-secondary" v-on:click.prevent="productos.push({descripcion: '', incluye: [{descripcion: '', titulo: '', titulo_incluye_state: false, descripcion_incluye_state: false}], precio: '', titulo: '', titulo_state: false, descripcion_state: false})">Añadir Otro Producto</a>
                  </p>
                </div>
                <div class="col">
                  <p class="t-right">
                    <a href="#" class="t-14-px link t-secondary" v-on:click.prevent="productos[index].incluye.push({descripcion: '', titulo: '', titulo_incluye_state: false, descripcion_incluye_state: false})">+ Sección/Bloque</a>
                  </p>
                </div>
              </div>
            </div>
          </draggable>
          <div class="col-12">
            <div class="row v-items-center">
              <div class="col-auto">
                <p class="t-complementary t-21-px">Notas</p>
              </div>
              <div class="col">
                <p class="t-right">
                  <a href="#" class="t-14-px link t-secondary" v-on:click.prevent="notas.push({contenido: '', state: false})">+ Nota</a>
                </p>
              </div>
            </div>
          </div>
          <draggable v-model="notas" v-bind="{handle:'.handle-notes'}" class="col-12">
            <div v-for="(nota, index) in notas" class="box-container padding-block b-white" v-bind:class="{'filled': notas[index].contenido != ''}">
              <div class="row v-items-center">
                <div class="col-auto handle-container handle-notes">
                  <div class="handle">
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <div class="col">
                  <p>
                    <a href="#" class="t-14-px" v-on:click.prevent="delete_nota(notas[index])">
                      <i class="fa fa-trash"></i> Eliminar esta nota
                    </a>
                  </p>
                  <div class="icon-wrap">
                    <textarea @input="mixin_autoResize_resize" v-model="notas[index].contenido" rows="3" placeholder="Escribe algo..."></textarea>
                    <i class="fa" v-bind:class="{'fa-search': !notas[index].state, 'fa-times': notas[index].state}" v-on:click.prevent="notas[index].state = !notas[index].state; buscar = ''"></i>
                  </div>
                  <GetSugerencias 
                  v-bind:state="notas[index].state"  
                  v-bind:tipo="'nota'" 
                  v-on:actionChange="function(new_value){notas[index].contenido = new_value; notas[index].state = false}" 
                  v-bind:busqueda="buscar" />
                </div>
              </div>
            </div>
          </draggable>
          <div class="col-12">
            <ul v-if="messages.length" class="messages-container">
              <p class="t-14-px">Verifica lo siguiente:</p>
              <li v-for="(message, index) in messages" v-bind:class="['message-item t-12-px', message.type]"><i class="fas fa-times" @click.prevent.stop="delete_message(message)"></i> {{message.content}}</li>
            </ul>
          </div>
          <div class="col-12">
            <p v-if="id_cotizacion">
              <button type="submit" class="btn b-primary" @click="action = 'crear_version'">Guardar Como Nueva Versión</button>
              <button v-if="id_version" type="submit" class="btn b-secondary" @click="action = 'editar_version'">Guardar Cambios</button>
              <button v-if="id_version" type="submit" class="btn b-white" @click="action = 'crear_cotizacion'">Crear Nueva Cotización</button>
            </p>
            <p v-else>
              <button type="submit" class="btn b-secondary" @click="action = 'crear_cotizacion'">Crear Nueva Cotización</button>
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import router from '@/router'
import { Datetime } from 'vue-datetime'
import Multiselect from 'vue-multiselect'
import draggable from 'vuedraggable'
import GetSugerencias from '@/components/sugerencias.vue'
import { db } from '@/firebase'
export default {
  name: 'formVersion',
  components: {
    Multiselect,
    draggable,
    GetSugerencias,
    datetime: Datetime
  },
  props: {
    action: {
      required: false,
      default: 'crear_cotizacion'
    },
    id: {
      required: false,
      default: ''
    },
    cotizar: {
      required: false,
      default: ''
    },
    nombre_proyecto: {
      required: false,
      default: ''
    },
    tiempo_entrega: {
      required: false,
      default: ''
    },
    id_cliente: {
      required: false,
      default: function () { return [] }
    },
    folio: {
      required: false,
      default: ''
    },
    folio_secuencial: {
      required: false,
      default: 1
    },
    version_secuencial: {
      required: false,
      default: 1
    },
    referencias: {
      required: false,
      default: function () { return [] }
    },
    productos: {
      required: false,
      default: function () { return [] }
    },
    notas: {
      required: false,
      default: function () { return [] }
    },
    servicios_seleccionados: {
      required: false,
      default: function () { return [] }
    },
    fecha_solicitud: {
      required: false,
      default: function () { return [] }
    },
    fecha_creacion: {
      required: false,
      default: function () { return [] }
    }
  },
  data() {
    return {
      id_cotizacion: this.$route.params.id,
      id_version: this.$route.params.version,
      montoFormateado: 0.00,
      clientes: [],
      servicios: [],
      fecha_solicitar: false,
      fecha_crear: false,
      messages: [],
      buscar: '',
      status_cotizar: false,
      status_proyecto: false
    }
  },
  firestore () {
    return {
      clientes: db.collection('clientes').orderBy('nombre', 'asc'),
      servicios: db.collection('servicios').orderBy('nombre', 'asc')
    }
  },
  watch: {
    //people: {
    //  handler: function (after, before) {
    //    // Return the object that changed
    //    let changed = after.filter( function( p, idx ) {
    //      return Object.keys(p).some( function( prop ) {
    //        return p[prop] !== before[idx][prop];
    //      })
    //    })
    //    // Log it
    //    console.log(changed)
    //  },
    //  deep: true
    //}
    productos: {
    	handler: function() {
    		for(let i in this.productos){
    			this.productos[i].precio = this.$options.filters.cleanFormat(this.productos[i].precioformateado)
    			this.productos[i].precioformateado = this.$options.filters.formatNumber(this.productos[i].precioformateado)
    		}
    	},
    	deep: true
    },
    montoFormateado () {
      //this.monto = this.$options.filters.cleanFormat(this.montoFormateado)
      //this.montoFormateado = this.$options.filters.formatNumber(this.monto)
    }
  },
  updated: function () {
    //Altura a textarea
    if(document.getElementById('contenedor-formulario-versiones')){
      if(!document.getElementById('contenedor-formulario-versiones').classList.contains('formateado')){
        var allTextarea = document.getElementById('contenedor-formulario-versiones').getElementsByTagName('textarea')
        for (var i = 0, len = allTextarea.length; i < len; i++) {
          allTextarea[i].style.height = allTextarea[i].scrollHeight+'px'
        }
      }
      document.getElementById('contenedor-formulario-versiones').classList.add('formateado')
    }
  },
  computed: {
    validateData (){
      //Verificar fecha
      this.fecha_solicitar = new Date().toISOString()
      this.fecha_crear = new Date().toISOString()
      if(this.fecha_solicitud){
        if(this.fecha_solicitud.seconds > 0){
          const value = this.fecha_solicitud.seconds
          const curdate = new Date(null)
          const date = curdate.setTime(value*1000)
          const momentdate = curdate.toISOString()
          this.fecha_solicitar = momentdate
        }
      }
      if(this.fecha_creacion){
        if(this.fecha_creacion.seconds > 0){
          const value = this.fecha_creacion.seconds
          const curdate = new Date(null)
          const date = curdate.setTime(value*1000)
          const momentdate = curdate.toISOString()
          this.fecha_crear = momentdate
        }
      }
    },
    varificar_modificaciones (){
      //Siempre y cuando no se este editando un movimiento verificar si se modifico un campo
      if(!this.id){
        if(this.cotizar != '' || this.nombre_proyecto != '' || this.tiempo_entrega != '' || this.id_cliente || this.folio != '' || this.referencias || this.productos || this.notas || this.servicios_seleccionados){
          //Si se ha modificado cualquiera de los campos preguntar antes de salir
          this.$emit('actionChange', false)
        }else{
          this.$emit('actionChange', true)
        }
      }else{
        this.$emit('actionChange', false)
      }
    }
  },
  methods: { 
    mixin_autoResize_resize(event) {
      //Modificar altura del textarea
      event.target.style.height = 'auto'
      event.target.style.height = event.target.scrollHeight+'px'
    },
    delete_message (value){
      this.messages.splice(this.messages.indexOf(value), 1)
    },
    delete_producto (value){
      this.productos.splice(this.productos.indexOf(value), 1)
    },
    delete_incluye (parent, value){
      this.productos[parent].incluye.splice(this.productos[parent].incluye.indexOf(value), 1)
    },
    delete_nota (value){
     this.notas.splice(this.notas.indexOf(value), 1)
    },
    addTag (newTag) {
      const tag = newTag
      this.referencias.push(tag)
    },
    actionForm () {
      let item_id = this.id
      let item_cotizar = (this.cotizar).trim()
      let item_nombre_proyecto = (this.nombre_proyecto).trim()
      let item_tiempo_entrega = Number(this.tiempo_entrega)
      let item_id_cliente = this.id_cliente

      var item_folio = (this.folio).trim()
      if(this.action != 'editar_version'){
        var folio = this.folio_secuencial
        var format_folio = '0001'
        var format_version = 1
        //Formatear folio
        var the_folio = ''
        if(folio >= 0 && folio < 10){
          the_folio = '000'
        }else if(folio >= 10 && folio < 100){
          the_folio = '00'
        }else if(folio >= 100 && folio < 1000){
          the_folio = '0'
        }
        //Asignar valor a folio formateado
        format_folio = the_folio + folio
        //Obtener versiones existentes si se esta creando una nueva version
        if(this.action == 'crear_version'){
          format_folio = ((this.folio).trim()).substring(0, 4) //Obtiene el folio de la cotizacion en esta version
          format_version = this.version_secuencial + 1 //Obtiene el siguiente folio de version que le corresponde
        }
        item_folio = format_folio+'-V.'+format_version
      }

      let item_referencias = this.referencias
      let item_productos = this.productos
      let item_notas = this.notas

      let item_fecha_solicitud_seleccionada = this.fecha_solicitar
      let item_fecha_solicitud = new Date(item_fecha_solicitud_seleccionada)

      let item_fecha_creacion_seleccionada = this.fecha_crear
      let item_fecha_creacion = new Date(item_fecha_creacion_seleccionada)

      let todos_servicios = this.servicios_seleccionados.sort(function(a,b) {return (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0);} ).reverse()
      let item_servicios_seleccionados = []
      if(todos_servicios){
        for (var i = todos_servicios.length - 1; i >= 0; i--) {
          var objeto_servicio = {
            id: todos_servicios[i].id,
            color: todos_servicios[i].color,
            nombre: todos_servicios[i].nombre
          }
          item_servicios_seleccionados.push(objeto_servicio)
        }
      }

      let item_ref_cliente = {}

      if(item_id_cliente){
        item_ref_cliente.id = item_id_cliente.id, 
        item_ref_cliente.color = item_id_cliente.color,
        item_ref_cliente.nombre = item_id_cliente.nombre
      }

      // Setear errores
      this.messages = []

      // Comprobar cotizar
      if(!item_cotizar) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Especifica que se va a cotizar.'
        }
        this.messages.push(the_message)
      }

      // Comprobar nombre proyecto
      if(!item_nombre_proyecto) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Coloca el nombre del proyecto.'
        }
        this.messages.push(the_message)
      }

      // Comprobar dias
      if(item_tiempo_entrega < 1) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario seleccionar los días de entrega.'
        }
        this.messages.push(the_message)
      }

      // Comprobar cliente
      if(!item_id_cliente || item_id_cliente.length < 1) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario seleccionar un cliente.'
        }
        this.messages.push(the_message)
      }

      // Comprobar folio
      if(!item_folio) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar el folio.'
        }
        this.messages.push(the_message)
      }

      // Comprobar productos
      if(item_productos.length < 1) {
        const the_message = {
          type: 'fatal b-fail',
          content: 'Es necesario ingresar por lo menos un producto.'
        }
        this.messages.push(the_message)
      }else{
        // Comprobar informacion producto
        for(const e in item_productos){
          //Comprobar titulo
          if(item_productos[e].titulo == '') {
            const the_message = {
              type: 'fatal b-fail',
              content: 'Es necesario ingresar un título al producto.'
            }
            this.messages.push(the_message)
          }
          //Comprobar precio
          if(!item_productos[e].precio) {
            const the_message = {
              type: 'fatal b-fail',
              content: 'Es necesario ingresar un precio al producto.'
            }
            this.messages.push(the_message)
          }
          //Comprobar descripcion
          if(item_productos[e].descripcion == '') {
            const the_message = {
              type: 'fatal b-fail',
              content: 'Es necesario ingresar una descripción al producto.'
            }
            this.messages.push(the_message)
          }
          //Comprobar incluye
          for(const i in item_productos[e].incluye){
            if(item_productos[e].incluye[i].titulo == '') {
              const the_message = {
                type: 'fatal b-fail',
                content: 'Es necesario ingresar un título al bloque del producto.'
              }
              this.messages.push(the_message)
            }
            if(item_productos[e].incluye[i].descripcion == '') {
              const the_message = {
                type: 'fatal b-fail',
                content: 'Es necesario ingresar una descripción al bloque del producto.'
              }
              this.messages.push(the_message)
            }
          }
        }
      }

      // Si no existen errores proceder
      if(!this.messages.length){
        //Assignar campos a interactuar
        let datos_version = {
          cotizar: item_cotizar,
          fecha_creacion: item_fecha_creacion,
          fecha_modificacion: new Date(),
          fecha_solicitud: item_fecha_solicitud,
          folio: item_folio,
          id_cliente: item_id_cliente.id,
          nombre_proyecto: item_nombre_proyecto,
          notas: item_notas,
          productos: item_productos,
          ref_cliente: item_ref_cliente,
          referencias: item_referencias,
          servicios: item_servicios_seleccionados,
          tiempo_entrega: Number(item_tiempo_entrega),
        }
        let datos_cotizacion = datos_version
        let vm = this
        // Verificar tipo de accion
        if(this.action == 'editar_version'){
          //Editar Version
          db.collection('cotizaciones').doc(this.id_cotizacion).collection('versiones').doc(this.id_version).update(datos_version)
          .then(function(){
            // Actializar datos de cotizacion
            db.collection('cotizaciones').doc(vm.id_cotizacion).update(datos_cotizacion)
            .then(function() {
                //Actualizado
            })
            .catch(function(error) {
                //Error
            })
            // Enviar a la cotizacion
            vm.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
            router.replace('/viewQuote/'+vm.id_cotizacion+'/'+vm.id_version)
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else if(this.action == 'crear_version'){
          //Crear Version
          db.collection('cotizaciones').doc(this.id_cotizacion).collection('versiones').add(datos_version)
          .then(function(docRef){
            // Actualizar cotizacion
            db.collection('cotizaciones').doc(vm.id_cotizacion).update(datos_cotizacion)
            .then(function() {
                //Actualizado
            })
            .catch(function(error) {
                //Error
            })
            // Enviar a la cotizacion
            vm.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
            router.replace('/viewQuote/'+vm.id_cotizacion+'/'+docRef.id)
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else if(this.action == 'crear_cotizacion'){
          //Crear Cotizacion
          db.collection('cotizaciones').add(datos_cotizacion)
          .then(function(docRef){
            // Actualizar siguiente folio
            db.collection('valores').where('relacion', '==', 'folio').limit(1)
            .get()
            .then(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    if(doc.exists){
                      //Asignar valor a folio
                      var siguiente_folio = Number(doc.data().valor) + 1
                      db.collection('valores').doc(doc.id).update({valor: siguiente_folio})
                    }
                })
            })
            //Crear version
            db.collection('cotizaciones').doc(docRef.id).collection('versiones').add(datos_version)
            .then(function(docRefVersion){
              // Creado
              // Enviar a la cotizacion
              vm.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
              router.replace('/viewQuote/'+docRef.id+'/'+docRefVersion.id)
            }).catch(function(error) {
              console.error('Error removing document: ', error)
              alert('Error al ejecutar operación')
            })
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else{
          // Enviar a la cotizacion
          this.$emit('actionChange', true) //Evita que el navegador pregunte al salir de la ruta
          router.replace('/quotes/')
        }
      }
    }
  }
}
</script>
<style>
  @import url('../../node_modules/vue-datetime/dist/vue-datetime.css')
</style>