<template>
  <div class="screen page stuffs b-soft">
    <div class="grid">
      <draggable class="layout" v-model="pendientes" @end="change_order" v-bind="{handle:'.handle-stuffs'}">
        <div v-for="pendiente in pendientes" :key="pendiente.id" class="container box-container stuffs-container" v-bind:style="{'background-color': pendiente.color}">
          <div class="row v-items-center">
            <div class="col-auto handle-container handle-stuffs">
              <div class="handle">
                <span></span>
                <span></span>
              </div>
            </div>
            <div class="col">
              <div class="container">
                <div class="row">
                  <div class="circle col-auto">
                    <swatches v-model="pendiente.color" :colors="colors" row-length="8" popover-to="right" swatch-size="30" @input="changeColor(pendiente.color, pendiente.id)"></swatches>
                  </div>
                  <div class="col">
                    <input type="text" placeholder="Escribe algo..." v-model="pendiente.nombre" v-on:blur="changeName(pendiente.nombre, pendiente.id)">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <a href="#" class="t-complementary" v-on:click.prevent="deleteRow(pendiente.id)">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>
          <GetStuffs v-bind:pendiente="pendiente" />
        </div>
      </draggable>
      <section class="layout no-select">
        <div class="container">
          <div class="row h-items-center">
            <div class="col-12">
              <p class="t-complementary t-18-px">
                Crear un nuevo grupo.
              </p>
            </div>
            <div class="col-12">
              <div class="container">
                <div class="row v-items-center">
                  <div class="col-auto">
                    <swatches v-model="grupo.color" :colors="colors" row-length="8" popover-to="right" swatch-size="30"></swatches>
                  </div>
                  <div class="col">
                    <span class="t-complementary" style="cursor: pointer;" v-on:click="createGroup()">
                      Selecciona un color y da click aquí para crear un nuevo grupo de pendientes.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GetFooter />
    </div>
  </div>
</template>

<script>
import GetFooter from '@/components/footer.vue'
import draggable from 'vuedraggable'
import Swatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import {getColorsSoft} from '@/colors.js'
import GetStuffs from '@/components/stuffs.vue'
import { db } from '@/firebase'
export default {
  name: 'app',
  components: {
    GetFooter,
    draggable,
    Swatches,
    GetStuffs
  },
  data() {
    return {
      pendientes: [],
      grupo: {
        nombre: 'Nuevo Grupo',
        color: '#FFFFFF'
      },
      colors: getColorsSoft
    }
  },
  firestore () {
    return {
      pendientes: db.collection('pendientes').orderBy('orden')
    }
  },
  methods: {
    change_order() {
      for (var a = 0; a <= this.pendientes.length - 1; a++){
        db.collection('pendientes').doc(this.pendientes[a].id).update({
          orden: a + 1
        })
      }
    },
    deleteRow(id){
      if(window.confirm('¿Estás Seguro? Esto eliminará esta lista.')){
        db.collection('pendientes').doc(id).delete().then(function() {
          console.log('Document successfully deleted!')
        }).catch(function(error) {
          alert('Error de ejecución')
          console.error('Error removing document: ', error)
        })
      }
    },
    changeColor(color, id) {
      if(color.trim() != ''){
        if(id){
          db.collection('pendientes').doc(id).update({color: color.trim()})
          .then(function(){
            // Guardado
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else{
          alert('Error al ejecutar operación')
        }
      }else{
        alert('Es necesario seleccionar un color')
      }
    },
    changeName(name, id) {
      if(name.trim() != ''){
        if(id){
          db.collection('pendientes').doc(id).update({nombre: name.trim()})
          .then(function(){
            // Guardado
          }).catch(function(error) {
            console.error('Error removing document: ', error)
            alert('Error al ejecutar operación')
          })
        }else{
          alert('Error al ejecutar operación')
        }
      }else{
        alert('Es necesario ingresar un nombre')
      }
    },
    createGroup() {
      var selfed = this
      var name = selfed.grupo.nombre
      var color = selfed.grupo.color
      if(name.trim() != ''){
        db.collection('pendientes').add({
          nombre: name.trim(),
          color: color,
          orden: selfed.pendientes.length + 1,
          fecha_creacion: new Date(),
          fecha_modificacion: new Date()
        })
        .then(function(){
          // Guardado
          //selfed.grupo.nombre = ''
          selfed.grupo.color = '#FFFFFF'
        }).catch(function(error) {
          console.error('Error removing document: ', error)
          alert('Error al ejecutar operación')
        })
      }
    }
  }
}
</script>