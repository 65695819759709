<template>
  <div v-if="the_show_values" class="col-12">
    <div class="box-container b-white t-center">
      <slick ref="slick" :options="slickOptions">
        <div class="slide-resume">
          <h1 class="t-48-px t-semibold t-complementary">{{the_total_money | formatPrice}}</h1>
          <p class="t-upper">
            Saldo Total
            <br>
            <span class="t-10-px t-primary">
              Ahorros: {{the_total_saves | formatPrice}}
            </span>
          </p>
          <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/balance')">
            Ver Actividad Mensual
          </a>
        </div>
        <div class="slide-resume">
          <h1 class="t-48-px t-semibold t-complementary">{{the_total_ins | formatPrice}}</h1>
          <p class="t-upper">
            Ingreso Total Mensual
            <br>
            <span class="t-10-px t-primary">
              Ventas Estudio {{the_total_ins_estudio | formatPrice}}
            </span>
          </p>
          <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/balance')">
            Ver Actividad Mensual
          </a>
        </div>
        <div class="slide-resume">
          <h1 class="t-48-px t-semibold t-complementary">{{the_total_outs | formatPrice}}</h1>
          <p class="t-upper">
            Egreso Total Mensual
            <br>
            <span class="t-10-px t-primary">
              Gastos Estudio {{the_total_outs_estudio | formatPrice}}
            </span>
          </p>
          <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/balance')">
            Ver Actividad Mensual
          </a>
        </div>
        <div class="slide-resume">
          <h1 class="t-48-px t-semibold t-complementary">{{the_total_real_money | formatPrice}}</h1>
          <p class="t-upper">
            Saldo Estimado Sin Impuestos
            <br>
            <span class="t-10-px t-primary">
              ISR {{the_total_isr | formatPrice}} - IVA {{the_total_iva | formatPrice}} <small>(<i class="fas fa-arrow-down"></i> {{the_total_iva_in | formatPrice}} <i class="fas fa-arrow-up"></i> {{the_total_iva_out | formatPrice}})</small>
            </span>
          </p>
          <a href="#" class="t-10-px link" v-on:click.prevent="goTo('/balance')">
            Ver Actividad Mensual
          </a>
        </div>
      </slick>
    </div>
  </div>
  <div v-else class="col-12">
    <div class="box-container b-white t-center">
      <div class="load-container">
        <img src="@/assets/fottom-load.gif" alt="Fottom Flux" class="logo-load">
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { db } from '@/firebase'
import Slick from 'vue-slick'
export default {
  components: {
    Slick
  },
  name: 'getDashboard',
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        prevArrow: false,
        nextArrow: false,
        dots: true
      },
      the_show_values: false, //Determina si se muestra los valores despues de cargarse
      the_total_money: 0, //Total antes de impuestos y sin ahorro ni movimientos fantasma
      the_total_saves: 0, //Total Ahorrado
      the_total_ins_sat: 0, //Total Ingresos Facturados
      the_total_iva_in: 0, //Total Iva Retenido en Ingresos
      the_total_ins_no_sat: 0, //Total Ingresos No Facturados
      the_total_ins: 0, //Total Ingreso Mensual Incluyendo IVA Retenido
      the_total_ins_estudio: 0, //Total Ingreso Mensual Del Estudio Incluyendo IVA Retenido
      the_total_outs_sat: 0, //Total Egresos Facturados Incluyendo IVA Transladado
      the_total_outs_estudio: 0, //Total Egresos Mensual Del Estudio Incluyendo IVA Retenido
      the_total_isr_retenido: 0, //Isr retenido correspondiente al mes corriente
      the_total_iva_retenido: 0, //Iva retenido correspondiente al mes corriente
      the_total_isr_pagado: 0, //Isr pagado correspondiente al mes anterior
      the_total_iva_pagado: 0, //Iva pagado correspondiente al mes anterior
      the_total_saves_mensual: 0, //Ahorro en este mes corriente
      the_total_iva_out: 0, //Total Iva Transferido en Egresos
      the_total_outs: 0, //Total Egreso Mensual Incluyendo Pago impuestos isr e iva siempre y cuando no sea un movimiento fantasma
      the_total_outs_internacional: 0, //Total Egresos Internacionales
      the_total_isr: 0, //Calculo de isr a pagar calculado en base a los ingresos del mes sin iva menos los gastos del mes facturados sin iva
      the_total_iva: 0, //Calculo de iva a pagar calculado del iva retenido - el iva transladado
      the_total_real_money: 0, //Calculo de dinero real despues de pagar impuestos
      curren_date: [{
        'day': new Date().getDate(),
        'month': new Date().getMonth() + 1,
        'year': new Date().getFullYear()
      }],
    }
  },
  mounted(){
    var vv = this

    //Repasar las cuentas para ver saldos iniciales
    db.collection('cuentas').get().then(accounts => {
      accounts.forEach(account => {
          if(account.id == 'igJZPb5KdA6HvzQQPsTO'){//Si el id de la cuenta pertenece a la cuenta ahorros
            vv.the_total_saves += account.data().valor_actual
          }else if(account.id == 'O2SnmMZminZ6KP8B75z3'){//Si el id de la cuenta pertenece a la cuenta gastos fantasmas
            //No hacer nada
          }else{//Si el id de la cuenta no pertenece a lo anterior
            vv.the_total_money += account.data().valor_actual
          }
      })
    })
    
    //Funcion para actualizar datos de todos los movimientos
    /*db.collection('movimientos').get().then(moves => {

      moves.forEach(move => {
        
        //Asignar fecha separada a movimiento
        if(move.data().fecha_creacion && !move.data().ano_corriente){
          var value_date = new Date(move.data().fecha_creacion.seconds * 1000)
          var dia_corriente_value = value_date.getDate();
          var mes_corriente_value = value_date.getMonth() + 1;
          var ano_corriente_value = value_date.getFullYear();

          let datos_seleccionados = {
            dia_corriente: dia_corriente_value,
            mes_corriente: mes_corriente_value,
            ano_corriente: ano_corriente_value,
          }

          db.collection('movimientos').doc(move.id).update(datos_seleccionados)
          .then(function(){
            console.log(move.id)
            // Guardado
          }).catch(function(error) {
            console.error('Error updating document: '+move.id, error)
          })
        }
        //Asignar categoria iva retenido a movimientos que tengan la categoria isr retenido e incluyan la palabra iva
        if(move.data().id_categoria == 'jygRd5YUfyh8JpJdwG6V'){
          let datos_seleccionados = {
            id_categoria: 'YARxFJQAfiMby94u449a',
            ref_categoria: db.collection('categorias').doc('YARxFJQAfiMby94u449a'),
          }
          if(move.data().descripcion.includes('Iva ')){
            db.collection('movimientos').doc(move.id).update(datos_seleccionados)
            .then(function(){
              console.log(move.id)
              // Guardado
            }).catch(function(error) {
              console.error('Error updating document: '+move.id, error)
            })
          }
        }
      })

    })*/

    //Repasar los movimientos del mes corriente para calcular valores
    db.collection('movimientos').where('mes_corriente', '==', this.curren_date[0].month).where('ano_corriente', '==', this.curren_date[0].year).get().then(moves => {

      moves.forEach(move => {

        //Calcular movimientos mes
        if(move.data().id_tipo_movimiento == 'HDPLGCGRFI4h08Oyw2q2'){//Si el movimiento es un Ingreso evitara que una transferencia sume dinero que no entro
          for(var z = 0; z <= move.data().etiquetas.length - 1; z++){
            if(move.data().etiquetas[z].id == 'qQRPtjkJrcRk8qw33Ogl'){//Si encuentra una etiqueta perteneciente al id de Iva
              vv.the_total_iva_in += Number(move.data().monto)
            }
            if(move.data().etiquetas[z].id == 'Gg9TwJxFKdOxCKOFFIXK'){//Si encuentra una etiqueta perteneciente al id de Facturado
              vv.the_total_ins_sat += Number(move.data().monto)
            }
            if(move.data().etiquetas[z].id == 'aTpiP3JF3YA2Gu6xoQ9B'){//Si encuentra una etiqueta perteneciente al id de Interno
              vv.the_total_ins_estudio += Number(move.data().monto)
            }
          }
        }else if(Number(move.data().monto) < 0){//Si el movimiento es un Egreso o transferencia negativa
          var isInternoFacturado = 0
          for(var z = 0; z <= move.data().etiquetas.length - 1; z++){
            //Calcula gastos realizados que fueron facturados
            if(move.data().etiquetas[z].id == 'Gg9TwJxFKdOxCKOFFIXK'){//Si encuentra una etiqueta perteneciente al id de Facturado
              vv.the_total_outs_sat += Math.abs(Number(move.data().monto))
              isInternoFacturado++ //Sumar concidencia con etiqueta
            }
            if(move.data().etiquetas[z].id == 'Ade1I8HgRhNWfGphKYNh'){//Si encuentra una etiqueta perteneciente al id de Extranjero
              vv.the_total_outs_internacional += Number(move.data().monto)
              //Eliminar el gasto extranjero puesto que no genera iva y se contempla el gasto entero al calcular el isr
              vv.the_total_outs_sat -= Math.abs(vv.the_total_outs_internacional)
            }
            if(move.data().etiquetas[z].id == 'aTpiP3JF3YA2Gu6xoQ9B'){//Si encuentra una etiqueta perteneciente al id de Interno
              if(move.data().id_tipo_movimiento == 'QGCpBfjBKd6o8xCBcSAl'){//Solo si el tipo de movimiento es un Egreso
                if(move.data().id_cuenta != 'O2SnmMZminZ6KP8B75z3'){//Si el movimiento no pertenece a la cuenta gastos fantasmas sumar al gasto interno
                  vv.the_total_outs_estudio += Number(move.data().monto)
                }
              }
              isInternoFacturado++ //Sumar concidencia con etiqueta
            }
          }
        }
        //asignar isr pagado por categoria
        if(move.data().id_categoria == '1LVKhrGbjQQb2Nuimt4z'){
          vv.the_total_isr_pagado += Number(move.data().monto)
        }
        //asignar iva pagado por categoria
        if(move.data().id_categoria == 'sfZC8SpBaoe69Z23hcS6'){
          vv.the_total_iva_pagado += Number(move.data().monto)
        }
        //asignar isr retenido por categoria
        if(move.data().id_categoria == 'jygRd5YUfyh8JpJdwG6V'){
          vv.the_total_isr_retenido += Number(move.data().monto)
        }
        //asignar iva retenido por categoria
        if(move.data().id_categoria == 'YARxFJQAfiMby94u449a'){
          vv.the_total_iva_retenido += Number(move.data().monto)
        }
        //asignar ahorro del mes
        if(move.data().id_cuenta == 'igJZPb5KdA6HvzQQPsTO'){
          vv.the_total_saves_mensual = Number(move.data().monto)
        }
      })

      //Calcular el ISR
      var tabuladores = [
        {limite_inferior:0.01, limite_superior:578.52, cuota_fija:0.00, tasa:1.92}, 
        {limite_inferior:578.53, limite_superior:4910.18, cuota_fija:11.11, tasa:6.40}, 
        {limite_inferior:4910.19, limite_superior:8629.20, cuota_fija:288.33, tasa:10.88}, 
        {limite_inferior:8629.21, limite_superior:10031.07, cuota_fija:692.96, tasa:16.00}, 
        {limite_inferior:10031.08, limite_superior:12009.94, cuota_fija:917.26, tasa:17.92}, 
        {limite_inferior:12009.95, limite_superior:24222.31, cuota_fija:1271.87, tasa:21.36}, 
        {limite_inferior:24222.32, limite_superior:38177.69, cuota_fija:3880.44, tasa:23.52}, 
        {limite_inferior:38177.70, limite_superior:72887.50, cuota_fija:7162.74, tasa:30.00}, 
        {limite_inferior:72887.51, limite_superior:97183.33, cuota_fija:17575.69, tasa:32.00}, 
        {limite_inferior:97183.34, limite_superior:291550.00, cuota_fija:25350.35, tasa:34.00}, 
        {limite_inferior:291550.01, limite_superior:999999999, cuota_fija:91435.02, tasa:35.00}
      ]
      //Obtenemos los gastos totales comprobables restando al ingreso facturado sin contar el iva
      //Restamos el total de los gastos sin contemplar el iva
      //Restamos el total de los gastos internacionales que no agraban iva
      const ganancias_totales = Math.abs(vv.the_total_ins_sat) - Math.abs(vv.the_total_outs_sat / 1.16) - Math.abs(vv.the_total_outs_internacional)
      var total_isr = 0
      for (var w = 0; w <= tabuladores.length - 1; w++){
        if(ganancias_totales >= tabuladores[w].limite_inferior && ganancias_totales <= tabuladores[w].limite_superior){
          var diferencia_tabulador = ganancias_totales - tabuladores[w].limite_inferior
          var impuesto_marginal = diferencia_tabulador / 100 * tabuladores[w].tasa
          total_isr = impuesto_marginal + tabuladores[w].cuota_fija
          break
        }
      }
      //Calcular dinero ingresado
      vv.the_total_ins = Math.abs(vv.the_total_ins_estudio) + Math.abs(vv.the_total_iva_in)
      //Calcular el Iva recibido contemplando en cuenta el retenido por clientes
      vv.the_total_iva_in = Math.abs(vv.the_total_iva_in) - Math.abs(vv.the_total_iva_retenido)
      //Definir el total de isr a pagar contemplando el retenido por clientes
      vv.the_total_isr = total_isr - Math.abs(vv.the_total_isr_retenido)
      //Calcular total de ingreso sin facturar
      vv.the_total_ins_no_sat = Math.abs(vv.the_total_ins) - Math.abs(vv.the_total_ins_sat) - Math.abs(vv.the_total_iva_in)
      //Calcula el iva correspondiente al gasto facturado
      vv.the_total_iva_out = Math.abs(vv.the_total_outs_sat) / 1.16 * 0.16
      //Calcular el Iva a pagar contemplando el retenido por clientes
      vv.the_total_iva = Math.abs(vv.the_total_iva_in) - Math.abs(vv.the_total_iva_out)
      //Calcula el dinero real despues de pagar impuestos
      vv.the_total_real_money = Math.abs(vv.the_total_money) - Math.abs(vv.the_total_isr) - Math.abs(vv.the_total_iva)
      //Calcular gasto interno del estudio se resta el gasto del pago de iva
      vv.the_total_outs_estudio = Math.abs(vv.the_total_outs_estudio) - Math.abs(vv.the_total_iva_pagado)
      //Calcular dinero egresado
      vv.the_total_outs = vv.the_total_outs_estudio - Math.abs(vv.the_total_iva_out)
      //Permitimos mostrar valores
      vv.the_show_values = true
    })
  },
  methods: {
    goTo (url){
      router.push({path: url})
    },
    date_to_seconds (get_date){
      if(get_date){
        //Format validate 2018-01-25T18:30
        var seconds = new Date(get_date).getTime() / 1000
      }else{
        var seconds = new Date().getTime() / 1000
      }
      return seconds
    },
  }
}
</script>
